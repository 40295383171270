import { useApiClient } from '@/shared/hooks/useApiClient'
import { useQuery } from '@tanstack/react-query'
import { CreditReason } from '@/shared/services/credit/types'
import { creditService } from '@/shared/services/credit'

export const creditReasonsQueryKey = 'credit-reasons'

export const useGetCreditReasons = () => {
  const { apiClient } = useApiClient()
  const service = creditService(apiClient.getClients().bffApi)

  return useQuery<CreditReason[]>(
    [creditReasonsQueryKey],
    async () => {
      const { data } = await service.getCreditReasons()
      return data
    },
    { retry: 2, suspense: true }
  )
}

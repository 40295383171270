import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[8]};
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[2]};

  .label {
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
  }
`

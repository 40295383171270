import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import styled from 'styled-components'

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  gap: 16px;
  align-items: center;
`

export const GenericError = () => {
  return (
    <StyledRoot>
      <Heading variant="heading-h4-medium">Erro ao carregar conteúdo</Heading>
      <Text>Algo deu errado por aqui, mas você pode tentar novamente daqui a pouco.</Text>
    </StyledRoot>
  )
}

import { useContext } from 'react'
import { REALMS, ROLES, AZP } from '@/shared/constants'
import { AuthContext, Roles } from '@/shared/contexts/Auth'
import { UserGroupNames } from '@/shared/integrations/sorting-hat/types'
import { JWTProps } from '@/shared/contexts/Auth/types'
import config from '@/config'

export type UseJWTResponse = {
  email: string
  getBackofficeUserRoles: () => Set<Roles>
  getUserGroupNameBySchool: (schoolId: string) => UserGroupNames | undefined
  getUserGroupNameLabel: (schoolId: string) => string
  getUserRolesBySchool: (schoolId: string) => Set<Roles>
  hasPayoutReportAccess: boolean
  /**
   * @deprecated Use `isBackofficeUser` instead.
   */
  isAdmin: boolean
  isBackofficeUser: boolean
  isGroupManager: boolean
  isGuardian: boolean
  isSchool: boolean
  name: string
  schoolId: string
  schoolIds: string[]
  userId: string
}

const roles = Object.values(ROLES)
export type Role = typeof roles[number]

export const hasRole = (jwtProps: JWTProps | null, role: Role): boolean => {
  const resourceDef = jwtProps?.resource_definition
  const definition = resourceDef?.definition ?? {}
  const keys = Object.keys(definition)

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const roles = definition[key]?.roles

    if (roles?.includes(role)) {
      return true
    }
  }

  return false
}

// TODO: this method need to consider SchoolID or Definition Key as validation for who this Role is assignee
const hasPayoutReportAccess = (
  jwtProps: JWTProps | null,
  isAdmin: boolean,
  isSchool: boolean
): boolean => {
  if (isAdmin) {
    return !!jwtProps?.resource_roles?.includes(ROLES.PAYOUT_REPORT_ACCESS)
  } else if (isSchool) {
    return hasRole(jwtProps, ROLES.PAYOUT_REPORT_ACCESS)
  }

  return false
}

const isSameRealmAndAzp = (jwtProps: JWTProps | null, realm: string, azp: string): boolean => {
  const credRealm = jwtProps?.realm
  const credAzp = jwtProps?.azp

  return credRealm === realm && credAzp === azp
}

const getSchoolsIds = (jwtProps: JWTProps | null): string[] => {
  if (jwtProps?.realm !== REALMS.MULTITENACY_REALM) {
    return []
  }

  const resourceDef = jwtProps?.resource_definition
  const def = resourceDef?.definition ?? {}

  return Object.keys(def)
}

export const useJWT = (): UseJWTResponse => {
  const {
    isBackofficeUser,
    jwtProps,
    getUserGroupNameBySchool,
    getUserGroupNameLabel,
    getUserRolesBySchool,
    getBackofficeUserRoles,
  } = useContext(AuthContext)

  const isAdmin = isSameRealmAndAzp(
    jwtProps,
    REALMS.ADMIN_REALM,
    config.SORTING_HAT.ADMIN_AZP || AZP.ADMIN_AZP
  )
  const isGuardian = isSameRealmAndAzp(
    jwtProps,
    REALMS.GUARDIAN_REALM,
    config.SORTING_HAT.GUARDIAN_AZP || AZP.GUARDIAN_AZP
  )
  const isSchool = isSameRealmAndAzp(
    jwtProps,
    REALMS.SCHOOL_REALM,
    config.SORTING_HAT.SCHOOL_AZP || AZP.SCHOOL_AZP
  )

  const schoolsIds = getSchoolsIds(jwtProps)

  return {
    isAdmin,
    isGroupManager: isSchool && hasRole(jwtProps, ROLES.GROUP_MANAGER),
    isGuardian,
    isSchool,

    hasPayoutReportAccess: hasPayoutReportAccess(jwtProps, isAdmin, isSchool),
    schoolId: schoolsIds[0],
    schoolIds: schoolsIds,

    getUserGroupNameLabel,
    getUserRolesBySchool,
    getUserGroupNameBySchool,
    getBackofficeUserRoles,
    isBackofficeUser,

    userId: jwtProps?.sub ?? '',
    name: jwtProps?.name ?? '',
    email: jwtProps?.email ?? '',
  }
}

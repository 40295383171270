import type { UserGroupNames } from '@/shared/integrations/sorting-hat/types'
import type { Roles } from '@/shared/contexts/Auth'

import { OPTIONS_GROUP } from '@monorepo/access-management/constants/OPTIONS_GROUP'
import { REQUIRED_ROLE_FOR_GROUP_ASSIGN } from '@monorepo/access-management/constants/REQUIRED_ROLE_FOR_GROUP_ASSIGN'
import { GROUP_NAME_ORDER } from '@monorepo/access-management/constants/GROUP_NAME_ORDER'

interface IProps {
  loggedUserGroupName?: UserGroupNames
  userRoles: Set<Roles>
  userToBeEditedGroupName: UserGroupNames
}

export const getAvailableOptions = ({
  loggedUserGroupName,
  userToBeEditedGroupName,
  userRoles,
}: IProps) => {
  if (!loggedUserGroupName) return []

  const loggedUserAccessLevel = GROUP_NAME_ORDER[loggedUserGroupName]
  const userToBeEditedLevel = GROUP_NAME_ORDER[userToBeEditedGroupName]

  return OPTIONS_GROUP.reduce((acc, option: { label: string; value: UserGroupNames }) => {
    const currentRole = REQUIRED_ROLE_FOR_GROUP_ASSIGN[option.value]

    if (loggedUserAccessLevel > userToBeEditedLevel && userRoles.has(currentRole))
      return [...acc, option]

    if (option.value === userToBeEditedGroupName) {
      return [...acc, option]
    }

    return acc
  }, [] as { label: string; value: UserGroupNames }[])
}

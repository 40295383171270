import { forwardRef, ReactNode } from 'react'
import { Heading } from '@gravity/heading'
import { Skeleton } from '@gravity/skeleton'

import { Container } from './styles'

type SectionContainerProps = {
  action?: ReactNode
  children?: ReactNode
  id: string
  isLoading?: boolean
  title: string
}

export const SectionContainer = forwardRef<HTMLElement, SectionContainerProps>(
  ({ id, action, title, children, isLoading }, ref) => {
    return (
      <Container id={id} ref={ref}>
        <header>
          {isLoading ? (
            <Skeleton variant="neutral" width={172} height={25} />
          ) : (
            <Heading variant="heading-h4-medium">{title}</Heading>
          )}

          {action && action}
        </header>

        <div>{children}</div>
      </Container>
    )
  }
)

import { useContext } from 'react'
import { ApiClientContext } from '@/shared/contexts/apiClientContext'

export const useApiClient = () => {
  const { apiClient } = useContext(ApiClientContext)

  return {
    apiClient,
  }
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { creditService } from '@/shared/services/credit'
import { GetLockResponse } from '@/shared/services/credit/types'

export const getLockQueryKey = 'credit-get-lock'

export const useGetLock = (schoolId: string, options?: UseQueryOptions<GetLockResponse>) => {
  const { apiClient } = useApiClient()
  const service = creditService(apiClient.getClients().bffApi)

  return useQuery<GetLockResponse>(
    [getLockQueryKey, schoolId],
    async () => await service.getLock(schoolId),
    {
      retry: 3,
      useErrorBoundary: true,
      ...options,
    }
  )
}

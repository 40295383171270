import { useApiClient } from '@/shared/hooks/useApiClient'
import { creditService } from '@/shared/services/credit'
import { useMutation } from '@tanstack/react-query'
import { OrderDropoutRequest } from '@/shared/services/credit/types'

export const orderDropoutQueryKey = 'order-dropout'

export const useOrderDropout = () => {
  const { apiClient } = useApiClient()
  const service = creditService(apiClient.getClients().bffApi)

  return useMutation([orderDropoutQueryKey], async (body: OrderDropoutRequest) =>
    service.orderDropout(body)
  )
}

import { useQuery } from '@tanstack/react-query'

import { useJWT } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { useSchool } from '@/escolas/hooks/useSchool'

import { MANAGEMENT_QUERY_KEY } from '../constants'

export const useSchoolManagementQuery = () => {
  const { api } = useApi()
  const { school } = useSchool()
  const { hasPayoutReportAccess } = useJWT()

  return useQuery({
    queryKey: ['school', MANAGEMENT_QUERY_KEY, school?.id ?? ''],
    queryFn: async () => {
      const { data } = await api.management.fetchSchoolManagement({
        schoolId: school?.id ?? '',
      })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },

    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: !!school?.id && hasPayoutReportAccess,
  })
}

import { createContext } from 'react'
import { ApiClient } from '@/shared/core/api'

type ApiClientContext = {
  apiClient: ApiClient
}

export const ApiClientContext = createContext<ApiClientContext>(null)
const apiClient = new ApiClient()

export const ApiClientProvider = ({ children }) => {
  return (
    <ApiClientContext.Provider
      value={{
        apiClient: apiClient,
      }}
    >
      {children}
    </ApiClientContext.Provider>
  )
}

import { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { Chip } from '@olaisaac/design-system'

import { useApi } from '@/utils/hooks/useApi'
import { useContractsFilter, useNavigation } from 'src/escolas/hooks'

import { FIXED_STUDENTS_FILTER_OPTIONS } from '../ContractsYear/components/StudentContractsFilterDrawer/constants'
import {
  GuardiansContractsFilterType,
  StudentsContractsFilterObjectKeys,
  StudentsContractsFilterType,
} from 'src/escolas/hooks/useContractsFilter/types'
import { ContractStatusLabel } from 'src/shared/interfaces'

const SHOW_MORE_CHIP_COUNT = 4

type Tag = { label: string; value: string }

type SelectedFiltersProps = {
  filterType: 'guardians' | 'students'
}

export const SelectedFilters = ({ filterType }: SelectedFiltersProps) => {
  const { api } = useApi()
  const { schoolId } = useNavigation()
  const { studentsFilter, guardiansFilter, updateFilter } = useContractsFilter()

  const [guardiansTags, setGuardiansTags] = useState<Tag[]>([])
  const [studentsTags, setStudentsTags] = useState<Tag[]>([])

  const fetchProductNames = async (productsIds: string[]) => {
    const { data } = await api.products.getList({
      ids: productsIds.slice(0, 4).join(','),
      per_page: 4,
      school_id: schoolId,
    })

    return data
  }

  useEffect(() => {
    const loadTags = async () => {
      const selectedProducts =
        studentsFilter.productId.length > 0 ? await fetchProductNames(studentsFilter.productId) : []

      const selectedProductsTags = selectedProducts.map(item => ({
        label: item.name,
        value: item.id,
      }))

      const selectedFixedFilterOptions = Object.keys(studentsFilter).reduce((acc, key) => {
        if ((key as StudentsContractsFilterObjectKeys) === 'productId') return acc

        const { options: fixedFilterOptions } = FIXED_STUDENTS_FILTER_OPTIONS.find(
          ({ filterName }) => filterName === key
        )

        return [
          ...acc,
          ...fixedFilterOptions.filter(option =>
            (studentsFilter[key] as string[]).includes(option.value)
          ),
        ]
      }, [] as Tag[])

      setStudentsTags([...selectedProductsTags, ...selectedFixedFilterOptions])
    }

    loadTags()
  }, [studentsFilter])

  useEffect(() => {
    const selectedFixedFilterOptions = guardiansFilter.contractStatus.map(status => {
      return { label: ContractStatusLabel[status], value: status }
    })

    setGuardiansTags(selectedFixedFilterOptions)
  }, [guardiansFilter])

  const handleRemoveOption = (filterType: 'students' | 'guardians', value: string) => {
    const removeOption = <T extends StudentsContractsFilterType | GuardiansContractsFilterType>(
      filter: T,
      optionValue: string
    ) => {
      const updatedFilter = Object.keys(filter).reduce((acc, key) => {
        if ((filter[key] as string[]).includes(optionValue)) {
          return {
            ...acc,
            [key]: (filter[key] as string[]).filter(item => item !== optionValue),
          }
        }

        return {
          ...acc,
          [key]: studentsFilter[key],
        }
      }, {} as T)

      return updatedFilter
    }

    if (filterType === 'guardians') {
      const updatedFilter = removeOption(guardiansFilter, value)

      updateFilter('guardians', updatedFilter)
      return
    }

    const updatedFilter = removeOption(studentsFilter, value)

    updateFilter('students', updatedFilter)
  }

  const tags = filterType === 'guardians' ? guardiansTags : studentsTags

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" flexWrap="wrap">
      {tags.slice(0, SHOW_MORE_CHIP_COUNT).map(({ label, value }) => (
        <Box ml="0.5rem" mt="0.5rem" key={value}>
          <Chip
            variation="blue"
            label={label}
            onDelete={() => handleRemoveOption(filterType, value)}
          />
        </Box>
      ))}

      {tags.length > SHOW_MORE_CHIP_COUNT && (
        <Box ml="0.5rem" mt="0.5rem">
          <Chip variation="blue" label={`+${tags.length - SHOW_MORE_CHIP_COUNT}`} />
        </Box>
      )}
    </Box>
  )
}

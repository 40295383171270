import { SchoolReportingPeriod } from '@/shared/models/SchoolChargeOperation'
import { School } from '@/shared/models/School'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'

export const isIsaacPasySchool = isIsaacPaySchool

export const getPayoutSubtitle = (isPayoutOpen: boolean) => {
  return isPayoutOpen ? 'Valor acumulado' : 'Valor total'
}

export const getSchoolReportingPeriod = (school: School) => {
  return school?.school_charge_config?.reporting_period ?? SchoolReportingPeriod.weekly
}

export const getIsaacPayInstallmentMessage = (isOutsourcedProvider: boolean) => {
  return isOutsourcedProvider
    ? 'Tudo certo com a matrícula do aluno. Suas mensalidades serão recebidas diretamente na conta cadastrada da escola!'
    : 'Tudo certo com a matrícula do aluno. Suas mensalidades serão repassadas pelo isaac!'
}

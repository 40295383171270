import { useHistory, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import { setToClipboard } from '@/shared/utils'
import { UnleashFlags, useSnackbar, useUnleashFlag } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { useJWT } from '@/shared/hooks/useJWT'
import { Installment } from '@/modules/guardians/GuardianDetails/types'
import useManualLiquidationNavigation from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/hooks/useManualLiquidationNavigation'

import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'
import {
  InstallmentStatus,
  InstallmentType,
  NegotiationType,
  ReceivableType,
} from '@/modules/guardians/GuardianDetails/constants'
import { LiquidationSource } from '@/shared/interfaces'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useHotjar, HotjarEvents } from '@/shared/hooks/useHotjar'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useSendManualLiquidationEvent } from '@/modules/guardians/ManualLiquidation/hooks/manualLiquidation/useSendManualLiquidationEvents'
import { useSendGuardianDetailsInvoiceEvent } from '../../../hooks/useSendGuardianDetailsInvoiceEvent'

interface UseGuardianInvoicesTableActionsProps {
  installment: Installment
}

export const useGuardianInvoicesTableActions = ({
  installment,
}: UseGuardianInvoicesTableActionsProps) => {
  const history = useHistory()
  const { api } = useApi()
  const { getUserRolesBySchool, isBackofficeUser } = useJWT()
  const { school, schoolSlug } = useSelectedSchool()
  const { setPageToReturn } = useGuardianNavigation()
  const { search: currentPageQueryParams } = useLocation()
  const { setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const { sendHotjarEvent } = useHotjar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const isEnabledBasicOperationAccessLevel = useUnleashFlag(
    UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL
  )

  const { goToManualLiquidation, goToEditManualLiquidation } = useManualLiquidationNavigation({
    urn: installment.urn,
    callbackParams: currentPageQueryParams,
  })

  const isDownPayment = installment.type === InstallmentType.DOWN_PAYMENT
  const isNegotiationV2 = installment.negotiation_type === NegotiationType.NEGOTIATION_API_V2

  const isOpenReceivable = [
    InstallmentStatus.DUE_TODAY,
    InstallmentStatus.OPEN,
    InstallmentStatus.OVERDUE,
  ].includes(installment.status)
  const isOverdueReceivable = installment.status === InstallmentStatus.OVERDUE
  const isFromCreditCardFlow = installment.is_from_credit_card_flow

  const isOverdueEnrollment =
    installment.receivable_type === ReceivableType.ENROLLMENT && isOverdueReceivable

  const sendManualLiquidationEvent = useSendManualLiquidationEvent()
  const sendGuardianDetailsInvoiceEvent = useSendGuardianDetailsInvoiceEvent()

  const userRoles = getUserRolesBySchool(school?.id ?? '')

  const isManualLiquidationCreateBlockedForNoAccessRole =
    isEnabledBasicOperationAccessLevel && !userRoles.has('criar_baixa_manual') && !isBackofficeUser

  const isManualLiquidationEditBlockedForNoAccessRole =
    isEnabledBasicOperationAccessLevel && !userRoles.has('editar_baixa_manual') && !isBackofficeUser

  const isHiddenManualLiquidation =
    !isOpenReceivable ||
    (isDownPayment && isNegotiationV2 && isOverdueReceivable) ||
    isManualLiquidationCreateBlockedForNoAccessRole ||
    isFromCreditCardFlow

  const isManualLiquidation = [
    LiquidationSource.ISAAC_ACCOUNT,
    LiquidationSource.SCHOOL_ACCOUNT,
  ].includes(installment?.paid_payment_source as LiquidationSource)

  const isEditable =
    !isOpenReceivable &&
    !isFromCreditCardFlow &&
    isManualLiquidation &&
    !isManualLiquidationEditBlockedForNoAccessRole

  const isPaymentSourceIsaacAccount =
    isEditable && installment?.paid_payment_source === LiquidationSource.ISAAC_ACCOUNT

  const canEditWithBasicOperationAccessLevel = isEnabledBasicOperationAccessLevel
    ? isEditable && userRoles.has('editar_baixa_manual')
    : isEditable

  const canEditManualLiquidation = isBackofficeUser
    ? isPaymentSourceIsaacAccount
    : canEditWithBasicOperationAccessLevel

  const isHiddenEditManualLiquidation = isOpenReceivable || !canEditManualLiquidation

  const handleClickSharePaymentLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    sendGuardianDetailsInvoiceEvent('row', {
      name: 'Copiar link de cobrança',
      receivable_id: installment.urn,
      contract_id: installment.contract_id,
    })

    setToClipboard(installment.invoice?.payment_link ?? '')

    setSnackbarIsOpen(true, {
      variation: 'success',
      title: 'Link copiado com sucesso',
      description: '',
    })
  }

  const handleClickManualLiquidation = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    sendManualLiquidationEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEntities.MANUAL_LIQUIDATION,
      undefined,
      {
        name: 'Registrar recebimento',
        receivable_id: installment.urn,
        selected_date: dayjs().format(),
        contract_id: installment.contract_id,
        component: 'row',
      }
    )
    sendHotjarEvent(HotjarEvents.MANUAL_LIQUIDATION)
    setPageToReturn()
    goToManualLiquidation()
  }

  const handleClickPrintReceipt = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    sendGuardianDetailsInvoiceEvent('row', {
      name: 'Gerar comprovante',
      receivable_id: installment.urn,
      contract_id: installment.contract_id,
    })

    const response = await api.receivables.printReceipt(
      installment.urn.replace('urn:receivable:', '')
    )
    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    const newWindow = window.open(fileURL, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleClickEditManualLiquidation = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    sendManualLiquidationEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEntities.MANUAL_LIQUIDATION,
      undefined,
      {
        name: 'Editar recebimento',
        receivable_id: installment.urn,
        selected_date: dayjs().format(),
        contract_id: installment.contract_id,
        component: 'row',
      }
    )
    sendHotjarEvent(HotjarEvents.EDIT_MANUAL_LIQUIDATION)
    setPageToReturn()
    goToEditManualLiquidation()
  }

  const handleEditContract = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CONTRACT_DRAWER,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $component: 'row',
          $contract_id: installment?.contract_id,
          $button_name: 'Editar contrato',
          $contract_type: 'EFFECTED_CONTRACT',
        },
      })

    api.contracts.get(installment?.contract_id).then(contract => {
      const path = `/contratos/${contract?.id}/editar`

      history.push(`/${schoolSlug}${path}`)
    })
  }

  const isPaid = installment.status === InstallmentStatus.PAID

  const hasEditContractPermission = isBackofficeUser || userRoles.has('editar_contrato')

  const showEditContract =
    isOverdueEnrollment && (!isEnabledBasicOperationAccessLevel || hasEditContractPermission)

  const showSharePaymentLink =
    isOpenReceivable && !isOverdueEnrollment && installment.invoice?.payment_link

  return {
    showSharePaymentLink,
    showManualLiquidation: !isHiddenManualLiquidation,
    showEditManualLiquidation: !isHiddenEditManualLiquidation,
    showPrintReceipt: isPaid,
    showEditContract,
    handleClickPrintReceipt,
    handleClickManualLiquidation,
    handleClickEditManualLiquidation,
    handleClickSharePaymentLink,
    handleEditContract,
  }
}

import { SidebarContainer } from './styles'
import { SidebarMenuItem } from '../SidebarMenuItem'

export type SidebarMenuSection = {
  id: string
  label: string
  subLabel?: string
}

type SidebarMenuProps = {
  activeSectionId?: string
  onSectionActiveCallback: (id: string) => void
  sections: SidebarMenuSection[]
}

export const SidebarMenu = ({
  sections,
  activeSectionId,
  onSectionActiveCallback,
}: SidebarMenuProps) => {
  return (
    <SidebarContainer>
      {sections.map(section => (
        <SidebarMenuItem
          {...section}
          key={section.id}
          to={`#${section.id}`}
          isActive={section.id === activeSectionId}
          onClick={e => {
            if (e.currentTarget) {
              onSectionActiveCallback(section.id)
              e.stopPropagation()
            }
          }}
        />
      ))}
    </SidebarContainer>
  )
}

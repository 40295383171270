import AddIcon from '@material-ui/icons/Add'
import {
  Button,
  ButtonGroup,
  Grid,
  Pagination,
  Search,
  Table,
  TableRowParams,
  TableProps,
  usePagination,
} from '@olaisaac/design-system'
import { assoc, mapObjIndexed } from 'ramda'
import { SetStateAction, useEffect, useState } from 'react'
import { renderDebtStatus } from 'src/escolas/components/contract/ContractDetails'
import { PageTableControlBar } from 'src/escolas/components/layout/PageTableControlBar'
import { PageWithTableControlBar } from 'src/escolas/components/layout/PageWithTableControlBar'
import { TableColumns } from 'src/escolas/components/Table/TableColumnCell'
import TableFilter, {
  TableFilterOptions,
  TableFilterParam,
} from 'src/escolas/components/TableFilter'
import { useDidUpdateEffect, useNavigation } from 'src/escolas/hooks'
import useRouterReferenceYear from 'src/escolas/hooks/useRouterReferenceYear'
import { ContractFilters } from '@/escolas/router/[schoolSlug]/contratos'
import { useApi } from '@/utils/hooks/useApi'
import {
  Contract,
  ContractResponse,
  DebtStatus,
  Pagination as IPagination,
} from 'src/shared/interfaces'
import { monthsToContractValidity, paramsToQueryString } from 'src/shared/utils'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import { debounce } from 'throttle-debounce'
import { Title } from './components/Title'
import useAddContractEnabled from '@/escolas/hooks/useAddContractEnabled'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { useLayout } from '@/shared/hooks/useLayout'
import { usePageEvents } from './usePageEvents'

type ContractsYearProps = {
  filterOptions: Array<TableFilterOptions>
  filters: ContractFilters
  handleRowClick: (params: TableRowParams<Contract>) => void
  keyprop?: string
  name: string
  schoolId: string
  search: () => void
  setFilters: (value: SetStateAction<ContractFilters>) => void
  setName: (name: string) => void
}

const filterToParams = (filters: Record<string, TableFilterParam<any>>) =>
  mapObjIndexed(value => value?.value, filters)

const ContractsYear = ({
  keyprop,
  schoolId,
  handleRowClick,
  filters,
  filterOptions,
  setFilters,
  name,
  setName,
  search,
}: ContractsYearProps) => {
  const { api } = useApi()
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [total, setTotal] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [orderBy, setOrderBy] = useState<string>('')
  const referenceYear = useRouterReferenceYear()
  const isAddContractButtonEnabled = useAddContractEnabled()
  const history = useHistory()
  const { getNavigationUrl } = useNavigation()
  const events = usePageEvents()

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: <Title referenceYear={referenceYear} boxAlignItems="center" />,
  })

  const {
    page,
    setPage,
    itensPerPage,
    setItensPerPage,
    initialItensPerPageOptions,
  } = usePagination()

  const query = async ({ page, per_page }: IPagination) => {
    return api.contracts
      .fetchByStudent({
        schoolId,
        referenceYear,
        filter: {
          name: filters.name.value,
          ...(filters.status?.value && { contractStatus: [filters.status.value] }),
        },
        pagination: {
          page,
          per_page,
        },
      })
      .then(({ data, pagination }) => ({
        data: data.map((contract: ContractResponse) => {
          const {
            id,
            start_month,
            end_month,
            student,
            product,
            status,
            debt_status,
            guardian,
            sign_payment_status,
          } = contract

          return {
            debt_status,
            guardian: prettifyFullName(guardian.name),
            id,
            key: id,
            product: product.name,
            student: prettifyFullName(student.name),
            validity: monthsToContractValidity({ start_month, end_month }),
            sign_payment_status,
            status,
          }
        }),
        pagination,
      }))
  }

  const qs = paramsToQueryString(filterToParams(filters))
  const searchData = () => {
    setIsLoading(true)
    query({ page: page, per_page: itensPerPage })
      .then(({ data, pagination }) => {
        pagination.total && setTotal(pagination.total)

        setDataSource(data)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    searchData()
  }, [])

  useEffect(() => {
    search()
    setPage(1)
  }, [name])

  useDidUpdateEffect(() => {
    searchData()
  }, [qs, page, itensPerPage, orderBy])

  const source = keyprop ? dataSource.map(r => assoc('key', r[keyprop], r)) : dataSource

  const contractsTableColumns: TableColumns = [
    { headerName: 'Aluno', field: 'student', enableSorting: true },
    { headerName: 'Responsável', field: 'guardian', enableSorting: true },
    { headerName: 'Produto', field: 'product' },
    {
      field: 'debt_status',
      headerName: 'Situação',
      renderCell: (value: any) => renderDebtStatus(DebtStatus[value as keyof typeof DebtStatus]),
    },
  ]

  const defaultProps: TableProps<Contract> = {
    columns: contractsTableColumns,
    isLoading,
    orderBy: orderBy,
    rows: source,
    sorted: true,
    sortingHandler: value => setOrderBy(value),
  }

  const propsWithOnClick = assoc(
    'onRowClick',
    (params: TableRowParams<Contract>) => handleRowClick(params),
    defaultProps
  )

  return (
    <Box paddingTop="2.5rem">
      <PageWithTableControlBar>
        <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid container alignItems="center">
            <Grid item sm={5}>
              <Search
                placeholder="Procure pelo nome do aluno ou responsável"
                onClick={() => events.searchContract(referenceYear)}
                onChange={debounce(800, value => {
                  setName(value)
                })}
                onClear={() => setName('')}
              />
            </Grid>

            <Grid item sm={7} style={{ textAlign: 'right' }}>
              <TableFilter
                filters={filters}
                filtersSetter={setFilters}
                filterOptions={filterOptions}
                filterEventHandler={() => events.filterContract(referenceYear)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Table<Contract> {...propsWithOnClick} />
        </Grid>

        <PageTableControlBar>
          <Pagination
            currentPage={page}
            itensPerPage={itensPerPage}
            itensPerPageOptions={initialItensPerPageOptions}
            totalItens={total}
            onPageChange={newPage => {
              setPage(newPage)
            }}
            onItensPerChangeChange={newItensPerPage => {
              setItensPerPage(newItensPerPage)
            }}
          />
          {isAddContractButtonEnabled && (
            <ButtonGroup>
              <Button
                variation="primary"
                startIcon={<AddIcon />}
                onClick={() =>
                  history.push(getNavigationUrl({ path: `/contratos/${referenceYear}/novo` }))
                }
              >
                Adicionar Contrato
              </Button>
            </ButtonGroup>
          )}
        </PageTableControlBar>
      </PageWithTableControlBar>
    </Box>
  )
}

export default ContractsYear

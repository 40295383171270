import { Text } from '@gravity/text'
import { Link } from '@olaisaac/design-system'
import styled from 'styled-components'
import Icon from '@mdi/react'
import { mdiOpenInNew } from '@mdi/js'
import { ReactElement } from 'react'
import { useIrregularStudentsEvents } from '../hooks/useIrregularStudentsEvents'
import { CopyToClipboardButton } from './CopyToClipboardButton'
import { colors } from '@gravity/tokens'
import { Tooltip } from '@material-ui/core'

const BaseboardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  align-items: center;
  background-color: ${colors['colors-background-neutral-2']};
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;
`

const StyledReferenceMessage = styled.div`
  display: flex;
  align-items: center;
`

const Message = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ReferenceLink = ({ url }: { url: string }) => {
  const { sendClickRedirectLinkEvent } = useIrregularStudentsEvents()
  return (
    <StyledLink href={url} onClick={() => sendClickRedirectLinkEvent} target="blank">
      <Icon path={mdiOpenInNew} size="16px" />
      <Text style={{ color: 'inherit' }} variant="button-3">
        Corrigir dados no sistema
      </Text>
    </StyledLink>
  )
}

export const ReferenceMessage = ({
  copyable,
  copyableTooltip,
  identifier,
}: {
  copyable: string
  copyableTooltip: string
  identifier: string
}) => {
  const { sendClickCopyExternalIDEvent } = useIrregularStudentsEvents()

  return (
    <>
      {copyable && (
        <Tooltip title={`Copie o ${identifier} para regularizar no seu sistema`} interactive>
          <StyledReferenceMessage>
            <Message>
              <Text style={{ color: 'inherit' }} variant="button-3">
                {`${identifier}:`}
              </Text>
              <Text style={{ color: 'inherit' }} variant="button-3">
                {copyable}
              </Text>
            </Message>
            <CopyToClipboardButton
              copyableText={copyable}
              tooltipText={copyableTooltip}
              onClick={sendClickCopyExternalIDEvent}
            />
          </StyledReferenceMessage>
        </Tooltip>
      )}
    </>
  )
}

export const IrregularityTableBaseboard = ({
  actionElement,
  callToActionMsg,
}: {
  actionElement: ReactElement
  callToActionMsg: string
}) => {
  return (
    <BaseboardContainer>
      <Text variant="body-1-medium">{callToActionMsg}</Text>
      {actionElement}
    </BaseboardContainer>
  )
}

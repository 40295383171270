import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApi } from '@/utils/hooks/useApi'
import { CreditType, Eligibility } from '@/shared/services/credit/types'

const anticipationEligibilityQueryKey = 'anticipation-elegibility'

export const useAnticipationEligibility = (
  schoolId: string,
  options?: UseQueryOptions<Eligibility>
) => {
  const { api } = useApi()

  return useQuery<Eligibility>(
    [anticipationEligibilityQueryKey, schoolId],
    async () => {
      const { data } = await api.credit.getSchoolEligibility(schoolId, CreditType.AR)
      return data
    },
    {
      retry: 1,
      ...options,
    }
  )
}

import { useRouteMatch } from 'react-router-dom'

import { routes } from './routes'

import type { ModuleDefinition } from '@/shared/core/module'

export const useRegisterStudentsModule: ModuleDefinition = module => {
  const { path: basePath } = useRouteMatch()

  module.registerRoutes(routes, { basePath })
}

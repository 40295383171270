import { useHistory } from 'react-router-dom'
import { useEnrollmentYear } from '../SchoolEnrollmentCycle/hooks/useEnrollmentYear'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useEffect } from 'react'
import * as Styled from './styles'
import { PageWrapper } from '@/shared/components/PageWrapper'
import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { InteractiveCard } from '../../components/InteractiveCard'
import { Button } from '@gravity/button'

export const EnrollmentEntryPage = () => {
  const history = useHistory()
  const { selectedYear, isCampaignButtonEnabled } = useEnrollmentYear()
  const isDigitalSignatureEnabled = useUnleashFlag(UnleashFlags.MAT_673_ENABLE_DIGITAL_SIGNATURE)
  const isIsaacScoreEnabled = useUnleashFlag(UnleashFlags.MAT_616_ENABLE_ISAAC_SCORE)

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Matrículas' })
  const { schoolSlug } = useSelectedSchool()

  useEffect(() => {
    if (!isDigitalSignatureEnabled) {
      history.push(`/${schoolSlug}/matriculas`)
    }
  }, [isDigitalSignatureEnabled])

  const handleIsaacScoreClick = () => {
    history.push(`/${schoolSlug}/matriculas/score`)
  }

  const handleDigitalSignatureClick = () => {
    history.push(`/${schoolSlug}/matriculas/assinatura-digital`)
  }

  const handleCampaignClick = () => {
    history.push(`/${schoolSlug}/campanhas/${selectedYear}`)
  }

  const features = [
    {
      title: 'Campanha de rematrícula',
      description:
        'Facilite a renovação escolar, permitindo gerenciar alunos, adicionar mensalidades e personalizar condições de forma prática',
      buttonText: 'Gerenciar campanhas',
      buttonClick: handleCampaignClick,
      disabled: !isCampaignButtonEnabled,
    },
    {
      title: 'Contrato digital',
      description:
        'Configure seu contrato digital e simplifique a gestão de seus documentos com os responsáveis.',
      buttonText: 'Configurar contrato',
      buttonClick: handleDigitalSignatureClick,
      disabled: false,
    },
    {
      title: 'isaac Score',
      description:
        'Consulte o score de risco dos responsáveis para facilitar sua tomada de decisão na hora de matrícula.',
      buttonText: 'Consultar score',
      buttonClick: handleIsaacScoreClick,
      disabled: !isIsaacScoreEnabled,
    },
  ]

  return (
    <PageWrapper>
      <Styled.PageWrapper>
        <Styled.CardsContainer>
          {features.map((feature, index) => {
            return (
              <InteractiveCard key={index} title={feature.title} description={feature.description}>
                <Button onClick={() => feature.buttonClick()} disabled={feature.disabled}>
                  {feature.buttonText}
                </Button>
              </InteractiveCard>
            )
          })}
        </Styled.CardsContainer>
      </Styled.PageWrapper>
    </PageWrapper>
  )
}

import { useEffect, useRef, useState } from 'react'

export type SectionObserverProps = {
  sections: string[]
  viewPercentageActivation?: number
}

export const useSectionObserver = ({
  sections,
  viewPercentageActivation = 0.9,
}: SectionObserverProps) => {
  const [activeSectionId, setActiveSectionId] = useState<string>(sections[0])
  const sectionRefs = useRef<Map<string, HTMLElement | null>>(new Map())

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveSectionId(entry.target.id)
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: viewPercentageActivation, // percentage of the section must be visible (default 0.9)
      }
    )

    sectionRefs.current.forEach(section => {
      if (section) observer.observe(section)
    })

    // Cleanup
    return () => {
      sectionRefs.current.forEach(section => {
        if (section) observer.unobserve(section)
      })
    }
  }, [sectionRefs])

  const handleActiveSection = (id: string) => {
    const section = sectionRefs.current.get(id)
    if (section) section.scrollIntoView({ behavior: 'smooth' })
  }

  const registerSection = (id: string, element: HTMLElement | null) => {
    sectionRefs.current.set(id, element)
  }

  return { activeSectionId, registerSection, handleActiveSection }
}

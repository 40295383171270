import styled from 'styled-components'
import { colors, spacing, borderRadius, fontSize, lineHeight } from '@gravity/tokens'

type CardContainerProps = {
  isSelected: boolean
}

export const CardContainer = styled.article<CardContainerProps>`
  padding: ${spacing[3]};
  border: 1px solid;
  border-color: ${props =>
    props.isSelected ? colors['colors-interaction-primary-4'] : colors['colors-border-neutral-3']};
  background-color: ${props => props.isSelected && colors['colors-background-accent-3']};
  border-radius: ${borderRadius[2]};
  cursor: pointer;
  &:hover {
    background-color: ${props => !props.isSelected && colors['colors-background-neutral-2']};
  }
`

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing[4]};
`

export const BigNumberWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

export const BigNumber = styled.span`
  font-size: ${fontSize['2xl']};
  font-weight: bold;
  margin-right: ${spacing[2]};
  line-height: ${lineHeight.normal};
`
export const LoadingCard = styled.div`
  display: flex;
  height: 112px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${spacing[2]};

  border-radius: ${borderRadius[2]};
  border: 1px solid ${colors['colors-border-neutral-3']};
`

import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'

import { reportService } from '@/escolas/services/report'

import type { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'

export const useIsaacPayPayoutReports = (
  schoolId: string,
  options?: UseQueryOptions<UnguaranteedReport[]>
) => {
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.privateApi)

  return useQuery<UnguaranteedReport[]>(
    ['reports', schoolId, 'isaac-pay'],
    async () => {
      const { data } = await service.fetchReportList({
        schoolId,
        reportType: 'UNGUARANTEED',
        payoutDomain: 'IP',
      })

      return data
    },
    options
  )
}

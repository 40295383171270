import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { useApi } from '@/utils/hooks/useApi'
import { Guardian } from 'src/shared/interfaces'

export type updateGuardianPayload = {
  _guardianId: string
  newGuardian?: Omit<Guardian, 'address' | 'address_id' | 'created_at' | 'id'>
  schoolId: string
}

export type useGuardianCommands = {
  updateGuardian: (
    payload: updateGuardianPayload
  ) => Promise<Omit<Guardian, 'address' | 'address_id' | 'created_at' | 'id'>>
}

export type useGuardianQueries = {
  cleanGuardianCache: () => void
  guardian: Guardian
  isUpdateGuardianError: boolean
  isUpdateGuardianLoading: boolean
  isUpdateGuardianSuccess
}

export const useGuardian = (guardianId?: string): useGuardianCommands & useGuardianQueries => {
  const { api } = useApi()
  const queryClient = useQueryClient()
  const queryKey = ['guardian', guardianId]

  const { data: guardian } = useQuery<Guardian>(queryKey, () => api.guardians.get(guardianId), {
    ...SOFTCACHE_OPTIONS_BASE,
    enabled: Boolean(guardianId),
    staleTime: 10 * 60 * 1000,
  })

  const {
    mutateAsync: updateGuardian,
    isLoading: isUpdateGuardianLoading,
    isError: isUpdateGuardianError,
    isSuccess: isUpdateGuardianSuccess,
  } = useMutation(
    ({ _guardianId, newGuardian, schoolId }: updateGuardianPayload) =>
      api.guardians.update(_guardianId, newGuardian, schoolId),
    {
      onSuccess: async updatedGuardian => {
        await queryClient.invalidateQueries(queryKey)
        return queryClient.setQueryData(queryKey, updatedGuardian)
      },
    }
  )

  const cleanGuardianCache = () => queryClient.invalidateQueries(queryKey)

  return {
    cleanGuardianCache,
    guardian,
    isUpdateGuardianError,
    updateGuardian,
    isUpdateGuardianLoading,
    isUpdateGuardianSuccess,
  }
}

import { useSchool } from './useSchool'
import { useQuery } from '@tanstack/react-query'
import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { useApi } from '@/utils/hooks/useApi'
import { OnboardingRedirect } from 'src/shared/interfaces'

export const useOnboardingRedirect = () => {
  const { api } = useApi()
  const { school } = useSchool()

  const school_id = school?.id

  const { data, isFetching, isFetched } = useQuery<OnboardingRedirect>(
    ['onboarding-redirect', school_id],
    () => api.selfOnboarding.onboardingRedirect(school_id),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(school_id),
      staleTime: 10 * 60 * 1000,
      retry: false,
    }
  )

  return {
    isFetching,
    isFetched,
    data,
  }
}

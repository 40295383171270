import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

import { Stepper } from '@/modules/enrollment/components/Stepper'
import { CampaignProvider } from '@/modules/enrollment/contexts/CampaignContext'
import { useSchoolEnrollmentCycleContext } from '@/modules/enrollment/contexts/SchoolEnrollmentContext'
import { useEnrollmentCampaignRedirect } from '@/shared/hooks/useEnrollmentCampaignRedirect'

import { PaymentConditionsStep } from './components/PaymentConditionsStep'
import { StudentsStep } from './components/StudentsStep'
import { CampaignPublicityStep } from './components/CampaignPublicityStep'
import { ReviewStep } from './components/ReviewStep'
import { useCampaignStepper } from './hooks/useCampaignStepper'
import * as Styled from './styles'

const NewCampaignWrapper = () => {
  const campaignSteps = [
    { label: 'Condições', component: PaymentConditionsStep },
    { label: 'Alunos', component: StudentsStep },
    { label: 'Divulgação', component: CampaignPublicityStep },
    { label: 'Revisão', component: ReviewStep },
  ]

  const {
    activeStep,
    ActiveComponent,
    handleButtonClick,
    stepsNavHistory,
    handleStepClick,
  } = useCampaignStepper(campaignSteps)
  const history = useHistory()

  const { selectedEnrollmentCycle } = useSchoolEnrollmentCycleContext()

  const handleStepFinish = () => {
    handleButtonClick(activeStep + 1)
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [activeStep])

  return (
    <Styled.Container>
      <Styled.NewCampaignWrapper>
        <Stepper
          steps={campaignSteps}
          handleStepClick={handleStepClick}
          activeStep={activeStep}
          stepsNavHistory={stepsNavHistory}
          title="Nova campanha"
          // eslint-disable-next-line react/jsx-handler-names
          onClickBackBtn={history.goBack}
        />
        <Styled.ContentContainer>
          <ActiveComponent enrollmentCycle={selectedEnrollmentCycle} onFinish={handleStepFinish} />
        </Styled.ContentContainer>
      </Styled.NewCampaignWrapper>
    </Styled.Container>
  )
}

export const NewCampaign = () => {
  useEnrollmentCampaignRedirect()

  return (
    <CampaignProvider>
      <NewCampaignWrapper />
    </CampaignProvider>
  )
}

import { Button } from '@gravity/button'
import { Icon } from '@mdi/react'
import { mdiLink, mdiReceiptText, mdiPencil } from '@mdi/js'

import { useDrawerActionsInstallment } from '@/modules/guardians/InstallmentsDrawerContainer/components/InstallmentDrawerActions/hooks/useDrawerActionsInstallment'
import { InstallmentDrawerMenu } from '@/modules/guardians/InstallmentsDrawerContainer/components/InstallmentDrawerMenu/InstallmentDrawerMenu'
import ChangePaymentDialog from '@/escolas/components/modal/ChangePaymentMethodDialog'
import { parseInstallmentToReceivable } from '@/modules/guardians/utils/parseInstallmentToReceivable'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/escolas/components/modal/ConfirmationDialog'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'

import { ButtonGroup } from './styles'

export interface InstallmentActionDrawerProps {
  onCloseDrawer: () => void
}

export const InstallmentDrawerActions = ({ onCloseDrawer }: InstallmentActionDrawerProps) => {
  const {
    installmentDrawerInfo,
    showFailedDialog,
    setShowChangePaymentModal,
    showChangePaymentModal,
    setShowFailedDialog,
  } = useInstallmentDrawerContext()

  const {
    isDisabledManualLiquidation,
    isHiddenManualLiquidation,
    isDisabledRenegotiation,
    isHiddenRenegotiation,
    isDisabledAddDiscounts,
    isHiddenAddDiscounts,
    isHiddenEditDueDate,
    isDisabledChangePaymentMethod,
    isHiddenChangePaymentMethod,
    isHiddenEditManualLiquidation,
    handleClickManualLiquidation,
    handleClickRenegotiation,
    handleClickAddDiscounts,
    handleClickEditEnrollmentDueDate,
    handleClickChangePaymentMethod,
    handleClickSharePaymentLink,
    handleClickPrintReceipt,
    handleSuccessChangePaymentMethod,
    handleClickEditManualLiquidation,
  } = useDrawerActionsInstallment(onCloseDrawer)

  if (!installmentDrawerInfo.status) {
    return null
  }

  const receivable = parseInstallmentToReceivable(installmentDrawerInfo)
  const isPayableByCreditCard = installmentDrawerInfo.available_payment_methods?.includes(
    'CREDIT_CARD'
  )
  const changePaymentMethodTitle = isPayableByCreditCard
    ? 'Alterar para pagamento em boleto'
    : 'Alterar para pagamento em cartão'

  const buttonTitle =
    installmentDrawerInfo.status === 'PAID' ? 'Gerar comprovante' : 'Compartilhar link'
  const buttonAction =
    installmentDrawerInfo.status === 'PAID' ? handleClickPrintReceipt : handleClickSharePaymentLink
  const buttonIcon = installmentDrawerInfo.status === 'PAID' ? mdiReceiptText : mdiLink

  return (
    <>
      <ButtonGroup>
        <Button fullWidth onClick={buttonAction} iconStart={<Icon size={2} path={buttonIcon} />}>
          {buttonTitle}
        </Button>

        {!isHiddenEditManualLiquidation && (
          <Button
            fullWidth
            variant="ghost"
            onClick={handleClickEditManualLiquidation}
            iconStart={<Icon size={2} path={mdiPencil} />}
          >
            Editar recebimento
          </Button>
        )}
      </ButtonGroup>

      <InstallmentDrawerMenu
        items={[
          {
            title: 'Registrar recebimento',
            onClick: handleClickManualLiquidation,
            disabled: isDisabledManualLiquidation,
            hidden: isHiddenManualLiquidation,
          },
          {
            title: 'Renegociar',
            onClick: handleClickRenegotiation,
            disabled: isDisabledRenegotiation,
            hidden: isHiddenRenegotiation,
          },
          {
            title: 'Editar descontos',
            onClick: handleClickAddDiscounts,
            disabled: isDisabledAddDiscounts,
            hidden: isHiddenAddDiscounts,
          },
          {
            title: 'Editar vencimento',
            onClick: handleClickEditEnrollmentDueDate,
            hidden: isHiddenEditDueDate,
          },
          {
            title: changePaymentMethodTitle,
            onClick: handleClickChangePaymentMethod,
            disabled: isDisabledChangePaymentMethod,
            hidden: isHiddenChangePaymentMethod,
            helpDescription: isDisabledChangePaymentMethod
              ? 'Não é possível alterar a forma de pagamento de uma parcela vencida'
              : '',
          },
        ]}
      />

      <FailureFeedbackDialog
        buttonLabel="Voltar"
        isVisible={showFailedDialog}
        onClose={() => setShowFailedDialog(false)}
        submitHandler={() => setShowFailedDialog(false)}
        centered
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>

      <ChangePaymentDialog
        isVisible={showChangePaymentModal}
        buttonLabel="Confirmar alteração"
        onClose={() => setShowChangePaymentModal(false)}
        submitHandler={() => null}
        closeIcon
        backButtonLabel="Voltar"
        maxWidth="sm"
        title={changePaymentMethodTitle}
        receivableId={receivable?.id}
        setShowChangePaymentModal={setShowChangePaymentModal}
        isPayableByCreditCard={isPayableByCreditCard}
        replaceReceivables={handleSuccessChangePaymentMethod}
        setShowFailedDialog={setShowFailedDialog}
      />
    </>
  )
}

import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'
import { spacing } from '@gravity/tokens'

import { Installment } from '@/modules/guardians/GuardianDetails/types'
import { useGuardianDetailsInvoice } from '@/modules/guardians/GuardianDetailsInvoicesTab/hooks/useGuardianDetailsInvoice'
import { formatter } from '@/modules/guardians/GuardianDetailsInstallmentsTab/utils/formatter'
import { paramsDict } from '@/modules/guardians/utils/mapDataToRows'
import { GuardianInvoicesTableActions } from '@/modules/guardians/GuardianDetailsInvoicesTab/components/GuardianInvoicesTableActions'
import {
  InstallmentRowType,
  InstallmentStatus,
} from '@/modules/guardians/GuardianDetails/constants'

import { Row } from './types'
import { Wrapper, TableRow, TableBadgeCell } from './styles'

interface GuardianInvoicesTableProps {
  installments: Installment[]
  loading?: boolean
  onRowClick: (row: Row) => void
}

export const GuardianInvoicesTable = ({
  installments,
  onRowClick,
  loading,
}: GuardianInvoicesTableProps) => {
  const {
    rows,
    batchSelection,
    enableBatchSelection,
    handleRowSelect,
    handleSelectAll,
  } = useGuardianDetailsInvoice({ installments })

  const isOverdueEnrollment = ({
    row_type,
    status,
  }: {
    row_type: InstallmentRowType
    status: InstallmentStatus
  }) =>
    row_type === InstallmentRowType.ENROLLMENT_INSTALLMENT && status === InstallmentStatus.OVERDUE

  const checkboxStyle = { maxWidth: spacing[8], width: spacing[8] }

  const shouldShowStatusBadgeTooltip = row => paramsDict.PENDING.text === row.data.status.text
  const handleStatusBadgeClick = (
    row: Row,
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
  ) => {
    if (row.data.disabled && shouldShowStatusBadgeTooltip(row)) {
      event.stopPropagation()
    }
  }

  return (
    <Wrapper>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell
            style={checkboxStyle}
            name="checkbox"
            enableCheckbox={enableBatchSelection}
            onChange={handleSelectAll}
            checked={batchSelection}
          />
          <Table.HeaderCell minWidth={80} name="vencimento">
            Vencimento
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="fatura">
            Fatura
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="competencia">
            Competência
          </Table.HeaderCell>
          <Table.HeaderCell name="aluno">Aluno</Table.HeaderCell>
          <Table.HeaderCell name="produto">Produto</Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="valor-a-pagar">
            Valor a pagar
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={140} name="situacao">
            Situação
          </Table.HeaderCell>
        </Table.Head>

        <Table.Body>
          {loading ? (
            <>
              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>
            </>
          ) : (
            rows?.map(row => (
              <TableRow key={row.urn} onClick={() => onRowClick(row)} disabled={row.data.disabled}>
                {isOverdueEnrollment(row) ? (
                  <Tooltip
                    text="Altere a data de vencimento desta fatura para que ela possa ser paga."
                    position="right"
                    style={{ maxWidth: '262px' }}
                  >
                    <Table.CustomCell
                      style={checkboxStyle}
                      enableCheckbox={row.data.enableCheckboxVisibility}
                      onChange={() => handleRowSelect(row.urn)}
                      checked={row.data.checked}
                      disabled={!row.data.enableCheckbox}
                    />
                  </Tooltip>
                ) : (
                  <Table.CustomCell
                    style={checkboxStyle}
                    enableCheckbox={row.data.enableCheckboxVisibility}
                    onChange={() => handleRowSelect(row.urn)}
                    checked={row.data.checked}
                    disabled={!row.data.enableCheckbox}
                  />
                )}
                <Table.TextCell>{row.data.due_date}</Table.TextCell>
                <Table.TextCell subtext={row.data.order_reference}>{row.data.type}</Table.TextCell>
                <Table.TextCell>{row.data.accrual_date}</Table.TextCell>
                <Table.TextCell>{row.student.name}</Table.TextCell>
                <Table.TextCell subtext={row.data.negotiation_id}>
                  {row.product_name}
                </Table.TextCell>
                <Table.TextCell>{formatter.amount(row.amount)}</Table.TextCell>

                <Tooltip
                  text="A parcela será efetivada após o pagamento da pré-matrícula e quitação de contratos anteriores"
                  hidden={!shouldShowStatusBadgeTooltip(row)}
                  position="left"
                >
                  <TableBadgeCell
                    badgeVariant="soft"
                    badgeColor={row.data.status.color}
                    onClick={evt => handleStatusBadgeClick(row, evt)}
                    enableTooltip={shouldShowStatusBadgeTooltip(row)}
                  >
                    {row.data.status.text}
                  </TableBadgeCell>
                </Tooltip>

                <GuardianInvoicesTableActions installment={row} />
              </TableRow>
            ))
          )}
        </Table.Body>
      </Table.Root>
    </Wrapper>
  )
}

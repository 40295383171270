import { FamilyRegularizationReport } from '@monorepo/account-reports/pages/FamilyRegularizationReport'
import { FidcConfirmationReports } from '@monorepo/account-reports/pages/FidcConfirmationReports'
import { Reports } from '../pages/Reports'
import { IsaacPayIncomeReport } from '../pages/IsaacPayIncomeReport'
import { EnrollmentCampaignReport } from '../pages/EnrollmentCampaignReport'

import type { RouteDefinition } from '@/shared/core/router'
import { TeachingMaterialIncomeReport } from '../pages/TeachingMaterialIncomeReport'

export const routes: RouteDefinition[] = [
  {
    key: 'REPORTS',
    path: '/relatorios',
    component: Reports,
    exact: true,
  },
  {
    key: 'FAMILY_REGULARIZATION_REPORT',
    path: '/relatorio/regularizacao-das-familias',
    component: FamilyRegularizationReport,
    exact: true,
  },
  {
    key: 'ISAAC_PAY_INCOME_REPORT',
    path: '/relatorio/visao-de-recebimentos',
    component: IsaacPayIncomeReport,
    exact: true,
  },
  {
    key: 'FIDC_REPORT',
    path: '/relatorios/validacao-de-contratos',
    component: FidcConfirmationReports,
    exact: true,
  },
  {
    key: 'REDIRECT_REPORT',
    path: '/relatorios/*',
    redirect: '/relatorios',
  },

  {
    key: 'ENROLLMENT_CAMPAIGN_REPORT',
    path: '/relatorio/campanha-de-rematricula',
    component: EnrollmentCampaignReport,
    exact: true,
  },
  {
    key: 'TEACHING_MATERIAL_INCOME_REPORT',
    path: '/relatorio/visao-de-vendas-do-material-didatico',
    component: TeachingMaterialIncomeReport,
    exact: true,
  },
]

import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { omit, propEq } from 'ramda'
import dayjs, { Dayjs } from 'dayjs'
import {
  DatePicker,
  Checkbox,
  Radio,
  FormControlLabel,
  Notification,
  Typography,
} from '@olaisaac/design-system'
import {
  Box,
  Divider,
  RadioGroup,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import {
  DiscountType,
  LiquidationInfoResponse,
  LiquidationPaymentMethod,
  LiquidationPaymentMethodAdminLabel,
  LiquidationPaymentMethodLabel,
  LiquidationSource,
  Receivable,
  ReceivableStatuses,
} from 'src/shared/interfaces'
import {
  DATE_FORMAT,
  formatCentsToReal,
  formatRealToCents,
  getEndOfToday,
  getStartOfToday,
  makeIsDayAfterDate,
  makeIsDayBeforeDate,
  validateEditReason,
} from 'src/shared/utils'
import DrawerForm from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/DrawerForm'
import { useJWT, useSnackbar } from 'src/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { useContract, useNavigation } from 'src/escolas/hooks'
import { FeatureFlags, useFeatureFlag } from 'src/shared/hooks/useFeatureFlag'
import {
  UnleashFlags,
  useUnleashFlag as unleashFlag,
  useUnleashFlag,
} from 'src/shared/hooks/useUnleashFlag'

import { useSendManualLiquidationEvent } from '@/modules/guardians/ManualLiquidation/hooks/manualLiquidation/useSendManualLiquidationEvents'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-bottom: 0px;
  }
`

const NoWrapTypography = styled(Typography)`
  white-space: nowrap;
`

const NoWrapTypographyDisabled = styled(NoWrapTypography)`
  color: ${({ theme }) => theme.palette.text.disabled};
`

export type LiquidationForm = {
  additional_discount_amount: cents
  apply_fines_and_interest_change: boolean
  apply_lost_due_payment_discount: boolean
  apply_lost_early_payment_discount: boolean
  apply_previous_fines_and_interest: boolean
  edit_reason: string
  paid_date: Dayjs
  payment_method: LiquidationPaymentMethod
  source: LiquidationSource
}

export type ManualLiquidationContentProps = {
  goToConfirmManualLiquidationContent: (submitHandler: any) => void
  onFinish: () => void
  onSuccess?: () => void
  receivables: Array<Receivable>
  removeIsaacOption?: boolean
  resetManualLiquidationForm: boolean
  selectedReceivableId: uuid
  setIsFailureFeedbackOpen?: (value: boolean) => void
  setIsSubmitting: (value: boolean) => void
  setSelectedReceivableId: Dispatch<SetStateAction<uuid>>
  successCallback: (receivables: Array<Receivable>) => void
}

const ManualLiquidationContent: FC<ManualLiquidationContentProps> = ({
  onFinish,
  onSuccess,
  receivables,
  removeIsaacOption,
  selectedReceivableId,
  setSelectedReceivableId,
  successCallback,
  goToConfirmManualLiquidationContent,
  setIsSubmitting,
  resetManualLiquidationForm,
}) => {
  const { api } = useApi()
  const { isAdmin } = useJWT()
  const { schoolId } = useNavigation()
  const { contract } = useContract()
  const { value: featureFlagValue, config } = useFeatureFlag(
    FeatureFlags.MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG
  )
  const { url } = useRouteMatch()

  const sendManualLiquidationEvent = useSendManualLiquidationEvent()

  const receivable = receivables?.find(propEq('id', selectedReceivableId))
  const isPaid = receivable?.status === ReceivableStatuses.PAID

  const isFeatureFlagEnabled = !isAdmin && featureFlagValue && config.includes(schoolId)

  const isValidatePaidDateBefore7Days = unleashFlag(
    UnleashFlags.PAYMENTS_PEX_273_ENABLE_VALIDATE_PAID_DATE_MIN_7_DAYS_BEFORE
  )

  const mustApplyEarlyPaymentDiscounts = Boolean(
    receivable?.discounts?.find(
      ({ type, days_before_due_date }) =>
        days_before_due_date > 0 && type === DiscountType.KEPT_ON_MANUAL_LIQUIDATION
    )
  )
  const mustApplyFinesAndInterest = receivable?.current_fine + receivable?.current_interest > 0
  let paymentSource = receivable?.payment_source
  if (removeIsaacOption) {
    paymentSource = LiquidationSource.SCHOOL_ACCOUNT
  }

  const form = useForm<LiquidationForm>({
    mode: 'onChange',
    defaultValues: {
      additional_discount_amount: receivable?.current_additional_discount,
      edit_reason: '',
      apply_fines_and_interest_change: mustApplyFinesAndInterest,
      apply_lost_due_payment_discount: receivable?.current_due_payment_discount > 0,
      apply_lost_early_payment_discount: mustApplyEarlyPaymentDiscounts,
      apply_previous_fines_and_interest: mustApplyFinesAndInterest,
      paid_date: null,
      source: paymentSource,
      payment_method: null,
    },
  })

  const { control, formState, getValues, watch, reset, setValue, trigger } = form
  const [liquidationInfo, setLiquidationInfo] = useState<LiquidationInfoResponse>()
  const [
    shouldDisabledRemoveFinesAndInterest,
    setShouldDisabledRemoveFinesAndInterest,
  ] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>()
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const [isAdditionalDiscountEnabled, setIsAdditionalDiscountEnabled] = useState<boolean>(
    receivable?.current_additional_discount > 0
  )
  const [newReceivableId, setNewReceivableId] = useState<uuid>()
  watch([
    'additional_discount_amount',
    'edit_reason',
    'apply_fines_and_interest_change',
    'apply_previous_fines_and_interest',
    'apply_lost_due_payment_discount',
    'apply_lost_early_payment_discount',
    'paid_date',
    'source',
    'payment_method',
  ])
  const {
    additional_discount_amount,
    edit_reason,
    apply_fines_and_interest_change,
    apply_previous_fines_and_interest,
    apply_lost_due_payment_discount,
    apply_lost_early_payment_discount,
    paid_date,
    source,
    payment_method,
  } = getValues()

  const isManualLiquidationPaymentMethodEnabled = source === LiquidationSource.SCHOOL_ACCOUNT

  const isPaymentMethodSelectionEnabled = !isPaid ? isManualLiquidationPaymentMethodEnabled : true

  const onError = () => {
    reset()
    setSnackbarMessage('Ocorreu um erro ao registrar a baixa manual. Tente novamente.')
    setSnackbarIsOpen(true)
  }

  const onSubmitSuccess = () => {
    reset()
    setSnackbarMessage('O recebimento foi registrado com sucesso.')
    setSnackbarIsOpen(true)
  }

  useEffect(() => {
    resetManualLiquidationForm && reset()
  }, [resetManualLiquidationForm])

  useEffect(() => {
    if (isLoading || !paid_date?.isValid()) return

    const isPaidDateBeforeDueDate = paid_date.isAfter(receivable.due_date)

    setIsLoading(true)
    api.receivables
      .liquidationInfo(selectedReceivableId, {
        additional_discount_amount:
          isAdditionalDiscountEnabled && additional_discount_amount
            ? +additional_discount_amount
            : 0,
        apply_lost_due_payment_discount,
        apply_lost_early_payment_discount,
        is_edition: isPaid,
        paid_date: paid_date
          ?.hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .utc()
          .hour(0)
          .toISOString(),
        remove_fines_and_interest: isPaidDateBeforeDueDate
          ? false
          : !apply_previous_fines_and_interest,
        remove_fines_and_interest_change: !apply_fines_and_interest_change,
      })
      .then(data => {
        setLiquidationInfo(data)
        if (isPaid) {
          setValue('payment_method', data.payment_method)
        }
      })
      .catch(onError)
      .finally(() => setIsLoading(false))
  }, [
    additional_discount_amount,
    apply_fines_and_interest_change,
    apply_previous_fines_and_interest,
    apply_lost_due_payment_discount,
    apply_lost_early_payment_discount,
    paid_date,
    isAdditionalDiscountEnabled,
  ])

  useEffect(() => {
    if (!!paid_date && paid_date.isAfter(receivable.due_date)) {
      setValue('apply_previous_fines_and_interest', true)
      setShouldDisabledRemoveFinesAndInterest(true)
    }
  }, [paid_date])

  useEffect(() => {
    if (!source && !isFeatureFlagEnabled) {
      return
    }

    if (paid_date) {
      return
    }

    const date = receivable?.paid_date ? dayjs(receivable?.paid_date) : dayjs()

    setValue('paid_date', date, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [source])

  useEffect(() => {
    trigger('additional_discount_amount')
  }, [liquidationInfo?.final_amount])

  const submitHandler = async () => {
    isFeatureFlagEnabled && setIsSubmitting(true)

    const resource = isPaid
      ? api.receivables.patchManualLiquidation
      : api.receivables.manualLiquidation

    return await resource(
      selectedReceivableId,
      {
        additional_discount_amount:
          isAdditionalDiscountEnabled && additional_discount_amount
            ? +additional_discount_amount
            : 0,
        ...(isPaid && { edit_reason }),
        apply_lost_due_payment_discount,
        apply_lost_early_payment_discount,
        paid_date: paid_date
          ?.hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .utc()
          .hour(0)
          .toISOString(),
        remove_fines_and_interest: !apply_previous_fines_and_interest,
        remove_fines_and_interest_change: !apply_fines_and_interest_change,
        source: isFeatureFlagEnabled ? LiquidationSource.ISAAC_ACCOUNT : source,
        payment_method: source === LiquidationSource.SCHOOL_ACCOUNT ? payment_method : null,
        triggered_by: url.includes('validacao-de-inadimplencia') ? 'NEVER_PAID' : null,
      },
      schoolId
    )
      .then(updatedReceivables => {
        successCallback([
          ...receivables?.filter(({ id }) => id !== selectedReceivableId),
          ...updatedReceivables,
        ])
        const paidReceivableId = updatedReceivables.find(propEq('status', ReceivableStatuses.PAID))
          ?.id
        setNewReceivableId(paidReceivableId)
        onSuccess && onSuccess()
      })
      .then(() => {
        if (isFeatureFlagEnabled) {
          setIsSubmitting(false)
          setSelectedReceivableId(newReceivableId)
          onSubmitSuccess()
          onFinish()
        }
      })
  }

  const goToConfirmationStep = () => {
    goToConfirmManualLiquidationContent(submitHandler)
  }

  const onSuccessSetReceivableId = () => {
    onFinish()
    setSelectedReceivableId(newReceivableId)
  }

  const validateTotalDiscount = () => {
    const amount = liquidationInfo?.final_amount || 0
    const fineAndInterest = liquidationInfo?.final_fine_and_interest || 0
    return amount - fineAndInterest > 0
  }

  const hasSelectedSource = Boolean(source)
  let { fine_and_interest, previous_fines_and_interest } = liquidationInfo || {}

  const hasFineAndInterest = fine_and_interest !== 0
  let fineAndInterestChange = 0
  let hasLostEarlyPaymentDiscount = false
  let hasLostDuePaymentDiscount = false
  let hasOnlyAdditionDiscount = false

  hasLostEarlyPaymentDiscount = liquidationInfo?.lost_early_payment_discount !== 0
  hasLostDuePaymentDiscount = liquidationInfo?.lost_due_payment_discount !== 0
  hasOnlyAdditionDiscount = !hasFineAndInterest && !hasLostEarlyPaymentDiscount

  if (isPaid) {
    fineAndInterestChange = fine_and_interest - previous_fines_and_interest
  } else {
    previous_fines_and_interest = fine_and_interest
  }

  const isRenegotiated =
    receivable?.original_receivables?.length > 0 &&
    receivable?.original_receivables?.some(
      r =>
        r?.status === ReceivableStatuses.RENEGOTIATED ||
        r?.status === ReceivableStatuses.AGGLUTINATED
    )
  const isAdditionalDiscountAmountInvalid = Boolean(
    formState.isDirty && formState.errors.additional_discount_amount
  )

  const getWarningMessage = () => {
    let msg = ''
    const duePaymentDiscountChange =
      liquidationInfo?.lost_due_payment_discount - liquidationInfo?.previous_due_payment_discount
    const earlyPaymentDiscountChange =
      liquidationInfo?.lost_early_payment_discount -
      liquidationInfo?.previous_early_payment_discount

    if (!duePaymentDiscountChange && !earlyPaymentDiscountChange && !fineAndInterestChange) {
      if (isRenegotiated) {
        msg = 'A parcela já foi renegociada e não pode receber desconto adicional.'
      }
      return msg
    }

    const getDifferLabel = (change: number) => (change > 0 ? 'aumentou' : 'diminui')
    const getDiscountMessage = (msg: any, change: any, discountType: any) =>
      `${msg.length > 0 ? ' e' : ''} ${getDifferLabel(change)} ${formatCentsToReal(
        change
      )} por perda ${discountType}`

    if (fineAndInterestChange !== 0) {
      msg += `${getDifferLabel(fineAndInterestChange)} ${formatCentsToReal(
        Math.abs(fineAndInterestChange)
      )} do valor em multa e juros`
    }

    let discountChangeMessage = ''
    let discountChangeAmount = 0
    if (duePaymentDiscountChange && earlyPaymentDiscountChange) {
      discountChangeAmount = duePaymentDiscountChange + earlyPaymentDiscountChange
      discountChangeMessage = 'dos descontos'
    } else if (duePaymentDiscountChange) {
      discountChangeAmount = duePaymentDiscountChange
      discountChangeMessage = 'do desconto de pontualidade'
    } else if (earlyPaymentDiscountChange) {
      discountChangeMessage = 'do desconto de antecipação'
      discountChangeAmount = earlyPaymentDiscountChange
    }

    if (discountChangeMessage.length > 0) {
      msg += getDiscountMessage(msg, Math.abs(discountChangeAmount), discountChangeMessage)
    }
    if (msg.length > 0) {
      msg = `A mudança de data ${msg}.`
    }

    return msg
  }
  const warningMessage = getWarningMessage()

  const pilotSchoolsDrawerFormProps = {
    form: form,
    buttonDockerDescription: `Valor recebido ${formatCentsToReal(
      liquidationInfo?.final_amount || 0
    )}`,
    disableFeedback: true,
    onSubmit: goToConfirmationStep,
    isLoading,
    border: false,
  }

  const defaultDrawerFormProps = {
    form: form,
    buttonDockerDescription: `Valor recebido ${formatCentsToReal(
      liquidationInfo?.final_amount || 0
    )}`,
    errorTitle: 'Volte e registre novamente',
    errorMessage: 'Houve uma falha inesperada e não conseguimos registrar o recebimento.',
    successMessage: 'O recebimento foi registrado com sucesso.',
    onFinish: onSuccessSetReceivableId,
    onSubmit: submitHandler,
    isLoading,
    border: false,
  }

  const drawerFormProps = isFeatureFlagEnabled
    ? pilotSchoolsDrawerFormProps
    : defaultDrawerFormProps

  const dateByType = !isPaid
    ? getStartOfToday().subtract(7, 'day').utc()
    : dayjs(receivable?.paid_date).subtract(7, 'day')

  const minDate = isValidatePaidDateBefore7Days ? dateByType : dayjs(contract?.created_at)

  const maxDate =
    isPaid && isValidatePaidDateBefore7Days ? dayjs(receivable?.paid_date).utc() : getEndOfToday()

  const getLatestMinDate = () => {
    if (isValidatePaidDateBefore7Days) {
      if (dayjs(contract?.created_at).isAfter(minDate)) {
        return dayjs(contract?.created_at)
      }
    }
    return minDate
  }
  const helperText = (date: Dayjs) => {
    if (isValidatePaidDateBefore7Days && !date?.isSame(dayjs(maxDate).utc())) {
      if (makeIsDayBeforeDate(dayjs(dayjs(contract?.created_at)).utc())(date)) {
        return `Data não pode ser menor que ${getLatestMinDate().format('DD/MM/YYYY')}`
      }
      if (makeIsDayBeforeDate(dayjs(minDate).utc())(date)) {
        sendManualLiquidationEvent(
          EventDispatcherEvents.WARNING_VIEWED,
          EventDispatcherEntities.MANUAL_LIQUIDATION_PAST_DATE_WARNING,
          null,
          {
            name: 'Alerta data retroativa',
            selected_date: date.toISOString(),
            receivable_id: selectedReceivableId,
            contract_id: contract.id,
          }
        )
        return isPaid
          ? 'Você deve selecionar uma data de até 7 dias anteriores à última data de recebimento.'
          : 'Você deve selecionar uma data de até 7 dias anteriores ao dia de hoje.'
      }
      if (makeIsDayAfterDate(dayjs(maxDate).utc())(date)) {
        return isPaid
          ? 'Data não pode ser depois da última data de recebimento'
          : 'Data não pode ser futura.'
      }
    }
    if (!isValidatePaidDateBefore7Days) {
      if (makeIsDayBeforeDate(dayjs(minDate).utc())(date)) {
        return `Data não pode ser menor que ${minDate.format('DD/MM/YYYY')}`
      }
      if (makeIsDayAfterDate(dayjs(maxDate).utc())(date)) {
        return `Data não pode ser futura.`
      }
    }

    return ''
  }

  const validateDate = (date: Dayjs) => {
    return helperText(date) === ''
  }

  const isPortfolioRepurchaseEnabled = useUnleashFlag(
    UnleashFlags.PAYMENTS_PEX_237_ENABLE_PORTFOLIO_REPURCHASE_OPTION_ON_MANUAL_LIQUIDATION
  )
  const LiquidationPaymentMethodOptions =
    isAdmin && isPortfolioRepurchaseEnabled
      ? LiquidationPaymentMethodAdminLabel
      : LiquidationPaymentMethodLabel

  return (
    <>
      {warningMessage.length > 0 && (
        <Notification description={warningMessage} variation="warning" />
      )}
      <DrawerForm {...drawerFormProps}>
        {!removeIsaacOption && !isFeatureFlagEnabled ? (
          <>
            <Box pb={2} pt={1}>
              <Typography variation="bodyLarge">Onde a parcela foi recebida?</Typography>
            </Box>
            <FormControl component="fieldset">
              <Controller
                rules={{ required: false }}
                control={control}
                name="source"
                render={({ field }) => (
                  <RadioGroup aria-label="source" {...field}>
                    <StyledFormControlLabel
                      value={LiquidationSource.ISAAC_ACCOUNT}
                      control={<Radio />}
                      label="Na conta do isaac"
                    />
                    <Box pl={4} pb={2}>
                      <Typography variation="caption" color="secondary">
                        O recebimento foi na maquininha isaac ou via transferência, e o valor está
                        na conta do isaac.
                      </Typography>
                    </Box>
                    <StyledFormControlLabel
                      value={LiquidationSource.SCHOOL_ACCOUNT}
                      control={<Radio />}
                      label="Na conta da escola"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
            <Box my={3}>
              <Divider />
            </Box>
          </>
        ) : (
          <Box my={3} />
        )}

        <Box mb={3}>
          <Typography
            variation="bodyLarge"
            color={hasSelectedSource || isFeatureFlagEnabled ? 'primary' : 'secondary'}
          >
            Quando a parcela foi recebida?
          </Typography>
        </Box>
        <Controller
          rules={{
            required: true,
            validate: validateDate,
          }}
          name="paid_date"
          control={control}
          render={({ field }) => (
            <DatePicker
              disabled={!hasSelectedSource && !isFeatureFlagEnabled}
              invalidDateMessage="Data inválida"
              id="paid_date"
              helperText={helperText(field.value)}
              error={helperText(field.value) !== ''}
              fullWidth
              label="Data de recebimento"
              format={DATE_FORMAT}
              disableFuture
              {...omit(['ref'], field)}
            />
          )}
        />
        {!hasOnlyAdditionDiscount && (
          <>
            <Box my={3}>
              <Divider />
            </Box>
            <Box mb={2}>
              <Typography
                variation="bodyLarge"
                color={hasSelectedSource || isFeatureFlagEnabled ? 'primary' : 'secondary'}
              >
                O que foi mantido?
              </Typography>
            </Box>
          </>
        )}
        {hasFineAndInterest && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Controller
              defaultValue
              name="apply_previous_fines_and_interest"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <FormControlLabel
                  disabled={
                    (!hasSelectedSource && !isFeatureFlagEnabled) ||
                    shouldDisabledRemoveFinesAndInterest
                  }
                  control={
                    <Checkbox
                      checkedLink={undefined}
                      valueLink={undefined}
                      {...rest}
                      checked={value}
                    />
                  }
                  label="Multa e juros"
                />
              )}
            />
            <NoWrapTypography variation="bodyLarge" color="secondary">
              {formatCentsToReal(previous_fines_and_interest || 0)}
            </NoWrapTypography>
          </Box>
        )}
        {hasFineAndInterest && fineAndInterestChange !== 0 && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Controller
              defaultValue
              name="apply_fines_and_interest_change"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <FormControlLabel
                  disabled={
                    !hasSelectedSource ||
                    !isFeatureFlagEnabled ||
                    !apply_previous_fines_and_interest
                  }
                  control={
                    <Checkbox
                      checkedLink={undefined}
                      valueLink={undefined}
                      {...rest}
                      checked={!apply_previous_fines_and_interest ? false : value}
                    />
                  }
                  label="Multa e juros mudança de data"
                />
              )}
            />
            {apply_previous_fines_and_interest ? (
              <NoWrapTypography variation="bodyLarge" color="secondary" aria-disabled>
                {formatCentsToReal(fineAndInterestChange || 0)}
              </NoWrapTypography>
            ) : (
              <NoWrapTypographyDisabled variation="bodyLarge" color="secondary" aria-disabled>
                {formatCentsToReal(fineAndInterestChange || 0)}
              </NoWrapTypographyDisabled>
            )}
          </Box>
        )}
        {hasLostEarlyPaymentDiscount && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Controller
              defaultValue={false}
              name="apply_lost_early_payment_discount"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <FormControlLabel
                  disabled={!hasSelectedSource && !isFeatureFlagEnabled}
                  control={
                    <Checkbox
                      checkedLink={undefined}
                      valueLink={undefined}
                      {...rest}
                      checked={value}
                    />
                  }
                  label="Descontos de antecipação"
                />
              )}
            />
            <NoWrapTypography variation="bodyLarge" color="secondary">
              - {formatCentsToReal(liquidationInfo?.lost_early_payment_discount || 0)}
            </NoWrapTypography>
          </Box>
        )}
        {hasLostDuePaymentDiscount && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Controller
              defaultValue={false}
              name="apply_lost_due_payment_discount"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <FormControlLabel
                  disabled={!hasSelectedSource && !isFeatureFlagEnabled}
                  control={
                    <Checkbox
                      checkedLink={undefined}
                      valueLink={undefined}
                      {...rest}
                      checked={value}
                    />
                  }
                  label="Descontos de pontualidade"
                />
              )}
            />
            <NoWrapTypography variation="bodyLarge" color="secondary">
              - {formatCentsToReal(liquidationInfo?.lost_due_payment_discount || 0)}
            </NoWrapTypography>
          </Box>
        )}
        {!isRenegotiated && (
          <>
            <Box my={3}>
              <Divider />
            </Box>
            <Box mb={2}>
              <Typography
                variation="bodyLarge"
                color={hasSelectedSource || isFeatureFlagEnabled ? 'primary' : 'secondary'}
              >
                A escola deu outro desconto no momento do pagamento?
              </Typography>
            </Box>
            <FormControlLabel
              disabled={!hasSelectedSource && !isFeatureFlagEnabled}
              control={
                <Checkbox
                  checkedLink={undefined}
                  valueLink={undefined}
                  onChange={(_, checked) => setIsAdditionalDiscountEnabled(checked)}
                  checked={isAdditionalDiscountEnabled}
                />
              }
              label="Sim, a escola deu um desconto não previsto em contrato."
            />
            <Box pt={3}>
              <FormControl variant="outlined" fullWidth>
                <Controller
                  rules={{ validate: validateTotalDiscount }}
                  control={control}
                  name="additional_discount_amount"
                  defaultValue={0}
                  render={({ field: { onChange, value } }) => (
                    <NumberFormat
                      disabled={!hasSelectedSource && !isFeatureFlagEnabled}
                      id="additional-discount-input"
                      onBlur={event => {
                        onChange(formatRealToCents(event.target.value))
                        setIsAdditionalDiscountEnabled(true)
                      }}
                      customInput={TextField}
                      variant="outlined"
                      label="Valor do desconto"
                      format={formatCentsToReal}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={value}
                      error={isAdditionalDiscountAmountInvalid}
                      helperText={
                        isAdditionalDiscountAmountInvalid
                          ? `O valor máximo de desconto é ${formatCentsToReal(
                              liquidationInfo?.final_base_amount
                            )}`
                          : ''
                      }
                    />
                  )}
                />
              </FormControl>
            </Box>
          </>
        )}

        {isPaymentMethodSelectionEnabled ? (
          <>
            <Box my={3}>
              <Divider />
            </Box>
            <Box mb={3}>
              <Typography variation="bodyLarge" color="primary">
                O pagamento foi recebido através de qual forma de pagamento?
              </Typography>
            </Box>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="payment_method">Selecione um método de pagamento</InputLabel>
              <Controller
                rules={{
                  required: source === LiquidationSource.SCHOOL_ACCOUNT,
                }}
                control={control}
                name="payment_method"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <Select
                      inputRef={ref}
                      labelId="payment_method"
                      label="Selecione um método de pagamento"
                      data-testid="payment_method_test_id"
                      value={value ?? ''}
                      onChange={e => onChange(e.target.value)}
                      fullWidth
                      error={Boolean(formState.errors.payment_method)}
                    >
                      {Object.keys(LiquidationPaymentMethodOptions).map(payment_method => (
                        <MenuItem key={payment_method} value={payment_method}>
                          {
                            LiquidationPaymentMethodOptions[
                              payment_method as keyof typeof LiquidationPaymentMethodOptions
                            ]
                          }
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(formState.errors.payment_method) && (
                      <FormHelperText error>Selecione um método de pagamento</FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>
          </>
        ) : null}

        {isPaid ? (
          <>
            <Box my={3}>
              <Divider />
            </Box>
            <Box>
              <Typography variation="bodyLarge" color={hasSelectedSource ? 'primary' : 'secondary'}>
                Qual o motivo da edição?
              </Typography>
            </Box>
            <Box mt={2}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{
                    required: true,
                    maxLength: 100,
                    minLength: 5,
                    validate: validateEditReason,
                  }}
                  name="edit_reason"
                  control={control}
                  render={({ field: { value, ...rest }, fieldState: { error } }) => (
                    <TextField
                      {...rest}
                      label="Motivo da edição"
                      id="edit_reason-input"
                      value={value}
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={
                        error ? 'Deve ter entre 5 e 100 caracteres (somente letras e números)' : ''
                      }
                    />
                  )}
                />
              </FormControl>
            </Box>
          </>
        ) : null}
      </DrawerForm>
    </>
  )
}

export default ManualLiquidationContent

import useAddContractEnabled from '@/escolas/hooks/useAddContractEnabled'
import { useEnrollmentMixpanelEvents } from '@/modules/enrollment/hooks/useEnrollmentMixpanelEvents'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useHistory } from 'react-router-dom'
import { EventIdentifierName } from '@/modules/enrollment/models/EventIdentifierName.enum'

export const useEnrollmentActions = (selectedYear: number) => {
  const history = useHistory()
  const { sendButtonClickEvent } = useEnrollmentMixpanelEvents()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const addContractEnabled = useAddContractEnabled()
  const { schoolSlug } = useSelectedSchool()

  const goToCampaignsPage = () => {
    sendButtonClickEvent('gerenciar_campanhas')
    history.push(`campanhas/${selectedYear}`)
  }

  const handleAddStudent = () => {
    history.push(`contratos/${selectedYear}/novo`)
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
        name: EnrollmentEventDispatcherEvents.CLICKED,
        customProperties: { $Button_name: 'ADICIONAR_NOVO_ALUNO' },
      })
  }
  const goToScorePage = () => {
    sendButtonClickEvent(
      'isaac score',
      EventDispatcherEventScopes.ENROLLMENT,
      EventIdentifierName.SCORE_ISAAC
    )
    history.push({ pathname: `/${schoolSlug}/matriculas/score`, search: '' })
  }

  return {
    goToCampaignsPage,
    handleAddStudent,
    addContractEnabled,
    goToScorePage,
  }
}

import { useApiClient } from '@/shared/hooks/useApiClient'
import { creditService } from '@/shared/services/credit'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CreditType, ResponseGetStatus } from '@/shared/services/credit/types'

export const creditOrderStatusQueryKey = 'credit-order-status'

export const useGetOrderStatus = <T extends ResponseGetStatus>(
  schoolId: string,
  creditType?: CreditType,
  options?: UseQueryOptions<T>
) => {
  const { apiClient } = useApiClient()
  const service = creditService(apiClient.getClients().bffApi)

  return useQuery<T>(
    [creditOrderStatusQueryKey, schoolId],
    async () => {
      return await service.getOrderStatus<T>(schoolId, creditType)
    },
    options
  )
}

import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const useIsCampaignEnabled = () => {
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const { school } = useSelectedSchool()
  const isIsaacPay = isIsaacPaySchool(school)

  return !isIsaacPay && !isIntegratedSchool
}

import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query'
import config from '@/config'
import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { useApi } from '@/utils/hooks/useApi'
import { APIResponse, School } from 'src/shared/interfaces'

export const useSchools = () => {
  const { api } = useApi()

  const queryKey = ['schools-list', 1]

  const perPage = Number(config.API.PER_PAGE_MAX)
  const {
    data: { data: firstSchools = [], pagination: firstPagination = {} } = {},
    isFetching: isFirstSchoolsFetching,
  } = useQuery<APIResponse<School[]>>(
    queryKey,
    () => api.schools.getList({ per_page: perPage, page: 1 }),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      staleTime: 10 * 60 * 1000,
    }
  )

  const total = firstPagination?.total ?? 0
  const pagesCount = Math.ceil(total / perPage)
  const otherPagesCount = pagesCount !== 0 ? pagesCount - 1 : pagesCount

  const otherSchoolsResults: UseQueryResult<APIResponse<School[]>>[] = useQueries({
    queries: Array.from(Array(otherPagesCount).keys()).map(i => ({
      queryKey: ['schools-list', i + 2],
      queryFn: () => api.schools.getList({ per_page: perPage, page: i + 2 }),
      ...SOFTCACHE_OPTIONS_BASE,
      staleTime: 10 * 60 * 1000,
    })),
  })

  const isOtherSchoolsFetching = otherSchoolsResults.some(res => res?.isFetching)
  const isFetched = otherSchoolsResults.every(res => res?.isFetched)

  const otherSchools = isFetched
    ? otherSchoolsResults
        .map(res => res.data)
        .reduce((schools, arr) => [...schools, ...arr?.data], [])
    : []

  const schools = isFetched ? [...firstSchools, ...otherSchools] : []

  return {
    schools: schools as School[],
    hasFetched: isFetched,
    isFetching: isFirstSchoolsFetching && isOtherSchoolsFetching,
  }
}

import styled from 'styled-components'
import { Card as CardDS } from '@gravity/card'

export const Card = styled(CardDS)`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.gravity.spacing[12]} ${props => props.theme.gravity.spacing[8]};
  height: 360px;
  justify-content: space-between;
`

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[8]};
`

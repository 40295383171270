import React from 'react'
import Menu from '@material-ui/core/Menu'
import { MenuItem } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CloseIcon from '@material-ui/icons/Close'
import { SystemButton } from '@olaisaac/design-system'
import Tooltip from '@material-ui/core/Tooltip'
import { useJWT } from 'src/shared/hooks'
import config from '@/config'

import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

export type ContractTableActionsProps = {
  canEditDiscounts: boolean
  canEditDueDay: () => boolean
  closeAgglutination: () => void
  contractId: uuid
  guardianId: uuid
  hasInstallmentsToChangeDueDay: boolean
  isAgglutination: boolean
  isProcessingAgglutination: boolean
  openAgglutination: () => void
  openDiscountsEdition: () => void
  openDueDayEdition: () => void
  openPrintSlips: () => void
}
export const ContractTableActions = ({
  contractId,
  guardianId: responsibleId,
  isAgglutination,
  closeAgglutination,
  openAgglutination,
  canEditDueDay,
  canEditDiscounts,
  openDiscountsEdition,
  openDueDayEdition,
  openPrintSlips,
  isProcessingAgglutination,
  hasInstallmentsToChangeDueDay,
}: ContractTableActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { sendHotjarEvent } = useHotjar()
  const { isAdmin } = useJWT()
  const { schoolSlug } = useSelectedSchool()
  const { eventDispatcherClient } = useEventDispatcher()

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isAgglutination) {
      closeAgglutination()
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const close = () => {
    setAnchorEl(null)
  }

  const handlePrintingOpenInstallments = () => {
    sendHotjarEvent(HotjarEvents.PRINT_SLIP)
    openPrintSlips()

    const metadata = {
      contract_id: contractId,
      guardian_id: responsibleId,
      school_slug: schoolSlug,
      name: 'Imprimir parcelas em aberto',
    }

    eventDispatcherClient.sendEvent({
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      scope: EventDispatcherEventScopes.CONTRACT_PAGE,
      customProperties: metadata,
      entity: EventDispatcherEntities.PRINT_OPEN_SLIPS,
    })
  }

  const getHandleClick = (callback: () => void) => () => {
    callback()
    close()
  }

  const maxDaysToChangeContractDueDay = Number(config.MAX_DAYS_TO_CHANGE_CONTRACT_DUE_DAY)

  const getTooltipContent = () => {
    if (!hasInstallmentsToChangeDueDay) {
      return 'Contratos sem parcelas com vencimento editável, não podem ter seu vencimento alterado.'
    }

    if (maxDaysToChangeContractDueDay > 0) {
      return `Contratos criados a mais de ${maxDaysToChangeContractDueDay} dias não podem ter seu vencimento alterado.`
    }

    if (maxDaysToChangeContractDueDay === 0) {
      return `Somente contratos criados hoje podem ter seu vencimento alterado.`
    }
  }

  const tooltipContent = getTooltipContent()

  return (
    <Box position="absolute" top={10} right={8}>
      <Box>
        <SystemButton
          aria-label="imprimir"
          aria-controls="menu-imprimir"
          aria-haspopup="true"
          onClick={handleClick}
          disabled={isProcessingAgglutination}
        >
          {isAgglutination ? <CloseIcon /> : <MoreVertIcon />}
        </SystemButton>
        <Menu id="menu-imprimir" anchorEl={anchorEl} keepMounted open={open} onClose={close}>
          <MenuItem onClick={getHandleClick(handlePrintingOpenInstallments)} component="a">
            Imprimir parcelas em aberto
          </MenuItem>
          {isAdmin && config.REACT_APP_AGGLUTINATION_ENABLED === 'true' ? (
            <MenuItem onClick={getHandleClick(openAgglutination)} component="a" target="blank">
              Aglutinar parcelas em aberto
            </MenuItem>
          ) : null}
          {canEditDueDay() ? (
            <MenuItem onClick={getHandleClick(openDueDayEdition)} component="a" target="blank">
              Editar vencimentos
            </MenuItem>
          ) : (
            <Tooltip title={tooltipContent}>
              <Box>
                <MenuItem
                  disabled
                  onClick={getHandleClick(openDueDayEdition)}
                  component="a"
                  target="blank"
                >
                  Editar vencimentos
                </MenuItem>
              </Box>
            </Tooltip>
          )}

          <MenuItem
            disabled={!canEditDiscounts}
            onClick={getHandleClick(openDiscountsEdition)}
            component="a"
            target="blank"
          >
            Editar descontos de mensalidades
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  )
}
export default ContractTableActions

import styled from 'styled-components'
import { colors, spacing, borderRadius } from '@gravity/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing[8]} ${spacing[10]};
  margin: ${spacing[4]} 0;
  background-color: ${colors['colors-background-neutral-2']};
  border-radius: ${borderRadius[2]};
  gap: ${spacing[6]};
`

export const ScoreTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ScoreBarsWrapper = styled.div`
  display: flex;
  gap: ${spacing[6]};
  > div {
    flex: 1;
  }
`

type ScoreBarProps = {
  color: string
}
export const ScoreBar = styled.div<ScoreBarProps>`
  height: 8px;
  border-radius: ${borderRadius[2]};
  background-color: ${props => props.color};
`

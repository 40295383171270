import { useQuery } from '@tanstack/react-query'
import { GetGuardiansResponse } from '../services/types/onboardingGuardian'
import { useApiClient } from '@/shared/hooks'
import { onboardingAPI } from '../services/onboardingApi'
import { GetOnboardingBatchRequest } from '../services/types/commonTypes'

export const useGuardian = (params: GetOnboardingBatchRequest) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useQuery<GetGuardiansResponse>(['getGuardians', params], () => api.getGuardians(params))
}

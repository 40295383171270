import { useState } from 'react'
import { LayoutTemplate } from '../../LayoutTemplate'
import { Button } from '@gravity/button'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useFetchOnboardingBatches } from '../../hooks/useFetchOnboardingBatches'
import { useHistory } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'
import { useUploadOnboardingFileMutation } from '../../hooks/useUploadOnboardingFileMutation'
import { FileUpload } from '../components/FileUpload'

const LoadingWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const UploadPage = () => {
  const { school } = useSelectedSchool()
  const schoolId = school?.id ?? ''
  const [file, setFile] = useState<File | null>(null)

  const history = useHistory()
  const { toast } = useToast()

  const {
    mutateAsync: executeUploadOnboardingFileMutation,
    isLoading: isLoadingUpload,
  } = useUploadOnboardingFileMutation()

  const { isLoading, isError } = useFetchOnboardingBatches(
    { schoolId: school?.id ?? '' },
    {
      onSuccess(data) {
        if (data.length === 0) return

        const onboardingBatch = data[0]

        history.push(`migrar-dados/${onboardingBatch.id}/validar`)
      },
      onError() {
        toast({
          title: 'Erro ao tentar recuperar lote em andamento',
          type: 'error',
        })
      },
      retry: false,
    }
  )

  const handleFileSubmit = async () => {
    try {
      if (!file || !schoolId) return

      const onboardingBatch = await executeUploadOnboardingFileMutation({
        file,
        schoolId,
      })

      return history.push(`migrar-dados/${onboardingBatch.id}/validar`)
    } catch (error) {
      console.error(error)
    }
  }

  // TODO: Replace this temporary solution
  if (isLoading || isError) {
    return (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    )
  }

  return (
    <LayoutTemplate
      title="Importar planilha de contratos"
      description="Ao importar a sua planilha, a Plataforma isaac vai identificar dados cadastrais de alunos e responsáveis, informações de plano de pagamento de produtos e serviços."
      footer={
        <>
          <Button disabled={!file} onClick={handleFileSubmit} loading={isLoadingUpload}>
            Começar
          </Button>
        </>
      }
    >
      <ContentWrapper>
        <FileUpload file={file} setFile={setFile} />
      </ContentWrapper>
    </LayoutTemplate>
  )
}

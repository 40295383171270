import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'

import { UseCanAgglutinateQueryResponse, useCanAgglutinateQueryProps } from '../../services/types'
import { useApiClient } from '@/shared/hooks/useApiClient'

import { DEFAULT_QUERY_STALE_TIME, MIN_RECEIVABLES_ALLOWED, NO_QUERY_CACHE } from '../constants'
import { checkCanAgglutinate } from '../../services/apiV1'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

/**
 * @description Hook to check if two - or more - receivables can be agglutinated
 */
export const useCanAgglutinate = (
  props: useCanAgglutinateQueryProps,
  options?: UseQueryOptions<UseCanAgglutinateQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()
  const { receivable_ids, school_id } = props

  const service = apiClient.privateApi

  const queryKey = ['guardian-details-installments-can-agglutinate', props.receivable_ids]

  const {
    data,
    isLoading: isCheckCanAgglutinateLoading,
    isError: isCheckCanAgglutinateError,
    isFetched: isCheckCanAgglutinateFetched,
    refetch: refetchCheckCanAgglutinate,
  } = useQuery<UseCanAgglutinateQueryResponse, XMLHttpRequest>(
    queryKey,
    () => {
      return checkCanAgglutinate(service, {
        receivable_ids,
        school_id,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(props.receivable_ids?.length >= MIN_RECEIVABLES_ALLOWED),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      cacheTime: NO_QUERY_CACHE,
      retry: false,
      ...options,
    }
  )

  return {
    data,
    isCheckCanAgglutinateLoading,
    isCheckCanAgglutinateError,
    isCheckCanAgglutinateFetched,
    refetchCheckCanAgglutinate,
  }
}

import { Dispatch, FC, SetStateAction, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { DialogContent, Notification } from '@olaisaac/design-system'
import { propEq } from 'ramda'
import ReceivableFinancialSummary from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/ReceivableFinancialSummary'
import { Receivable, ReceivableStatuses } from 'src/shared/interfaces'
import { DrawerState } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/constants'
import AgglutinationHistory from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/AgglutinationHistory'
import { InstallmentDrawerActions } from './InstallmentDrawerActions'
import { useApi } from '@/utils/hooks/useApi'

export type DefaultContentProps = {
  isBrokenPromise?: boolean
  receivables: Array<Receivable>
  removeOptionManualLiquidation?: boolean
  replaceReceivables: (receivables: Array<Receivable>) => void
  selectedInstallmentHasError: boolean
  selectedReceivableId: uuid
  setDrawerState: Dispatch<SetStateAction<DrawerState>>
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
  urn?: string
}

export const DefaultContent: FC<DefaultContentProps> = ({
  receivables,
  selectedReceivableId,
  setDrawerState,
  removeOptionManualLiquidation,
  selectedInstallmentHasError,
  setShowInvoicesNotGeneratedDialog,
  urn,
  isBrokenPromise,
  replaceReceivables,
}) => {
  const [isAgglutinated, setIsAgglutinated] = useState<boolean>(false)
  const [receivable, setReceivable] = useState<Receivable>(null)
  const [isPayableByCreditCard, setIsPayableByCreditCard] = useState<boolean>(false)

  const { api } = useApi()

  const getInvoice = async (invoiceId: uuid) => {
    const { available_payment_methods } = await api.invoices.get(invoiceId)
    setIsPayableByCreditCard(available_payment_methods?.includes('credit_card'))
  }
  useEffect(() => {
    const receivable = receivables?.find(propEq('id', selectedReceivableId))
    if (receivable) {
      setReceivable(receivable)
      if (receivable.invoice_id) {
        getInvoice(receivable.invoice_id)
      }
    }
  }, [selectedReceivableId])

  const isRenegotiated = Boolean(receivable?.original_receivables?.length)

  const ChooseDrawerActions: FC<ChooseDrawerActionsProps> = ({
    receivable,
    selectedReceivableId,
    setDrawerState,
  }) => {
    if ([ReceivableStatuses.OPEN, ReceivableStatuses.PAID].includes(receivable?.status)) {
      return (
        <InstallmentDrawerActions
          selectedReceivableId={selectedReceivableId}
          setDrawerState={setDrawerState}
          removeOptionManualLiquidation={removeOptionManualLiquidation}
          selectedInstallmentHasError={selectedInstallmentHasError}
          setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
          urn={urn}
          isPayableByCreditCard={isPayableByCreditCard}
          replaceReceivables={replaceReceivables}
        />
      )
    }
    return null
  }

  return (
    <>
      {isRenegotiated && (
        <Notification
          description="Essa parcela é resultado de uma renegociação."
          variation="information"
        />
      )}

      {isBrokenPromise && (
        <Notification
          description="Uma negociação aconteceu nesta parcela, mas ela não foi concluída e/ou foi cancelada."
          variation="warning"
        />
      )}

      <DialogContent>
        <AgglutinationHistory
          selectedReceivableId={selectedReceivableId}
          setIsAgglutinated={setIsAgglutinated}
          setReceivable={setReceivable}
        />
        <Box mt={3}>
          <ReceivableFinancialSummary
            receivable={receivable}
            isAgglutinated={isAgglutinated}
            key={receivable?.id}
          />
        </Box>
      </DialogContent>

      <ChooseDrawerActions
        receivable={receivable}
        selectedReceivableId={selectedReceivableId}
        setDrawerState={setDrawerState}
      />
    </>
  )
}

export type ChooseDrawerActionsProps = {
  receivable: Receivable
  selectedReceivableId: uuid
  setDrawerState: Dispatch<SetStateAction<DrawerState>>
}

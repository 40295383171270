import { Guardian } from '@/shared/interfaces'
import { useMutation } from '@tanstack/react-query'
import { useApi } from '@/utils/hooks/useApi'

export type changeContractOwnershipResponse = {
  new_contract_id: uuid
  new_guardian_id: uuid
}

export type changeContractOwnershipPayload = {
  new_guardian?: Omit<Guardian, 'address' | 'address_id' | 'created_at' | 'id'>
  reason: string
}

export type useChangeContractOwnershipCommands = {
  changeOwnership: (
    payload: changeContractOwnershipPayload
  ) => Promise<changeContractOwnershipResponse>
}

export type useChangeContractOwnershipQueries = {
  isChangeOwnershipError: boolean
  isChangeOwnershipLoading: boolean
  isChangeOwnershipSuccess: boolean
}

export const useChangeContractOwnership = (
  contractId: uuid
): useChangeContractOwnershipCommands & useChangeContractOwnershipQueries => {
  const { api } = useApi()

  const {
    mutateAsync: changeOwnership,
    isLoading: isChangeOwnershipLoading,
    isError: isChangeOwnershipError,
    isSuccess: isChangeOwnershipSuccess,
  } = useMutation(
    ({ new_guardian, reason }: changeContractOwnershipPayload) =>
      api.contracts.changeOwnership(contractId, { reason, new_guardian }),
    {
      onSuccess: response => response,
    }
  )

  return {
    changeOwnership,
    isChangeOwnershipError,
    isChangeOwnershipLoading,
    isChangeOwnershipSuccess,
  }
}

import { FC } from 'react'

import config from '@/config'
import HelperButton from 'src/escolas/components/HelperButton/HelperButton'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { useNavigation } from 'src/escolas/hooks'
import { useSchool } from 'src/escolas/hooks/useSchool'
import { formatCentsToReal, formatCPF } from 'src/shared/utils'
import { useSendCheckoutEvent } from '@/modules/guardians/Negotiation/hooks/useSendCheckoutEvents'

import type { HelpButtonProps } from './types'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

const HelpButton: FC<HelpButtonProps> = ({ eventLocationName, helpButtonEntry, onClick }) => {
  const { totalAmount, agreementSimulations } = useAgreement()
  const { school } = useSchool()
  const { guardian } = useNavigation()

  const sendCheckoutEvent = useSendCheckoutEvent()

  const schoolName = school.name

  const introduction = `Olá, sou do colégio/escola ${schoolName} e preciso de ajuda com o pagamento do(a) Responsável ${
    guardian?.name
  }, CPF ***.***.${formatCPF(guardian?.tax_id).slice(
    -6
  )}, que está tentando pagar as seguintes parcelas.`

  const list = agreementSimulations.map(value => {
    return {
      productName: value.receivables[0].product,
      studentName: value.receivables[0].student,
      receivable: value.receivables,
    }
  })
  let listInstallment: string[] = []
  let finalAmount = 0
  if (helpButtonEntry) {
    finalAmount = helpButtonEntry.amount

    listInstallment = [
      `\n\nAluno: ${helpButtonEntry.studentName}\nProduto: ${
        helpButtonEntry.productName
      }\n${`Parcela ${helpButtonEntry.orderReference}: ${formatCentsToReal(
        helpButtonEntry.amount
      )}`}`,
    ]
  } else {
    finalAmount = totalAmount

    listInstallment = list.map(value => {
      return `\n\nAluno: ${value.studentName}\nProduto: ${
        value.productName
      }${value.receivable.reduce(
        (acc, receivable) => `${acc}
  Parcela ${receivable.orderReference}: ${formatCentsToReal(receivable.amount)}`,
        ''
      )}`
    })
  }

  const message =
    introduction +
    listInstallment.reduce((acc, value) => acc + value, '') +
    `\n\nTotal: ${formatCentsToReal(finalAmount)}`

  function handleOnClick() {
    if (onClick) onClick()
    sendCheckoutEvent(EventDispatcherEntities.BUTTON_BASE_HELP, {
      name: 'Ajuda',
      location: eventLocationName,
    })
  }

  return (
    <HelperButton
      link={`http://wa.me/${config.CSS_TELEPHONE}?text=${message}`}
      onClick={handleOnClick}
    />
  )
}

export default HelpButton

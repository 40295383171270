import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@olaisaac/design-system'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { PageWrapper } from '@/shared/components/PageWrapper'
import { Container, Header, Title, Iframe } from './styles'
import { Loader } from '@/shared/components/Loader'
import { Error } from '@/shared/components/Error'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetFidcReport } from '../../hooks/queries/fidcReport'

export const FidcConfirmationReports = () => {
  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()
  const { data, status } = useGetFidcReport()
  const [iframeStatus, setIframeStatus] = useState<typeof status | undefined>('loading')

  const Child = {
    loading: <Loader />,
    error: <Error />,
    success: (
      <>
        {iframeStatus === 'loading' ? <Loader /> : null}
        <Iframe
          id="fidc-report"
          src={data?.url}
          style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
          onLoad={() => setIframeStatus('success')}
        />
      </>
    ),
  }

  return (
    <PageWrapper>
      <Container>
        <Header>
          <Button
            variation="ghost"
            startIcon={<ArrowBackIcon />}
            onClick={() => push(`/${schoolSlug}/relatorios`)}
          >
            Voltar
          </Button>
          <Title>Relatório de validação de contratos 2024</Title>
        </Header>
        {Child[status]}
      </Container>
    </PageWrapper>
  )
}

import { useQuery } from '@tanstack/react-query'
import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { useApi } from '@/utils/hooks/useApi'
import { removeDuplicatedErrors } from '../router/responsavel/[guardianId]/contratos/utils/removeDuplicatedErrors'
import { InvoiceStatus } from '../router/responsavel/[guardianId]/contratos/types'
import { formatInvoiceError } from '../router/responsavel/[guardianId]/contratos/utils/formatInvoicesError'
import { getInvoiceError } from '../router/responsavel/[guardianId]/contratos/utils/getInvoiceError'
import { useEffect, useState } from 'react'
import {
  MAX_ATTEMPTS,
  MAX_RETRIES,
  REFETCH_INTERVAL,
} from '../router/responsavel/[guardianId]/contratos/constants'

export const useGetInvoicesByIds = (ids: uuid[], school_id: uuid, shouldLimitAttempts = true) => {
  const { api } = useApi()
  const queryKey = ['invoices-by-ids', ids]
  const [attempts, setAttempts] = useState(0)
  const { data: invoices, isFetching, isFetched, isLoading, isRefetching, refetch } = useQuery<
    InvoiceStatus[]
  >(queryKey, () => api.invoices.getByIds({ ids, school_id }), {
    ...SOFTCACHE_OPTIONS_BASE,
    enabled: ids?.length > 0 && (!shouldLimitAttempts || attempts < MAX_ATTEMPTS),
    retry: MAX_RETRIES,
    refetchInterval: data => {
      const hasInvoiceWithError = data?.find(invoice => !invoice.referral)
      if (hasInvoiceWithError) {
        return REFETCH_INTERVAL
      }
      return false
    },
  })

  useEffect(() => {
    if (shouldLimitAttempts) {
      if (attempts > MAX_ATTEMPTS) {
        return setAttempts(0)
      }
      isFetched && setAttempts(attempts => attempts + 1)
    }
  }, [isFetching])

  const invoicesWithError = invoices?.reduce((acc, currentValue) => {
    if (!currentValue.referral) {
      const invoice_id = currentValue.id
      const invoiceError = getInvoiceError(currentValue.additional_informations)
      acc.push({ invoice_id, error_type: formatInvoiceError(invoiceError) })
    }
    return acc
  }, [])

  const errors = invoicesWithError?.length
    ? removeDuplicatedErrors(invoicesWithError)?.map(({ error_type }) => ({ error_type }))
    : null

  return {
    attempts,
    invoices,
    errors,
    invoicesWithError,
    isFetching,
    isRefetching,
    isFetched,
    isLoading,
    refetch,
  }
}

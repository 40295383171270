import { SCORE_PERSON_QUERY_KEY } from '@/modules/enrollment/constants/score'
import { scoreService } from '@/modules/enrollment/services/scoreService'
import {
  PersonScoreRequest,
  PersonScoreResponse,
} from '@/modules/enrollment/services/scoreService/types'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export const useFetchPersonScore = (
  props: PersonScoreRequest,
  options?: UseQueryOptions<PersonScoreResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()

  const service = scoreService(apiClient.privateApi)

  return useQuery<PersonScoreResponse, XMLHttpRequest>(
    [SCORE_PERSON_QUERY_KEY, props],
    () => {
      return service.fetchPersonScore(props)
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}

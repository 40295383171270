/**
 * @description Formats a number to a currency (BRL) string
 * @param num Number to be formatted
 * @returns Formatted currency string
 */
export const formatCurrency = (num: number) => {
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num / 100)
}

/**
 * @description Converts a currency string to cents type (integer)
 * @param numStr Currency string to be converted
 * @returns Cents integer
 */
export const parseCurrencyToCents = (numStr: string) => {
  return parseInt(
    numStr
      .replace(/\./g, '')
      .replace(',', '')
      .replace(/[^\d.]/g, '')
  )
}

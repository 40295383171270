import styled from 'styled-components'

export const Container = styled.ul`
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.gravity.spacing[4]};
`

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[4]};
`

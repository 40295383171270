import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApi } from '@/utils/hooks/useApi'
import { GetLockResponse } from '@/shared/services/credit/types'

export const getLockQueryKey = 'get-lock'

export const useGetLock = (schoolId: string, options?: UseQueryOptions<GetLockResponse>) => {
  const { api } = useApi()

  return useQuery<GetLockResponse>(
    [getLockQueryKey, schoolId],
    async () => await api.credit.getLock(schoolId),
    {
      retry: 1,
      ...options,
    }
  )
}

import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'throttle-debounce'

import { useGetTeachingMaterialLogistics } from '@monorepo/account-reports/hooks/useTeachingMaterialLogistics'
import { useQuery } from '@/shared/hooks/useQuery'
import { usePagination } from '@/shared/hooks/usePagination'

import { ColumnHeader } from './types'
import { useToast } from '@gravity/toast'
import { View } from './view'
import { CreateProductDeliveryRequest } from '@monorepo/account-reports/services/product_deliveries/types'

const DEFAULT_ITEMS_PER_PAGE = 10
const INITIAL_PAGE = 1
const MAX_DELIVERIES = 4

export const TeachingMaterialLogisticsPage = () => {
  const { query, setOnQueryParam } = useQuery()
  const [nameFilter, setNameFilter] = useState(query.get('name') ?? '')
  const [debouncedNameFilter, setDebouncedNameFilter] = useState(query.get('name') ?? '')
  const { pagination, updatePaginationValue } = usePagination(INITIAL_PAGE, DEFAULT_ITEMS_PER_PAGE)

  const {
    loading,
    productDeliveries,
    total,
    error,
    refetch,
    handleCreateDelivery,
  } = useGetTeachingMaterialLogistics({
    nameFilter: debouncedNameFilter,
    pagination,
  })

  const [showConfirmationDialog, setShowConfirmationDialog] = useState({
    visible: false,
    stage: 0,
    contract_id: '',
    max_deliveries: 0,
  })

  const debouncedUpdate = useMemo(
    () =>
      debounce(500, (value: string) => {
        setDebouncedNameFilter(value)
        setOnQueryParam(value, 'name', 'replace')
        refetch()
      }),
    [setDebouncedNameFilter]
  )
  const { toast } = useToast()

  useEffect(() => {
    debouncedUpdate(nameFilter)
  }, [nameFilter])

  function onChangeNameFilter(value: string) {
    setNameFilter(value)
  }

  const deliveryColumnHeaders = Array.from({
    length: MAX_DELIVERIES,
  })
    .map((_, i) => i + 1)
    .map<ColumnHeader>(i => ({
      name: `delivery_${i}`,
      label: `${i}ª entrega`,
      sortable: false,
      enableCheckbox: true,
    }))

  const columnHeaders: Array<ColumnHeader> = [
    { name: 'student_name', label: 'Aluno', sortable: false },
    { name: 'class_name', label: 'Série', sortable: false },
    { name: 'guardian_name', label: 'Responsável', sortable: false },
    ...deliveryColumnHeaders,
  ]

  function handleConfirmationDialogClick(input: CreateProductDeliveryRequest) {
    setShowConfirmationDialog({ visible: false, stage: 0, contract_id: '', max_deliveries: 0 })
    handleCreateDelivery(input)
      .then(() => {
        toast({
          type: 'success',
          title: 'Registro de entrega atualizado',
        })
        refetch()
      })
      .catch(() => {
        toast({
          type: 'error',
          title: 'Erro ao atualizar o registro de entrega',
        })
      })
  }

  return (
    <View
      onChangeNameFilter={onChangeNameFilter}
      total={total}
      updatePaginationValue={updatePaginationValue}
      pagination={pagination}
      productDeliveries={productDeliveries}
      columnHeaders={columnHeaders}
      deliveryColumnHeaders={deliveryColumnHeaders}
      setShowConfirmationDialog={setShowConfirmationDialog}
      loading={loading}
      error={error}
      handleConfirmationDialogClick={handleConfirmationDialogClick}
      nameFilter={nameFilter}
      showConfirmationDialog={showConfirmationDialog}
    />
  )
}

import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { propEq, reject, drop, times } from 'ramda'
import { Button, Typography } from '@olaisaac/design-system'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { Box, FormControl, TextField } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import { useSnackbar } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { Receivable, Discount } from '@/shared/interfaces'
import { validateEditReason } from '@/shared/utils'
import { useNavigation } from '@/escolas/hooks'
import DrawerForm from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/DrawerForm'
import { EditGuardianFormSection } from '@/escolas/router/responsavel/[guardianId]/contratos/constants'

import { ConfirmFlexiblePeriodEdit } from '@/escolas/components/contract/ConfirmFlexiblePeriodEdit/ConfirmFlexiblePeriodEdit'
import DiscountForm from '@/escolas/components/DiscountForm'

const UpdateZipCodeBtn = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3d4ed7;
  cursor: pointer;
`

export type AddDiscountFormType = Discount & {
  isFinished: boolean
  label_days_before_due_date: string
}

export type AddDiscountsFormType = {
  discounts: AddDiscountFormType[]
  reason?: string
}

export type AddDiscountContentProps = {
  onClose: () => void
  onFinish: () => void
  receivables: Array<Receivable>
  selectedReceivableId: uuid
  setSelectedReceivableId: Dispatch<SetStateAction<uuid>>
  successCallback: (receivables: Array<Receivable>) => void
}

const AddDiscountContent: FC<AddDiscountContentProps> = ({
  onClose,
  onFinish,
  receivables,
  selectedReceivableId,
  setSelectedReceivableId,
  successCallback,
}) => {
  const { api } = useApi()
  const form = useForm<AddDiscountsFormType>({
    mode: 'all',
  })
  const { setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const params = new URLSearchParams()
  const history = useHistory()
  const { control, trigger } = form
  const { fields: discountFields, append, remove } = useFieldArray({
    control,
    name: 'discounts',
  })
  const prevDiscountsLength = useRef(0)
  const { schoolId } = useNavigation()

  const [discountsToAppendQueue, setDiscountsToAppendQueue] = useState<Discount[]>([])
  const [newReceivableId, setNewReceivableId] = useState<uuid>()
  const [isFlexibleEditConfirmOpen, setIsFlexibleEditConfirmOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const receivable = receivables?.find(propEq('id', selectedReceivableId))

  const addDiscounts = async (form: AddDiscountsFormType) => {
    const { discounts: formDiscounts, reason } = form

    setIsLoading(true)

    return await api.receivables
      .addDiscounts(
        {
          discounts: formDiscounts.map(discount => ({
            amount: discount?.amount,
            days_before_due_date: discount?.days_before_due_date,
            description: discount?.description,
          })),
          reason: reason || '',
          receivable_id: selectedReceivableId,
        },
        schoolId
      )
      .then(updatedReceivables => {
        successCallback([
          ...reject(propEq('id', selectedReceivableId), receivables),
          ...updatedReceivables,
        ])
        const newReceivableId = updatedReceivables.find(({ id }) => id !== selectedReceivableId)?.id
        setNewReceivableId(newReceivableId)
        setSnackbarIsOpen(true, {
          variation: 'success',
          title: 'Desconto editado com sucesso',
        })
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          onClose()
          if (status === 422 && errors[0].message === 'Zip Code error') {
            setSnackbarIsOpen(true, {
              variation: 'error',
              title: 'Atualize o CEP do responsável',
              description:
                'Não foi possível alterar o desconto, pois identificamos que o CEP no cadastro está desatualizado',
              link: (
                <UpdateZipCodeBtn
                  onClick={() => {
                    params.append('abrirCadastro', 'true')
                    params.append(EditGuardianFormSection.ADDRESS_SECTION, 'true')
                    history.push({ search: params.toString() })
                  }}
                >
                  Atualizar CEP
                </UpdateZipCodeBtn>
              ),
            })
          } else {
            setSnackbarIsOpen(true, {
              variation: 'error',
              title: 'Não foi possível alterar os descontos',
              description:
                'Tente repetir a operação novamente, ou entre em contato com o nosso time de atendimento',
            })
          }
          throw errors[0].message
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSubmit = (form: AddDiscountsFormType) => {
    const isOverdue = dayjs((receivable as any).working_day_due_date).isBefore(dayjs(), 'day')

    if (!isOverdue) {
      addDiscounts(form)
      return
    }

    setIsFlexibleEditConfirmOpen(true)
  }

  const handleConfirm = () => {
    const values = form.getValues()
    setIsFlexibleEditConfirmOpen(false)
    addDiscounts(values)
  }

  const onSuccessSetReceivableId = () => {
    onFinish()
    // This unmounts the form and should be run only after the success message appears
    setSelectedReceivableId(newReceivableId)
  }

  const removeDiscount = (index: number) => remove(index)

  const appendDiscount = (discount: Discount & Partial<AddDiscountFormType>) => {
    append({
      amount: discount?.amount,
      days_before_due_date: discount?.days_before_due_date,
      description: discount?.description,
      isFinished: discount?.isFinished || false,
      label_days_before_due_date: discount?.label_days_before_due_date,
    })
  }

  const appendNewDiscount = () => {
    appendDiscount({
      amount: null,
      days_before_due_date: null,
      description: '',
    })
  }

  const discounts = receivable?.discounts

  useEffect(() => {
    if (!discounts?.length) {
      appendNewDiscount()
      return
    }

    setDiscountsToAppendQueue(discounts)
    prevDiscountsLength.current = discounts?.length
    times(index => trigger(`discounts.${index}.isFinished`), discountFields.length)
  }, [discounts])

  useEffect(() => {
    if (!discountsToAppendQueue.length) {
      times(index => trigger(`discounts.${index}.amount`), discountFields.length)
      return
    }
    appendDiscount({ ...discountsToAppendQueue[0], isFinished: true })
    setDiscountsToAppendQueue(drop(1, discountsToAppendQueue))
  }, [discountsToAppendQueue])

  return (
    <>
      <ConfirmFlexiblePeriodEdit
        isOpen={isFlexibleEditConfirmOpen}
        handleClose={() => setIsFlexibleEditConfirmOpen(false)}
        handleSubmit={() => {
          handleConfirm()
        }}
      />
      <DrawerForm
        form={form}
        disableFeedback
        prevDiscountsLength={prevDiscountsLength.current}
        isEditDiscount
        errorTitle="Volte e adicione novamente"
        errorMessage="Houve uma falha inesperada e não conseguimos adicionar o desconto."
        successMessage="O desconto foi adicionado com sucesso."
        onFinish={onSuccessSetReceivableId}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      >
        <Box mt={2} mb={2}>
          <Typography variation="subtitleDesktopLarge">
            São permitidos até 3 descontos por parcela:
          </Typography>
        </Box>
        {discountFields?.map((data, index) => (
          <DiscountForm
            key={data.id}
            data={data}
            index={index}
            form={form}
            idPrefix="tuition"
            totalAmount={receivable?.original_amount}
            removeDiscount={removeDiscount}
          />
        ))}
        <Button
          variation="ghost"
          startIcon={<AddIcon />}
          fullWidth
          onClick={appendNewDiscount}
          disabled={discountFields.length >= 3}
        >
          Novo desconto
        </Button>
        <Box mt={2} mb={2}>
          <Box mt={2} mb={2}>
            <Typography variation="subtitleDesktopLarge">
              Descreva o motivo da edição de desconto
            </Typography>
          </Box>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                required: true,
                maxLength: 100,
                minLength: 5,
                validate: validateEditReason,
              }}
              control={control}
              name="reason"
              defaultValue=""
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  label="Motivo"
                  id="reason"
                  variant="outlined"
                  value={value}
                  error={Boolean(error)}
                  helperText={
                    error ? 'Deve ter entre 5 e 100 caracteres (somente letras e números)' : ''
                  }
                />
              )}
            />
          </FormControl>
        </Box>
      </DrawerForm>
    </>
  )
}

export default AddDiscountContent

import styled from 'styled-components'
import theme from '@/shared/theme'

const { spacing, colors } = theme.primitiveTokens

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: ${spacing[8]};
  width: 480px;
  align-self: center;
  margin: 0 auto;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[10]};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
`

export const Title = styled.h1`
  text-align: center;
  color: ${colors.gray[80]};
  font-family: Roboto;
  font-size: ${spacing[8]};
  font-style: normal;
  font-weight: 700;
  line-height: ${spacing[9]};
  letter-spacing: -0.5px;
`

export const Subtitle = styled.span`
  text-align: center;
  color: ${colors.gray[80]};
  font-family: Roboto;
  font-size: ${spacing[4]};
  font-style: normal;
  font-weight: 400;
  line-height: ${spacing[6]};
  letter-spacing: 0.15px;
`

import styled from 'styled-components'

export const Container = styled.div`
  padding: ${props => `${props.theme.gravity.spacing[10]} ${props.theme.gravity.spacing[16]} 0`};

  .error {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

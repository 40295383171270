import { useMutation } from '@tanstack/react-query'
import { useApi } from '@/utils/hooks/useApi'
import { DuplicateAgreementRequest } from '@/modules/guardians/models/agreement'

export const useDuplicateAgreement = () => {
  const { api } = useApi()

  return useMutation((params: DuplicateAgreementRequest) =>
    api.agreement.duplicateAgreement(params)
  )
}

import { Tooltip } from '@material-ui/core'
import { SaveAlt } from '@material-ui/icons'
import { Button } from '@olaisaac/design-system'

import { PayoutReportPageFooter } from '@/modules/report/components/PayoutReportPageFooter'

import { useApi } from '@/utils/hooks/useApi'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { usePagination } from '@/shared/hooks/usePagination'
import { useSnackbar } from '@/shared/hooks/'

import { downloadFile } from '@/shared/utils/downloadFile'
import { formatDate } from '@/shared/utils/dateFormatters'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'
import { useIsaacPayOutsourcedPaymentProvider } from '@/shared/hooks/useIsaacPayOutsourcedPaymentProvider'

type IsaacPayPayoutReportFooterProps = {
  isaacPayPayoutReportEndDate: Date
  isaacPayPayoutReportId: string
  isaacPayPayoutReportStartDate: Date
  isLoading: boolean
  isMonthlyViewEnabled: boolean
  totalItems: number
}

/**
 * Specialized footer component for isaacPay report page
 *
 * @param props
 * @param props.isaacPayPayoutReportId Payout ID
 * @param props.isaacPayPayoutReportStartDate Payout start date
 * @param props.isaacPayPayoutReportEndDate Payout end date
 * @param props.totalItems Total items to be paginated
 * @param props.isLoading Indicates if the loading state should be displayed
 */
export const IsaacPayPayoutReportFooter = ({
  isaacPayPayoutReportId,
  isaacPayPayoutReportStartDate,
  isaacPayPayoutReportEndDate,
  isMonthlyViewEnabled,
  totalItems,
  isLoading,
}: IsaacPayPayoutReportFooterProps) => {
  const { api } = useApi()
  const { school } = useSelectedSchool()
  const { pagination, updatePaginationValue } = usePagination(1, 25)
  const { setMessage, setVariation, setIsOpen } = useSnackbar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)
  const shouldShowButton = !isOutsourcedProvider && !isMonthlyViewEnabled

  const handleDownloadReport = async (payoutId: string, filename: string) => {
    try {
      await downloadFile(filename, 'xlsx', () =>
        api.report.fetchPayoutReportFile({
          version: 2,
          id: payoutId,
          extension: 'xlsx',
        })
      )
    } catch {
      setMessage(
        'Erro ao baixar o relatório. Tente novamente mais tarde ou entre em contato com nosso suporte.'
      )
      setVariation('error')
      setIsOpen(true)
    } finally {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.ISAAC_PAY_REPORT,
          name: EventDispatcherEvents.ISAAC_PAY_PAYOUT_DOWNLOAD_CSV,
          action: 'file_download',
          customProperties: {
            $file_version: 2,
            $page_name: REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT,
          },
        })
    }
  }

  const startDate = formatDate(isaacPayPayoutReportStartDate, 'DD/MM/YYYY')
  const endDate = formatDate(isaacPayPayoutReportEndDate, 'DD/MM/YYYY')

  const filename = `analítico-${startDate}-${endDate}-${school?.slug}.xlsx`

  const isEmptyPayout = totalItems === 0

  return (
    <PayoutReportPageFooter
      page={pagination.page}
      itemsPerPage={pagination.itemsPerPage}
      totalItems={totalItems}
      onChangePage={value => {
        updatePaginationValue('page', value, true)
      }}
      onChangeItemsPerPage={value => {
        updatePaginationValue('itemsPerPage', value, true)
      }}
    >
      {shouldShowButton && (
        <Tooltip title="Baixar o arquivo .xlsx do período">
          <span>
            <Button
              disabled={isLoading || isEmptyPayout}
              startIcon={<SaveAlt />}
              onClick={() => handleDownloadReport(isaacPayPayoutReportId, filename)}
            >
              Baixar planilha
            </Button>
          </span>
        </Tooltip>
      )}
    </PayoutReportPageFooter>
  )
}

import { PaginationType } from '@/shared/hooks/usePagination/types'
import { Table } from '@gravity/table'

interface PaginationFooterProps {
  pagination: PaginationType
  total: number
  updatePaginationValue: (key: keyof PaginationType, value: number, replaceRoute?: boolean) => void
}

export const PaginationFooter = ({
  pagination,
  total,
  updatePaginationValue,
}: PaginationFooterProps) => {
  return (
    <Table.Pagination
      page={pagination.page}
      itemsPerPage={pagination.itemsPerPage}
      total={total}
      options={[
        { label: '10 linhas por página', value: '10' },
        { label: '25 linhas por página', value: '25' },
        { label: '50 linhas por página', value: '50' },
        { label: '100 linhas por página', value: '100' },
      ]}
      onChangePage={newPage => {
        updatePaginationValue('page', newPage, true)
        window.scrollTo(0, 0)
      }}
      onChangeItemsPerPage={newItensPerPage => {
        updatePaginationValue('itemsPerPage', newItensPerPage, true)
      }}
    />
  )
}

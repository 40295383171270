import { createContext, useState } from 'react'
import { ProcessedInstallment } from '@/shared/interfaces'

type InstallmentsContextType = {
  processedInstallments: ProcessedInstallment[]
  selectedProcessedInstallment: ProcessedInstallment
  setProcessedInstallments: (value: ProcessedInstallment[]) => void
  setSelectedProcessedInstallment: (value: ProcessedInstallment) => void
}

export const InstallmentsStore = createContext<InstallmentsContextType>(
  {} as InstallmentsContextType
)

export const InstallmentsProvider = ({ children }) => {
  const [processedInstallments, setProcessedInstallments] = useState<Array<ProcessedInstallment>>(
    []
  )
  const [
    selectedProcessedInstallment,
    setSelectedProcessedInstallment,
  ] = useState<ProcessedInstallment>()

  return (
    <InstallmentsStore.Provider
      value={{
        processedInstallments,
        selectedProcessedInstallment,
        setSelectedProcessedInstallment,
        setProcessedInstallments,
      }}
    >
      {children}
    </InstallmentsStore.Provider>
  )
}

import dayjs from 'dayjs'
import { PaginationType } from 'src/shared/hooks/usePagination/types'

export const paginateInMemory = <T>(array: T[], pagination: PaginationType): T[] =>
  array.slice(
    (pagination.page - 1) * pagination.itemsPerPage,
    pagination.page * pagination.itemsPerPage
  )

export const validateDate = (date: string): string => {
  return !!date && date !== '0001-01-01T00:00:00Z'
    ? dayjs(date).add(1, 'day').format('DD/MM/YYYY')
    : ''
}

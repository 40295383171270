import { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { useSnackbar } from 'src/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { ContractCreationSteps, Enrollment, API_NAMED_ERRORS } from 'src/shared/interfaces'
import { useNavigation } from 'src/escolas/hooks'
import { PageRouteProps } from 'src/escolas/router'
import { buildRoute } from '@/utils/router/utils'
import { FormProps } from 'src/escolas/components/contract/create/types'
import { DUPLICATED_CONTRACT_MESSAGE_MAX_SECONDS } from 'src/escolas/components/contract/create/constants'
import { getWrongFieldNames } from 'src/escolas/components/contract/create/utils'
import { ContractForm } from 'src/escolas/components/contract/create/ContractForm'
import envConfig from '@/config'
import useRouterReferenceYear from 'src/escolas/hooks/useRouterReferenceYear'
import { AddDiscountFormType } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/AddDiscountContent'
import { isApiNamedError } from 'src/shared/utils/isApiNamedError'
import { mixpanel, MixpanelEventsEnum } from 'src/shared/integrations'
import * as Sentry from '@sentry/react'
import { useSelectedSchool } from 'src/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { SchoolChargeOperation } from '@/shared/models/SchoolChargeOperation'
import { CampaignErrorDialog } from './components/CampaignErrorDialog'
import { useToast } from '@gravity/toast'

export const Page = () => {
  const { api } = useApi()
  const { getNavigationUrl, schoolId } = useNavigation()
  const [errorFieldNames, setErrorFieldNames] = useState(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [contractCreationProgress, setContractCreationProgress] = useState<string>('')
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()

  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState<boolean>(false)
  const [isInvoiceLimitError, setIsInvoiceLimitError] = useState(false)
  const [showCampaignErrorDialog, setShowCampaignErrorDialog] = useState({
    visible: false,
    studentName: '',
    productName: '',
  })
  const [referenceYear, setReferenceYear] = useState(useRouterReferenceYear() || '')
  const history = useHistory()
  const { school } = useSelectedSchool()
  const { toast } = useToast()
  const [hasGuardianDocumentError, setHasGuardianDocumentError] = useState(false)

  const getContractPath = (
    guardian_id: uuid | null,
    product_id: uuid | null,
    student_id: uuid | null
  ): url => {
    return getNavigationUrl({
      path: `/responsaveis/${guardian_id}/awaiting?reference_years=${referenceYear}&product_ids=${product_id}&student_ids=${student_id}&refetch=true`,
    })
  }
  const showContractDuplicationMessage = (directed_path: string) => {
    setSnackbarMessage('Este contrato não pode ser salvo porque já existe.')
    setSnackbarIsOpen(true, {
      title: 'Um contrato igual a este já existe',
      variation: 'error',
      link: <Link to={directed_path}>Ver contrato</Link>,
    })
  }

  const showGateOneValidationMessage = () => {
    setSnackbarMessage(
      'Não foi possível salvar o contrato, o aluno selecionado possui pendências financeiras.'
    )
    setSnackbarIsOpen(true, {
      variation: 'error',
    })
  }

  const submitContractCreationHandler = async (
    enrollmentData: Enrollment | Record<string, never>,
    formValues: FormProps,
    discountsValues: AddDiscountFormType[]
  ) => {
    let { start_month, duration_months, due_day, use_working_dates_tuition } = formValues

    setContractCreationProgress(ContractCreationSteps.CREATE_GUARDIAN)
    const { guardian } = formValues

    const guardianData = guardian.id
      ? await api.guardians.update(guardian.id, guardian, schoolId)
      : await api.guardians.create(guardian, schoolId)

    setContractCreationProgress(ContractCreationSteps.CREATE_STUDENT)
    const { student } = formValues
    const studentData = await api.students.createOrUpdate(
      {
        id: student.id,
        name: student.name,
        guardian_id: guardianData?.id,
        tax_id: student.no_tax_id ? undefined : student.tax_id,
        birth_date: student.birth_date?.toISOString() ?? undefined,
      },
      schoolId
    )
    // TODO: move this to a function helper
    const chargeOperation = isIsaacPaySchool(school)
      ? SchoolChargeOperation.isaacPay
      : SchoolChargeOperation.default

    setContractCreationProgress(ContractCreationSteps.CREATE_CONTRACT)
    if (!due_day) {
      due_day = start_month.date()
    }
    await api.contracts.create({
      discounts: discountsValues?.map(discount => ({
        amount: discount?.amount,
        days_before_due_date: discount?.days_before_due_date,
        description: discount?.description,
        type: discount?.type,
      })),
      due_day: +due_day,
      use_working_dates_tuition: use_working_dates_tuition === 'true',
      duration_months: +duration_months,
      guardian_id: guardianData?.id,
      previous_year_contract_id: '',
      product_id: formValues?.product?.id,
      reference_year: referenceYear,
      send_signable_document: formValues?.product?.envelope_template_referral_id
        ? !formValues.disable_send_signable_document
        : false,
      start_month: start_month.toISOString(),
      student_id: studentData?.id,
      ...enrollmentData,
      custom_monthly_amount: formValues.custom_monthly_amount,
      custom_enrollment_monthly_amount: formValues.custom_enrollment_monthly_amount,
      pre_contract_disclaimer_check: formValues.pre_contract_disclaimer_check,
      school_id: schoolId,
      school_charge_operation: chargeOperation,
    })

    setContractCreationProgress(ContractCreationSteps.FINISHED)

    mixpanel.trackEvent({ name: MixpanelEventsEnum.SUBMIT_CONTRACT })

    setSnackbarMessage('Contrato criado com sucesso.')
    setSnackbarIsOpen(true)
    const url = getContractPath(guardianData?.id, formValues?.product?.id, studentData?.id)
    history.push(url)
  }

  const handleSubmit = async (
    enrollmentData: Enrollment | Record<string, never>,
    formValues: FormProps,
    discountsValues: AddDiscountFormType[]
  ) => {
    setIsLoading(true)
    if (envConfig.CHECK_CONTRACT_DUPLICATION_ENABLED === 'true') {
      try {
        const data = await api.contracts.checkDuplication({
          duration_months: +formValues?.duration_months,
          guardian_name: formValues?.guardian?.name,
          product_id: formValues?.product?.id,
          start_month: formValues?.start_month.toISOString(),
          student_name: formValues?.student.name,
        })
        const { is_duplicated, guardian_id, seconds_elapsed_since_creation } = data
        if (is_duplicated) {
          const url = getContractPath(guardian_id, formValues?.product?.id, formValues?.student?.id)
          if (seconds_elapsed_since_creation > DUPLICATED_CONTRACT_MESSAGE_MAX_SECONDS) {
            showContractDuplicationMessage(url)
          }
          history.push(url)
          setIsLoading(false)
          return
        }
      } catch (e) {
        Sentry.captureException(e)
        setIsLoading(false)
        setIsFeedbackDialogOpen(true)
        return
      }
    }
    try {
      if (!errorFieldNames?.length) {
        await submitContractCreationHandler(enrollmentData, formValues, discountsValues)
      }
    } catch (errors: any) {
      Sentry.captureException(errors)
      if (isApiNamedError(errors, API_NAMED_ERRORS.DENIED_GATE_ONE_VALIDATION)) {
        showGateOneValidationMessage()
        return
      }
      if (isApiNamedError(errors, API_NAMED_ERRORS.CONTRACT_IS_DUPLICATED)) {
        const errorData = errors?.response?.data?.data
        const enrollmentPath = getContractPath(
          errorData.guardian_id,
          errorData.product_id,
          errorData.student_id
        )
        showContractDuplicationMessage(enrollmentPath)
        return
      }
      if (isApiNamedError(errors, API_NAMED_ERRORS.STUDENT_HAS_CAMPAIGN_ENROLLMENT)) {
        setShowCampaignErrorDialog({
          visible: true,
          studentName: formValues?.student.name,
          productName: formValues?.product.name,
        })
        return
      }
      if (isApiNamedError(errors, API_NAMED_ERRORS.IDENTITY_TAX_ID_VALIDATION_FAILED)) {
        toast({
          type: 'error',
          title: 'CPF inválido não permitiu salvar contrato',
          description:
            'Não pode ser utilizado CPF de menor de idade ou que seja inválido na Receita Federal.',
        })
        setHasGuardianDocumentError(true)
        return
      }
      setIsFeedbackDialogOpen(true)
      setIsInvoiceLimitError(false)
      if (!errors) {
        setErrorFieldNames(null)
        return
      }
      if (errors?.invoice_limit) {
        setIsInvoiceLimitError(true)
        return
      }
      const wrongFieldNames = getWrongFieldNames(errors)
      setErrorFieldNames(wrongFieldNames)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <ContractForm
          contractCreationProgress={contractCreationProgress}
          onSubmit={handleSubmit}
          errorFieldNames={errorFieldNames}
          isFeedbackDialogOpen={isFeedbackDialogOpen}
          isInvoiceLimitError={isInvoiceLimitError}
          isLoading={isLoading}
          setIsFeedbackDialogOpen={setIsFeedbackDialogOpen}
          setReferenceYear={setReferenceYear}
          referenceYear={referenceYear}
          hasGuardianDocumentError={hasGuardianDocumentError}
          clearGuardianDocumentError={() => setHasGuardianDocumentError(false)}
        />
        <CampaignErrorDialog
          visible={showCampaignErrorDialog.visible}
          studentName={showCampaignErrorDialog.studentName}
          productName={showCampaignErrorDialog.productName}
          closeDialog={() =>
            setShowCampaignErrorDialog({ visible: false, productName: '', studentName: '' })
          }
        />
      </Grid>
    </>
  )
}

const getPathname = ({ path }: PageRouteProps) => `${path}/novo`
const buildBreadcrumbs = ({ breadcrumbs }: PageRouteProps, path: string) => [
  ...breadcrumbs,
  { path, name: 'Adicionar contrato' },
]
const PageRoute = buildRoute(Page, getPathname, buildBreadcrumbs)
export default PageRoute

import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

import { useEnrollmentCampaignRedirect } from '@/shared/hooks/useEnrollmentCampaignRedirect'

import { useGetEnrollmentReportCampaignReport } from '../../hooks/queries/enrollmentReport'
import { Loader } from '@/shared/components/Loader'
import { PageStatusWrapper } from '@/shared/components/PageStatusWrapper'
import * as Styled from './styles'
import { Button } from '@gravity/button'
import { mdiChevronLeft } from '@mdi/js'
import Icon from '@mdi/react'

export const EnrollmentCampaignReport = () => {
  const { goBack } = useHistory()
  const reportQuery = useGetEnrollmentReportCampaignReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  useEnrollmentCampaignRedirect()

  return (
    <PageStatusWrapper status={reportQuery.status}>
      <Styled.Header>
        <Button variant="ghost" iconStart={<Icon path={mdiChevronLeft} />} onClick={goBack}>
          Voltar
        </Button>
        <Styled.HeaderTextWrapper>
          <Heading variant="heading-h3-medium">Relatório de campanha de rematrícula</Heading>
          <Text variant="body-1-regular">
            Acompanhe os detalhes de todas as campanhas por aluno. Este relatório é atualizado
            diariamente a partir das 08:00, no horário de Brasília.
          </Text>
        </Styled.HeaderTextWrapper>
      </Styled.Header>

      {iframeStatus === 'loading' ? <Loader /> : null}

      <Styled.Iframe
        src={reportQuery.data?.url}
        style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
        onLoad={() => setIframeStatus('success')}
      />
    </PageStatusWrapper>
  )
}

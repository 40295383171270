import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

import theme from '@/shared/theme'

const { spacing, colors } = theme.primitiveTokens

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  padding-bottom: ${spacing[10]};
  position: relative;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing[5]} 0 0;
`

export const Title = styled(Typography).attrs({ variation: 'headlineDesktopMedium' })`
  font-size: 32px;
  font-weight: 700;
  padding: 1rem 0;
  width: 100%;
  box-shadow: 0 1px 0 0 ${colors.gray[6]};
  z-index: 1;
`

import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'

import { schoolGroupsService } from '@/shared/services/schoolGroups'

import type { FetchSchoolGroupAndSchoolsResponse } from '@/shared/services/schoolGroups/types'

export const useUserSchoolGroup = (
  schoolId: string,
  options?: UseQueryOptions<FetchSchoolGroupAndSchoolsResponse>
) => {
  const { apiClient } = useApiClient()

  const service = schoolGroupsService(apiClient.getClients().bffApi)

  return useQuery<FetchSchoolGroupAndSchoolsResponse>(
    ['school-group', 'schools'],
    () => service.fetchSchoolGroupAndSchools(schoolId),
    options
  )
}

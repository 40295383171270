import { FC, useState } from 'react'
import { useApi } from '@/utils/hooks/useApi'
import { Button, ButtonProps } from '@olaisaac/design-system'
import { InvoiceProviders } from 'src/shared/interfaces'

export type PrintReceiptButtonProps = {
  buttonVariation?: ButtonProps['variation']
  label?: string
  onSuccess?: () => void
  provider?: InvoiceProviders | string
  receivableId: uuid
  withoutMarginAndPadding?: boolean
}

const PrintReceiptButton: FC<PrintReceiptButtonProps> = ({
  buttonVariation = 'primary',
  label = 'Gerar comprovante de pagamento',
  receivableId,
  onSuccess,
  withoutMarginAndPadding,
}) => {
  const { api } = useApi()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleClick = async () => {
    try {
      setIsLoading(true)
      const response = await api.receivables.printReceipt(receivableId)
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      const newWindow = window.open(fileURL, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } catch {
    } finally {
      setIsLoading(false)
      if (onSuccess) onSuccess()
    }
  }

  return (
    <>
      {withoutMarginAndPadding ? (
        <Button
          variation={buttonVariation}
          fullWidth
          onClick={handleClick}
          loading={isLoading}
          style={{
            height: 'auto',
            padding: isLoading ? '14px' : '3px',
            justifyContent: isLoading ? 'flex-start' : 'center',
            width: 'auto',
          }}
        >
          {label}
        </Button>
      ) : (
        <Button variation={buttonVariation} fullWidth onClick={handleClick} loading={isLoading}>
          {label}
        </Button>
      )}
    </>
  )
}

export default PrintReceiptButton

import { ReactNode } from 'react'
import { Error } from '../Error'
import { Loader } from '../Loader'

import * as Styled from './styles'

export type PageStatusWrapperProps = {
  children: ReactNode
  status: 'error' | 'loading' | 'success'
}

export const PageStatusWrapper = ({ status, children }: PageStatusWrapperProps) => {
  if (status === 'error') return <Error />
  if (status === 'loading') return <Loader />

  return <Styled.Container>{children}</Styled.Container>
}

import { IrregularStudentStatus, irregularStatusList } from '../../types'
import { IrregularityBigNumbersCard } from '../IrregularityBigNumbersCard'
import { IrregularityBigNumbersProps } from './types'
import * as Styled from './styles'

export const IrregularityBigNumbers = ({
  data,
  isError,
  isLoading,
}: IrregularityBigNumbersProps) => {
  if (isLoading) {
    return null
  }

  if (isError) {
    return null
  }

  return (
    <Styled.Container>
      {irregularStatusList.map((status: IrregularStudentStatus) => {
        const value = data?.[status]

        if (!value) {
          return null
        }
        return (
          <IrregularityBigNumbersCard
            key={status}
            status={status}
            student_count={value.count}
            total_amount={value.amount}
          />
        )
      })}
    </Styled.Container>
  )
}

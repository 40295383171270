import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  padding: ${props => props.theme.gravity.spacing[4]} 0 0 ${props => props.theme.gravity.spacing[4]}; // TODO: Remover após aplicação do Grid
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[14]};
  flex-grow: 1;
  overflow-y: auto;

  max-width: 70%; // TODO: Remover após aplicação do Grid

  .action-block {
    display: flex;
    gap: ${props => props.theme.gravity.spacing[2]};
  }
`

import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import * as Styled from './styles'
import { ReactNode, forwardRef, HTMLAttributes } from 'react'

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode
  description: string
  title: string
}

export const InteractiveCard = forwardRef<HTMLDivElement, Props>(
  ({ title, description, children, ...props }, ref) => {
    return (
      <Styled.Card ref={ref} {...props}>
        <Styled.Texts>
          <Heading variant="heading-h4-medium">{title}</Heading>
          <Text>{description}</Text>
        </Styled.Texts>
        {children}
      </Styled.Card>
    )
  }
)

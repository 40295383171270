import { GROUP_NAME_ORDER } from '@monorepo/access-management/constants/GROUP_NAME_ORDER'

import type { User } from '@monorepo/access-management/models/User'
import type { RemoveAccessRoles } from '@monorepo/access-management/types/RemoveAccessRoles'
import type { UserGroupNames } from '@/shared/integrations/sorting-hat/types'
import type { Roles } from '@/shared/contexts/Auth'

interface Props {
  loggedUserGroupName?: UserGroupNames
  loggedUserId: string
  userRoles: Set<Roles>
  userTobeRemoved: User
}

export const canRemoveUserAccess = ({
  loggedUserId,
  userRoles,
  loggedUserGroupName,
  userTobeRemoved,
}: Props) => {
  if (!loggedUserGroupName) return false
  if (userTobeRemoved.id === loggedUserId) return false

  const loggedUserAccessLevel = GROUP_NAME_ORDER[loggedUserGroupName]
  const userToBeEditedLevel = GROUP_NAME_ORDER[userTobeRemoved.group_name]

  const REQUIRED_ROLE_FOR_GROUP_REMOVE: Record<UserGroupNames, RemoveAccessRoles | undefined> = {
    gestor: undefined,
    mantenedor: 'remover_acesso_mantenedor',
    secretaria: 'remover_acesso_secretaria',
    'operador-basico': 'remover_acesso_operador_basico',
  } as const

  const neededRoleToEditUser = REQUIRED_ROLE_FOR_GROUP_REMOVE[userTobeRemoved.group_name]

  if (
    loggedUserAccessLevel > userToBeEditedLevel &&
    neededRoleToEditUser &&
    userRoles.has(neededRoleToEditUser)
  )
    return true

  return false
}

import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useJWT } from '@/shared/hooks/useJWT'
import { APIResponse, TicketsSummaryList } from '@/shared/interfaces'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { accountReportsService } from '@monorepo/account-reports/services/account-reports'

export const useGetListTicketSummary = (
  options?: UseQueryOptions<APIResponse<TicketsSummaryList>>
) => {
  const { apiClient } = useApiClient()
  const service = accountReportsService(apiClient.getClients().privateApi)
  const { school } = useSelectedSchool()
  const { hasPayoutReportAccess } = useJWT()

  return useQuery<APIResponse<TicketsSummaryList>>(
    ['tickets-summary', 'list', school?.id],
    () => service.fetchTicketsSummaryList(school?.id ?? ''),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: !!school && hasPayoutReportAccess,
      retry: 2,
      ...options,
    }
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/utils/hooks/useApi'

import type {
  FetchPayoutDetailsRequestDTO,
  FetchPayoutDetailsResponseDTO,
} from '@/escolas/services/report/types'

export const useTuitionPayoutReport = (
  { payoutId, filter, pagination }: FetchPayoutDetailsRequestDTO<'tuition'>,
  options?: UseQueryOptions<FetchPayoutDetailsResponseDTO<'tuition'>>
) => {
  const { api } = useApi()

  return useQuery<FetchPayoutDetailsResponseDTO<'tuition'>>(
    ['reports', 'tuition', payoutId, JSON.stringify({ ...filter, ...pagination })],
    async () => {
      return await api.report.fetchPayoutDetails('tuition', {
        payoutId,
        filter,
        pagination,
      })
    },
    options
  )
}

import { Skeleton } from '@gravity/skeleton'

export const ActionsLoading = () => {
  return (
    <>
      <div data-testid="actions-loading">
        <Skeleton variant="neutral" width={40} height={40} />
      </div>
      <div data-testid="actions-loading">
        <Skeleton variant="neutral" width={40} height={40} />
      </div>
    </>
  )
}

import { Table } from '@gravity/table'

interface LoadingTableProps {
  columnsLength: number
  rowsLength: number
}
export const LoadingTable = ({ rowsLength, columnsLength }: LoadingTableProps) => {
  return (
    <Table.Root>
      {Array.from({ length: rowsLength }).map((_, rowIndex) => (
        <Table.Row key={rowIndex}>
          {Array.from({ length: columnsLength }).map((_, columnIndex) => (
            <Table.LoadingCell key={columnIndex} />
          ))}
        </Table.Row>
      ))}
    </Table.Root>
  )
}

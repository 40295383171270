import { FC } from 'react'
import ShareOutlined from '@material-ui/icons/ShareOutlined'
import { Typography, Button } from '@olaisaac/design-system'

import config from '@/config'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { MixpanelEventsEnum, mixpanel } from 'src/shared/integrations'
import { getReceivableStatus } from '@/modules/guardians/Negotiation/helpers/getReceivableStatus'

import useShareMessage from './hooks/useShareMessage'
import { ReceivableStatuses } from '@/shared/interfaces'
import { usePaymentCreditCard } from '@/escolas/hooks'

const SharePaymentOptionsButton: FC = () => {
  const { totalAmount, agreementSimulations } = useAgreement()
  const { paymentFee } = usePaymentCreditCard()

  const message = useShareMessage(totalAmount, agreementSimulations, paymentFee)

  const url = `http://wa.me/${config.CSS_TELEPHONE}?text=${encodeURIComponent(message)}`

  function sendEvent() {
    const eventParams = {
      receivables: agreementSimulations
        .map(simulation =>
          simulation.receivables.map(receivable => ({
            id: receivable.id,
            contract_id: receivable.contract_id,
            order_reference: receivable.orderReference,
            is_enrollment: receivable.isEnrollment,
            is_overdue: getReceivableStatus(receivable) === ReceivableStatuses.OVERDUE,
          }))
        )
        .flat(),
    }

    mixpanel.trackEvent({
      name: MixpanelEventsEnum.SHARE_AGREEMENT_DETAILS,
      properties: eventParams,
    })
  }

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={url}
      style={{ textDecoration: 'none' }}
      onClick={sendEvent}
    >
      <Button variation="ghost" style={{ maxWidth: 'initial' }}>
        <ShareOutlined />
        <Typography variation="buttonLarge" color="accent" style={{ textTransform: 'initial' }}>
          Compartilhar com o responsável
        </Typography>
      </Button>
    </a>
  )
}

export default SharePaymentOptionsButton

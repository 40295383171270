import { useEffect, useState } from 'react'
import { useApi } from '@/utils/hooks/useApi'
import { useNavigation } from 'src/escolas/hooks'

import { Contract, PaymentMethod, Receivable, ReceivableStatuses } from 'src/shared/interfaces'

export const formatPaidReceivableValues = (
  r: Receivable
): Receivable & { conditionDiscounts: cents; totalAmount: cents } => {
  const totalPerpetualDiscount = r.discounts.reduce(
    (acc, { amount, type }) => (type === 'PERPETUAL' ? acc + amount : acc),
    0
  )

  const interestAndFines = r.current_interest + r.current_fine

  const totalAmount = r.original_amount - totalPerpetualDiscount

  const amountPaidWithoutFinesAndInterest = r.total_amount_paid - interestAndFines

  const conditionDiscounts =
    totalAmount < amountPaidWithoutFinesAndInterest
      ? 0
      : totalAmount - amountPaidWithoutFinesAndInterest
  return {
    ...r,
    conditionDiscounts,
    totalAmount,
  }
}

const useContractPaidAmountsReport = (contractId: uuid = null) => {
  const { api } = useApi()
  const { school } = useNavigation()

  const [paidReceivables, setPaidReceivables] = useState<any[]>([])
  const [contract, setContract] = useState<Contract>(null)
  const [schoolData, setSchoolData] = useState<any>(null)

  const [isLoading, setIsLoading] = useState(true)

  const [totalOriginalAmount, setTotalOriginalAmount] = useState<cents>(0)
  const [totalInterest, setTotalInterest] = useState<cents>(0)
  const [totalFine, setTotalFine] = useState<cents>(0)
  const [totalDiscount, setTotalDiscount] = useState<cents>(0)

  const [totalPaid, setTotalPaid] = useState<cents>(0)

  useEffect(() => {
    if (!contractId) return

    api.contracts
      .get(contractId, {
        include_guardian: true,
        include_installments: true,
        include_product: true,
        include_receivables: true,
        include_student: true,
      })
      .then((c: Contract) => {
        setContract({ ...c })
      })
  }, [contractId])

  useEffect(() => {
    setSchoolData({ ...school })
  }, [school])

  useEffect(() => {
    const [amount, interest, fine, discount, amountPaid] = paidReceivables.reduce(
      (
        acc,
        { totalAmount, current_interest, current_fine, conditionDiscounts, total_amount_paid }
      ) => {
        return [
          acc[0] + totalAmount,
          acc[1] + current_interest,
          acc[2] + current_fine,
          acc[3] + conditionDiscounts,
          acc[4] + total_amount_paid,
        ]
      },
      [0, 0, 0, 0, 0]
    )
    setTotalOriginalAmount(amount)
    setTotalInterest(interest)
    setTotalFine(fine)
    setTotalDiscount(discount)
    setTotalPaid(amountPaid)
  }, [paidReceivables])

  useEffect(() => {
    if (!contract) return
    setPaidReceivables(
      contract.installments
        .map(installment =>
          installment.receivables
            .filter(receivable => {
              return (
                receivable.status === ReceivableStatuses.PAID &&
                receivable.payment_method !== PaymentMethod.SCHOOL_PORTFOLIO_REPURCHASE &&
                receivable.original_amount > 0
              )
            })
            .map(formatPaidReceivableValues)
        )
        .flat()
    )
  }, [contract])

  useEffect(() => {
    setIsLoading(!(contract && paidReceivables))
  }, [contract, paidReceivables])

  useEffect(() => {
    if (totalPaid <= 0) return

    document.title = `Demonstrativo-${contract.product.name}-${contract.reference_year}-${contract.student.name}`.replace(
      /\s/g,
      '-'
    )
    window.print()
  }, [totalPaid])

  return {
    contract,
    isLoading,
    paidReceivables,
    schoolData,
    totalDiscount,
    totalFine,
    totalInterest,
    totalOriginalAmount,
    totalPaid,
  }
}

export default useContractPaidAmountsReport

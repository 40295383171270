import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/utils/hooks/useApi'

import type { PayoutSummary } from '@/modules/report/models/PayoutSummary'

export const useTuitionPayoutReports = (
  schoolId: string,
  options?: UseQueryOptions<PayoutSummary[]>
) => {
  const { api } = useApi()

  return useQuery<PayoutSummary[]>(
    ['reports', schoolId, 'tuition'],
    async () => {
      return await api.report.fetchPayoutsSummary(schoolId)
    },
    options
  )
}

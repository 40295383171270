import * as Sentry from '@sentry/react'

import { enrollmentService } from '@/modules/enrollment/services/enrollmentService'
import { UploadSignatureDocumentRequest } from '@/modules/enrollment/services/enrollmentService/types'
import { useApiClient } from '@/shared/hooks'
import { useMutation } from '@tanstack/react-query'

type UseUploadSignatureDocumentMutationParams = UploadSignatureDocumentRequest & {
  schoolId: uuid
}
export const useUploadSignatureMutation = () => {
  const { apiClient } = useApiClient()
  const service = enrollmentService(apiClient.privateApi)

  return useMutation({
    mutationFn: async ({ schoolId, ...params }: UseUploadSignatureDocumentMutationParams) => {
      return service.uploadSignatureDocument(schoolId, params)
    },
    onError: error => Sentry.captureException(error),
  })
}

import { APIResponse, Receivable } from '@/shared/interfaces'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApi } from '@/utils/hooks/useApi'
import { useQuery as useQueryParams } from '@/shared/hooks/useQuery'
import { normalizeText } from '@/shared/utils'

export type PaginationRequest = Partial<{
  page: number
  per_page: number
}>

type ExternalPaymentQueryProps = {
  endDate: string
  options?: UseQueryOptions<APIResponse<Receivable[]>>
  pagination?: PaginationRequest
  schoolId: string
  startDate: string
}

export const useIsaacPayExternalPayoutReports = ({
  schoolId,
  endDate,
  pagination,
  startDate,
  options,
}: ExternalPaymentQueryProps) => {
  const { api } = useApi()
  const { query } = useQueryParams()

  const nameFilter = query.get('name') ?? ''
  const filter = {
    student_name: normalizeText(nameFilter),
  }

  return useQuery<APIResponse<Receivable[]>>(
    ['external-payments', schoolId, startDate, endDate, filter, pagination, 'isaac-pay'],
    () => {
      return api.receivables.fetchExternalPaymentsReportList({
        schoolId,
        start_date: startDate,
        end_date: endDate,
        filter,
        pagination,
      })
    },
    options
  )
}

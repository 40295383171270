import { useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import {
  Drawer,
  ActionDrawerHeader,
  ButtonDocker,
  Button,
  Search,
  Typography,
} from '@olaisaac/design-system'
import { debounce } from 'throttle-debounce'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'

import { useApi } from '@/utils/hooks/useApi'
import { useContractsFilter, useNavigation } from 'src/escolas/hooks'

import { CollapsibleFilterGroup } from '../../../../CollapsibleFilterGroup'

import { filterFixedFilterOptionsByText, trackFilterEvent } from './utils'

import { FIXED_STUDENTS_FILTER_OPTIONS } from './constants'

import {
  StudentsContractsFilterObjectKeys,
  StudentsContractsFilterType,
} from 'src/escolas/hooks/useContractsFilter/types'
import { OptionType } from '../../../../CollapsibleFilterGroup/types'
import { ContractsFilterDrawerProps } from './types'

/**
 * Drawer component for contracts student view filters
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback called on request drawer close
 */
export const StudentContractsFilterDrawer = ({ isOpen, onClose }: ContractsFilterDrawerProps) => {
  const { api } = useApi()
  const { schoolId } = useNavigation()
  const { studentsFilter, studentsFilterCount, updateFilter, clearFilter } = useContractsFilter()

  const form = useForm<StudentsContractsFilterType>()

  const { handleSubmit, setValue, reset, watch } = form

  const [searchOptionsField, setSearchOptionsField] = useState('')
  const [isLoadingFilters, setIsLoadingFilters] = useState(true)
  const [isFilterApplied, setIsFilterApplied] = useState(true)
  const [fixedFilterOptions, setFixedFilterOptions] = useState<Record<string, OptionType[]>>({})
  const [productsFilterData, setProductsFilterData] = useState({
    options: [],
    total: 0,
    page: 1,
  })

  useEffect(() => {
    Object.keys(studentsFilter).forEach((key: any) => {
      setValue(key, studentsFilter[key])
    })
  }, [isOpen])

  useEffect(() => {
    const subscription = watch(() => setIsFilterApplied(false))

    return () => subscription.unsubscribe()
  }, [watch])

  const fetchProductFilterOptions = async (page: number, searchText: string) => {
    const { data, pagination } = await api.products.getList({
      page: page,
      per_page: 4,
      school_id: schoolId,
      ...(searchText && { name: searchText }),
    })

    const productFilterOptions = data.map(item => {
      return {
        label: item.name,
        value: item.id,
      }
    })

    return {
      options: productFilterOptions,
      totalOptions: pagination.total,
    }
  }

  useEffect(() => {
    const filterOptionsByText = async () => {
      setIsLoadingFilters(true)

      const filteredFixedOptions = filterFixedFilterOptionsByText(searchOptionsField)
      const { options, totalOptions } = await fetchProductFilterOptions(1, searchOptionsField)

      setFixedFilterOptions(filteredFixedOptions)
      setProductsFilterData({
        options,
        total: totalOptions,
        page: 1,
      })
      setIsLoadingFilters(false)
    }

    filterOptionsByText()
  }, [searchOptionsField])

  const handleFetchMoreProducts = async (page: number, searchText: string) => {
    const { options, totalOptions } = await fetchProductFilterOptions(page, searchText)

    setProductsFilterData(prevState => ({
      options: [...prevState.options, ...options],
      total: totalOptions,
      page,
    }))
    setIsLoadingFilters(false)
  }

  const handleApplyFilters: SubmitHandler<StudentsContractsFilterType> = data => {
    setIsFilterApplied(true)
    updateFilter('students', data)
    onClose()

    trackFilterEvent(data)
  }

  const handleClearFilter = () => {
    reset({
      contractStatus: [],
      debtStatus: [],
      productId: [],
    })
    clearFilter('students')
    setIsFilterApplied(true)
  }

  const fixedFilterLabels = {
    debtStatus: 'Situação',
    contractStatus: 'Status do contrato',
  }

  const hasAvailableOptions = useMemo(() => {
    const productsFilterOptionsQuantity = productsFilterData.options.length

    const fixedFilterOptionsQuantity = Object.keys(fixedFilterOptions).reduce((acc, key) => {
      return acc + fixedFilterOptions[key].length
    }, 0)

    return productsFilterOptionsQuantity > 0 || fixedFilterOptionsQuantity > 0
  }, [productsFilterData, fixedFilterOptions])

  return (
    <FormProvider {...form}>
      <Drawer open={isOpen}>
        <ActionDrawerHeader title="Filtrar" onClose={onClose} />

        <Search
          placeholder="Buscar filtros"
          onChange={debounce(500, (value: string) => setSearchOptionsField(value))}
          onClear={() => setSearchOptionsField('')}
        />

        {isLoadingFilters && (
          <Box
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
            padding="1.5rem 1.5rem"
          >
            <Typography variation="bodySmall" color="secondary" withoutMargin>
              Carregando filtros...
            </Typography>
          </Box>
        )}

        {!isLoadingFilters && !hasAvailableOptions && (
          <Box
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
            padding="1.5rem 1.5rem"
          >
            <Typography variation="bodySmall" color="secondary" withoutMargin>
              Nenhum resultado encontrado
            </Typography>
          </Box>
        )}

        {!isLoadingFilters && hasAvailableOptions && (
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            padding="1.5rem 1.5rem"
            overflow="auto"
          >
            {productsFilterData.options.length > 0 && (
              <Box mb="3rem">
                <CollapsibleFilterGroup
                  label="Produtos"
                  filterName="productId"
                  options={productsFilterData.options}
                  totalOptions={productsFilterData.total}
                  searchText={searchOptionsField}
                  externalPagination={productsFilterData.page}
                  externalPaginationControl={handleFetchMoreProducts}
                  onClearFilter={filterName =>
                    setValue(filterName as StudentsContractsFilterObjectKeys, [])
                  }
                />
              </Box>
            )}

            {FIXED_STUDENTS_FILTER_OPTIONS.map(({ filterName }) => {
              if (fixedFilterOptions[filterName].length === 0) return null

              return (
                <Box mb="3rem" key={filterName}>
                  <CollapsibleFilterGroup
                    label={fixedFilterLabels[filterName]}
                    filterName={filterName}
                    options={fixedFilterOptions[filterName]}
                    totalOptions={fixedFilterOptions[filterName].length}
                    searchText={searchOptionsField}
                    onClearFilter={filterName =>
                      setValue(filterName as StudentsContractsFilterObjectKeys, [])
                    }
                  />
                </Box>
              )
            })}
          </Box>
        )}

        <ButtonDocker>
          <Button fullWidth disabled={isFilterApplied} onClick={handleSubmit(handleApplyFilters)}>
            Filtrar
          </Button>

          <Button
            fullWidth
            variation="ghost"
            disabled={studentsFilterCount === 0}
            onClick={handleClearFilter}
          >
            Limpar tudo
          </Button>
        </ButtonDocker>
      </Drawer>
    </FormProvider>
  )
}

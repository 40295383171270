import styled from 'styled-components'
import { TextField } from '@gravity/text-field'
import { Text as GravityText } from '@gravity/text'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.theme.gravity.spacing[8]};
`

export const NameFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${props => props.theme.gravity.spacing[6]};
`

export const NameFilter = styled(TextField)`
  gap: ${props => props.theme.gravity.spacing[3]};
  max-width: 100%;
  width: 350px;

  > span {
    color: ${props => props.theme.gravity.colors['colors-background-neutral-3']};
  }
`

export const Text = styled(GravityText)`
  margin-top: ${props => props.theme.gravity.spacing[2]};
  line-height: ${props => props.theme.gravity.lineHeight.normal};
`

import React, { InputHTMLAttributes } from 'react'
import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'

import { Container, Input } from './styles'

export const InputSearch = React.forwardRef<HTMLDivElement, InputHTMLAttributes<HTMLInputElement>>(
  (props, forwardedRef) => (
    <Container ref={forwardedRef}>
      <Icon path={mdiMagnify} size={1} className="icon-search" />
      <Input type="text" placeholder="Busque aluno ou responsável por nome" {...props} />
    </Container>
  )
)

import { useForm } from 'react-hook-form'
import * as Sentry from '@sentry/react'

import { zodResolver } from '@hookform/resolvers/zod'

import { fileSchema } from '../schemas/fileSchema'

type UseFileUploadProps = {
  onSubmit: (data: { file: File }) => void
}

type FileFormData = {
  file: File | null
}

export const useFileUpload = ({ onSubmit }: UseFileUploadProps) => {
  const {
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm<FileFormData>({
    resolver: zodResolver(fileSchema),
  })

  const file = watch('file')

  const validateAndSetFile = async (file: File) => {
    try {
      await fileSchema.parseAsync({ file })
      setValue('file', file, {
        shouldValidate: true,
      })
      return true
    } catch (error) {
      return false
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const isValid = await validateAndSetFile(file)

    if (isValid) {
      onSubmit({ file })
    } else {
      event.target.value = ''
    }
  }

  const handleFileSelect = async (file: File) => {
    await validateAndSetFile(file)
  }

  const handleDownload = async (file: File): Promise<void> => {
    try {
      const blob = new Blob([file], { type: file.type })
      const downloadUrl = window.URL.createObjectURL(blob)

      const response = await fetch(downloadUrl)
      const blobData = await response.blob()

      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blobData)
      downloadLink.download = file.name
      downloadLink.click()

      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleRemove = () => {
    setValue('file', null)
  }

  const handleFormSubmit = (data: FileFormData) => {
    if (data.file) {
      onSubmit({ file: data.file })
    }
  }

  return {
    file,
    errors,
    handleFileChange,
    handleFileSelect,
    handleDownload,
    handleRemove,
    handleSubmit: handleSubmit(handleFormSubmit),
  }
}

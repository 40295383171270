import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'

import {
  UseInstallmentQueryResponse,
  UseNegotiationInstallmentQueryProps,
} from '../../services/types'
import { guardianService } from '../../services'
import {
  DEFAULT_QUERY_RETRY_COUNT,
  DEFAULT_QUERY_STALE_TIME,
} from '../../GuardianDetails/constants'

export const useGuardianInstallmentDrawerQuery = (
  props: UseNegotiationInstallmentQueryProps,
  options?: UseQueryOptions<UseInstallmentQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()
  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardian-installment-drawer', props.urn, props.status]

  const {
    data: installment,
    isLoading: isInstallmentLoading,
    isError: isInstallmentError,
    isFetched: isInstallmentFetched,
    refetch,
  } = useQuery<UseInstallmentQueryResponse, XMLHttpRequest>(
    queryKey,
    () => {
      return service.fetchGuardianInstallmentDrawer({
        urn: props.urn,
        schoolId: props.schoolId,
        guardianId: props.guardianId,
        status: props.status,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled:
        Boolean(props.urn) &&
        Boolean(props.schoolId) &&
        Boolean(props.guardianId) &&
        Boolean(props.status),
      retry: DEFAULT_QUERY_RETRY_COUNT,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...options,
    }
  )

  return {
    installment,
    isInstallmentLoading,
    isInstallmentError,
    isInstallmentFetched,
    refetchInstallment: refetch,
  }
}

import styled from 'styled-components'

export const Container = styled.section`
  display: grid;
  grid-gap: ${props => props.theme.gravity.spacing[18]};
  margin-bottom: ${props => props.theme.gravity.spacing[18]};

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: ${props => props.theme.gravity.spacing[16]};
  }

  .content {
    display: flex;
    gap: ${props => props.theme.gravity.spacing[6]};
  }

  .description {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
  }

  .medium {
    font-weight: ${props => props.theme.gravity.fontWeight.medium};
  }
`

export type CategoryColors =
  | 'colors-background-neutral-3'
  | 'colors-background-semantic-colors-warning-2'
  | 'colors-interaction-secondary-2'

type IconContainerProps = {
  color: CategoryColors
}

export const IconContainer = styled.div<IconContainerProps>`
  width: ${props => props.theme.gravity.spacing[14]};
  height: ${props => props.theme.gravity.spacing[14]};

  background-color: ${props => props.theme.gravity.colors[props.color]};
  border-radius: ${props => props.theme.gravity.borderRadius.full};

  display: flex;
  align-items: center;
  justify-content: center;
`

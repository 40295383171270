import styled from 'styled-components'

export const Container = styled.div`
  padding: ${props => props.theme.gravity.spacing[10]} 0;

  display: flex;
  justify-content: center;

  .grid-container {
    row-gap: ${props => props.theme.gravity.spacing[8]};
  }
`

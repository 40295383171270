import { useMutation } from '@tanstack/react-query'

import { useApi } from '@/utils/hooks/useApi'
import type { MakeAgreementBody } from '@/modules/guardians/models/agreement'

export const useMakeAgreementMutation = () => {
  const { api } = useApi()

  return useMutation((body: MakeAgreementBody[]) => api.agreement.makeAgreement(body))
}

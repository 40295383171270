/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  AcceptPaymentPlanResponse,
  AddTuitionToCampaignResponse,
  CloseCampaignRequest,
  CreateCampaignRequest,
  Enrollment,
  EnrollmentCycleItem,
  EnrollmentCycleRequest,
  EnrollmentCycleResponse,
  GetCampaignsRequest,
  GetCampaignsResponse,
  GetCampaignStudentRequest,
  GetCampaignStudentResponse,
  GetEnrollmentCycleSituationResponse,
  GetGuardiansRequest,
  GetGuardiansResponse,
  HasCampaignEnrollmentRequest,
  HasCampaignEnrollmentResponse,
  Installment,
  StatusSummaryRequest,
  StatusSummaryResponse,
  UploadSignatureDocumentRequest,
} from '@/modules/enrollment/services/enrollmentService/types'
import {
  CampaignStatus,
  Status,
  PaymentPlanType,
} from '@/modules/enrollment/services/enrollmentService/types'
import type { Discount } from '@/shared/interfaces'
import {
  DebtStatus,
  InstallmentType,
  ReEnrollmentSubStatus,
  ReenrollmentStatus,
} from '@/shared/interfaces'
import { guardian1, guardian2, guardian3 } from '@/shared/mocks/guardians'
import { product1, productWithEnrollmentAndTemplate as product2 } from '@/shared/mocks/products'
import { student } from '@/shared/mocks/students'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import type {
  EnabledEnrollmentCycle,
  IrregularStudentItem,
  IrregularityBigNumbersType,
} from '@/modules/enrollment/services/types'
import {
  GuardianIrregularityType,
  InstallmentIrregularityType,
  IrregularStudentStatus,
} from '@/modules/enrollment/pages/IrregularStudentEnrollments/types'
import { AxiosResponse } from 'axios'

const sloppyFilter = (item: EnrollmentCycleItem, name: string): boolean => {
  if (!name || name === '') {
    return true
  }

  return JSON.stringify(item).toLowerCase().includes(name.toLowerCase())
}

export const mockStudentEnabledEnrollmentCycle: EnabledEnrollmentCycle = {
  is_reenrollable: true,
  reference_year: 2024,
  started_enrollment: true,
  student_status: ReenrollmentStatus.ENROLLMENT_IN_PROGRESS,
  student_status_details: [
    ReEnrollmentSubStatus.PRE_ENROLLMENT_PENDING,
    ReEnrollmentSubStatus.PREVIOUS_YEAR_OVERDUE,
  ],
}

export const enrollmentCycleMockData: EnrollmentCycleItem[] = [
  {
    main_contract_id: '398f24e5-c2f3-4a64-8d5a-5a241094ecb5',
    main_guardian: guardian1,
    main_product: product1,
    other_guardians: [guardian2],
    other_products: [product2],
    is_reenrollable: true,
    started_enrollment: true,
    student_id: '05c49c83-d92a-479f-bcf7-e160877790e9',
    student_name: 'Roberto Planta',
    student_status: ReenrollmentStatus.ENROLLMENT_IN_PROGRESS,
    student_referral: '',
    debt_status: DebtStatus.UP_TO_DATE,
    student_status_details: [
      ReEnrollmentSubStatus.PRE_ENROLLMENT_PENDING,
      ReEnrollmentSubStatus.PREVIOUS_YEAR_OVERDUE,
    ],
  },
  {
    main_contract_id: 'd82e67db-e628-4d8e-aee4-7203dcb26c9e',
    main_guardian: guardian2,
    main_product: product1,
    other_guardians: [],
    other_products: [],
    is_reenrollable: true,
    started_enrollment: false,
    student_id: '4f7851df-bf99-43aa-a631-cf19bdf29b7a',
    student_name: 'Tony Gaivota',
    student_status: ReenrollmentStatus.REENROLLMENT_AVAILABLE,
    student_referral: '',
    debt_status: DebtStatus.OVERDUE,
    student_status_details: [],
  },
  {
    main_contract_id: 'dbf6d5a6-c6c1-4778-8a4f-5b6e19f9672d',
    main_guardian: guardian2,
    main_product: product1,
    other_guardians: [],
    other_products: [],
    is_reenrollable: false,
    started_enrollment: false,
    student_id: '8db22a40-75db-4be9-84fc-54ae3d4d3675',
    student_name: 'Rogério Águas',
    student_status: ReenrollmentStatus.ENROLLED,
    student_referral: '',
    debt_status: DebtStatus.UP_TO_DATE,
    student_status_details: [],
  },
  {
    main_contract_id: 'd9c11e26-69ea-4b11-a5ef-cbb3f17b4515',
    main_guardian: guardian1,
    main_product: product1,
    other_guardians: [],
    other_products: [],
    is_reenrollable: false,
    started_enrollment: false,
    student_id: '2509c7d9-1cec-4206-b0dc-012967aa80cd',
    student_name: 'Nicolas Gaiola',
    student_status: ReenrollmentStatus.NOT_REENROLLABLE,
    student_referral: '',
    debt_status: DebtStatus.UP_TO_DATE,
    student_status_details: [ReEnrollmentSubStatus.PREVIOUS_YEAR_OVERDUE],
  },
]

export const fetchEnrollmentCycleMock = async (
  params: EnrollmentCycleRequest
): Promise<EnrollmentCycleResponse> => {
  return {
    data: enrollmentCycleMockData
      .filter(e => sloppyFilter(e, params.name || ''))
      .filter(e => !params.studentId || e.student_id === params.studentId),
    pagination: {
      total: 10,
    },
  }
}

export const updateEnrollmentSituationMock = async (
  schoolID: string,
  studentID: string,
  enrollmentCycle: number
): Promise<GetEnrollmentCycleSituationResponse> => {
  return {
    data: {
      status: ReenrollmentStatus.REENROLLMENT_AVAILABLE,
      sub_status: [],
      is_reenrollable: true,
    },
    pagination: {
      total: 10,
    },
  }
}

export const fetchStudentGuardiansMock = async (
  param: GetGuardiansRequest
): Promise<GetGuardiansResponse> => {
  return {
    data: param.taxID ? [{ ...guardian3, tax_id: param.taxID }] : [guardian1, guardian2],
  }
}

export const fetchStatusSummaryMock = async (
  param: StatusSummaryRequest
): Promise<StatusSummaryResponse> => {
  return {
    data: {
      total: 1538,
      status: {
        [ReenrollmentStatus.ENROLLED]: 482,
        [ReenrollmentStatus.ENROLLMENT_AT_RISK]: 64,
        [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: 243,
        [ReenrollmentStatus.NOT_REENROLLABLE]: 349,
        [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: 400,
      },
    },
  }
}

type matriculasContract = {
  guardian_id: uuid
  id: uuid
  installments: any
  next_year_contracts: any
  pre_contract_installments: any
  product_id: uuid
  reference_year: string
  status: string
  student_id: uuid
}

const fakeMatriculasContract1 = {
  guardian_id: '5c7b0849-2865-4130-8481-bc9d35a65907',
  id: '9d532da5-2c99-40f6-94be-b3e67aec9e18',
  installments: new Array<unknown>(),
  next_year_contracts: new Array<unknown>(),
  pre_contract_installments: new Array<unknown>(),
  product_id: product1.id,
  reference_year: '2024',
  status: 'PENDING',
  student_id: student.id,
}

const fakeMatriculasContract2 = {
  guardian_id: '4b24fcba-d96d-40af-94cd-8d73cace825b',
  id: '2bd8cc5f-1522-4f15-b883-aa46abb4ceb2',
  installments: new Array<unknown>(),
  next_year_contracts: new Array<unknown>(),
  pre_contract_installments: new Array<unknown>(),
  product_id: product1.id,
  reference_year: '2023',
  status: 'PENDING',
  student_id: student.id,
}

export const getMatriculasApiLegacyEnrollmentsMock = async (
  guardianId: uuid,
  referenceYear: string,
  schoolId: uuid,
  installment_status: string | null = null,
  student_id: uuid | null = null
): Promise<matriculasContract[]> => {
  if (referenceYear === '0') {
    return [fakeMatriculasContract1, fakeMatriculasContract2]
  }

  return [fakeMatriculasContract1]
}

export const createDiscount = (attrs: Partial<Discount> = {}) => {
  return {
    id: uuidv4(),
    amount: 20000,
    days_before_due_date: 2,
    description: 'discount description',
    ...attrs,
  }
}

export const createInstallment = (attrs: Partial<Installment> = {}) => {
  return {
    id: uuidv4(),
    created_at: dayjs().toISOString(),
    updated_at: dayjs().toISOString(),
    discounts: [createDiscount()],
    amount: 60000,
    due_date: new Date().toISOString(),
    use_working_dates: false,
    status: Status.PENDING,
    type: InstallmentType.TUITION,
    ...attrs,
  }
}

export const createEnrollment = (attrs: Partial<Enrollment> = {}) => {
  const installments = [
    createInstallment({ type: InstallmentType.ENROLLMENT, status: Status.OPEN }),
    createInstallment(),
    createInstallment(),
  ]

  return {
    id: uuidv4(),
    created_at: dayjs().toISOString(),
    updated_at: dayjs().toISOString(),
    guardian_id: uuidv4(),
    product_id: uuidv4(),
    student_id: uuidv4(),
    installments,
    reference_year: '2024',
    status: Status.OPEN,
    ...attrs,
  }
}

export const putEnrollmentMock = async (
  schoolId: uuid,
  enrollmentId: uuid,
  installments: Installment[]
): Promise<AxiosResponse<any>> => {
  return {} as AxiosResponse<any>
}

export const createCampaignMock = async (
  schoolId: uuid,
  params: CreateCampaignRequest
): Promise<AxiosResponse<any>> => {
  return {} as AxiosResponse<any>
}

export const closeCampaignMock = async (
  campaignId: uuid,
  params: CloseCampaignRequest
): Promise<AxiosResponse<any>> => {
  return {} as AxiosResponse<any>
}

export const fetchCampaignsMock = async (
  params: GetCampaignsRequest
): Promise<GetCampaignsResponse> => {
  return {
    data: {
      campaigns: [
        {
          closed_type: '',
          created_date: '2024-01-01T01:01:01.001Z',
          end_date: '2024-04-01T01:01:01.001Z',
          engaged_students_count: 2,
          id: '347ca552-8c50-45e4-bee0-eaec79ed7036',
          name: 'Expired Campaign',
          product_id: 'ba927b82-3768-4301-b215-76039fe12f83',
          product_name: 'Campaign Product',
          status: CampaignStatus.OPEN,
          students_count: 10,
          has_payment_plan: true,
          total_of_campaign_emails_read: 2,
          payment_plans: [
            {
              id: '347ca552-8c50-45e4-bee0-eaec79ed7036',
              due_date: '2024-04-01T01:01:01.001Z',
              amount: 20000,
              type: PaymentPlanType.ENROLLMENT,
              duration_months: 2,
            },
          ],
          paid_enrollments_count: 1,
        },
      ],
    },
    pagination: {
      total: 10,
    },
  }
}

export const fetchCampaignStudentsMock = async (
  params: GetCampaignStudentRequest
): Promise<GetCampaignStudentResponse> => {
  return {
    data: {
      students: [
        {
          enrollment_status: ReenrollmentStatus.REENROLLMENT_AVAILABLE,
          guardian_email: 'email@email.com',
          guardian_name: 'Guardian Name',
          has_open_email: true,
          has_tuition: true,
          is_engaged: true,
          origin_product_name: 'Origin Product Name',
          paid_enrollment: true,
          student_name: 'Student Name da Silva',
          student_id: '3997ca552-8c50-45e4-bee0-eaec79ed7036',
          is_campaign_proposal_canceled: false,
          enrollment_id: '0000000',
        },
      ],
    },
    pagination: {
      total: 10,
      per_page: 100,
      page: 1,
    },
  }
}

export const createSchoolEnrollmentCycles = (schoolId: uuid) => {
  return [
    {
      school_id: schoolId,
      school_enrollment_cycle_id: uuidv4(),
      reference_year: 2024,
      enabled: true,
      created_at: '2024-01-20T01:28:13.948Z',
      updated_at: '2024-01-20T01:28:13.948Z',
    },
    {
      school_id: schoolId,
      school_enrollment_cycle_id: uuidv4(),
      reference_year: 2023,
      enabled: true,
      created_at: '2023-01-15T01:28:13.948Z',
      updated_at: '2023-01-15T01:28:13.948Z',
    },
    {
      school_id: schoolId,
      school_enrollment_cycle_id: uuidv4(),
      reference_year: 2022,
      enabled: false,
      created_at: '2022-01-15T01:28:13.948Z',
      updated_at: '2022-01-15T01:28:13.948Z',
    },
  ]
}

export const irregularStudentsMockData: IrregularStudentItem[] = [
  {
    student_name: 'Alana Pentalagus 2',
    status: IrregularStudentStatus.FINISHED,
    total_pending_items: 5,
    total_blocked_amount: 80000,
    reference_link: 'https://www.globo.com/',
    enrollment_requests: [
      {
        id: '940d58a5-b193-4308-86b6-dd11daf9d43c',
        product_id: 'ae0b57bd-2722-485b-af9a-58084947af77',
        reference_year: 2024,
        reference_link: 'https://gshow.globo.com/',
        status: IrregularStudentStatus.IN_PROGRESS,
        guardian_request: {
          guardian_irregularities: [
            {
              irregularity_type: GuardianIrregularityType.ADDRESS_ZIP_CODE,
              resolved_at: '',
            },
          ],
          address_additional_information: '',
          city: '',
          email: '',
          external_id: '',
          name: 'Pedro Teste',
          number: '',
          phone_number: '',
          state_code: '',
          street: '',
          tax_id: '',
          zip_code: '',
        },
        installment_requests: [
          {
            amount: 100000,
            competence_date: '04/2024',
            due_date: '2024-04-01',
            installment_irregularities: [
              {
                irregularity_type: 'INSTALLMENT_DUE_DATE_EXPIRED',
                resolved_at: '',
              },
            ],
            net_amout: 100000,
            type: 'TUITION',
          },
        ],
        external_id: '',
      },
    ],
    external_id: '',
    id: '',
  },
]

export const irregularityBigNumbersMockData: IrregularityBigNumbersType = {
  [IrregularStudentStatus.FINISHED]: {
    amount: 100000,
    count: 10,
  },
  [IrregularStudentStatus.IN_PROGRESS]: {
    amount: 200000,
    count: 20,
  },
  [IrregularStudentStatus.NOT_STARTED]: {
    amount: 300000,
    count: 30,
  },
}

export const acceptPaymentPlanMock = async (
  enrollmentID: uuid
): Promise<AcceptPaymentPlanResponse> => {
  return {} as AcceptPaymentPlanResponse
}

export const hasCampaignEnrollmentMock = async ({
  schoolId,
  studentId,
}: HasCampaignEnrollmentRequest): Promise<HasCampaignEnrollmentResponse['data']> => {
  return {
    has_campaign_enrollment: false,
  }
}

export const addTuitionToCampaignMock = async (
  campaignID: uuid
): Promise<AddTuitionToCampaignResponse> => {
  return {} as AddTuitionToCampaignResponse
}

export const uploadSignatureDocumentMock = async (
  schoolId: uuid,
  params: UploadSignatureDocumentRequest
): Promise<AxiosResponse<any>> => {
  return {} as AxiosResponse<any>
}

import { useApiClient } from '@/shared/hooks/useApiClient'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { userProfileService } from '@monorepo/access-management/services/user-profile'
import {
  UpdateProfileResponse,
  UpdateProfileRequest,
} from '@monorepo/access-management/services/user-profile/types'

export const useMutateOnUpdateUserProfile = (
  options: UseMutationOptions<UpdateProfileResponse, unknown, UpdateProfileRequest, unknown>
) => {
  const { apiClient } = useApiClient()

  const service = userProfileService(apiClient.getClients().bffApi)

  return useMutation(service.updateProfile, options)
}

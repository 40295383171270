import { useEffect, useState } from 'react'

import config from '@/config'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks'
import userGuidingService from 'src/shared/libs/userguiding/userGuidingService'

import { CONTRACT_TABLE_TAB, ON_PRESS_NEVER_SHOW_AGAIN_GUIDE_EVENT } from '../constants'
import { addUserGuidingSeenItem, shouldShowTheUserGuiding } from '../utils/userGuiding'
import type { UseUserGuidingParams } from './types'
import { UserGuidingGuidesIds } from '@/shared/hooks/useUserGuidingTrigger'

function useUserGuiding({
  userId,
  email,
  name,
  isAdmin,
  isLoading,
  activeTab,
}: UseUserGuidingParams) {
  const [userGuidingIsInitialized, setUserGuidingIsInitialized] = useState(
    typeof userGuiding !== 'undefined'
  )

  const isAgreementGuideFlagEnabled = useUnleashFlag(
    UnleashFlags.PAYMENTS_PAYM_915_ENABLE_AGREEMENT_USERGUIDING
  )

  useEffect(() => {
    const isUserEligibleToInitTheGuide =
      !isAdmin && isAgreementGuideFlagEnabled && !userGuidingIsInitialized

    if (isUserEligibleToInitTheGuide) {
      const userParams = {
        id: userId,
        name,
        email,
      }
      userGuidingService
        .init(userParams, window, document, config.USERGUIDING_ID)
        .then(() => setUserGuidingIsInitialized(true))
    }
  }, [userGuidingIsInitialized, isAgreementGuideFlagEnabled, isAdmin])

  useEffect(() => {
    if (
      !isAdmin &&
      !isLoading &&
      activeTab === CONTRACT_TABLE_TAB.INSTALLMENTS &&
      isAgreementGuideFlagEnabled &&
      userGuidingIsInitialized &&
      shouldShowTheUserGuiding(userId)
    ) {
      addUserGuidingSeenItem(userId)
      userGuiding.previewGuide(UserGuidingGuidesIds.AGREEMENT_BUTTON)
    }
  }, [userGuidingIsInitialized, isLoading, activeTab, isAdmin])

  useEffect(() => {
    function handleOnPressNeverShowAgain() {
      addUserGuidingSeenItem(userId, false)
      userGuiding.finishPreview()
    }

    document
      .querySelector('body')
      .addEventListener(ON_PRESS_NEVER_SHOW_AGAIN_GUIDE_EVENT, handleOnPressNeverShowAgain)

    return () => {
      document.removeEventListener(
        ON_PRESS_NEVER_SHOW_AGAIN_GUIDE_EVENT,
        handleOnPressNeverShowAgain
      )
    }
  }, [])
}

export default useUserGuiding

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useIsaacPayPayoutReports } from '@/modules/report/hooks/queries/useIsaacPayPayoutReports'
import { usePayoutDetailsQuery } from '@/escolas/hooks/queries'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { usePagination } from '@/shared/hooks/usePagination'
import { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'
import { FetchPayoutDetailsResponseDTO, PayoutDomain } from '@/escolas/services/report/types'
import { usePayoutMonthAggregation } from '@/modules/isaacpay/hooks/usePayoutMonthAggregation'
import { IsaacPayTabs } from '@/modules/report/constants/PAYOUT_OUTSOURCED_PROVIDER_TABS'
import { useQuery } from '@/shared/hooks/useQuery'
import { useTuitionPayoutReportFilter } from '@/modules/report/hooks/useTuitionPayoutReportFilter'
import { useIsaacPayOutsourcedPaymentProvider } from '@/shared/hooks/useIsaacPayOutsourcedPaymentProvider'

type IsaacPayPayoutReportDataValue = {
  detailedPayoutReport: FetchPayoutDetailsResponseDTO<'isaac-pay'>
  enabled: boolean
  hasError: boolean
  isFetching: boolean
  payoutMonthAggregation: FetchPayoutDetailsResponseDTO<'isaac-pay'>
  payoutReportList: UnguaranteedReport[]
  refetch: () => void
}

type Props = {
  activeTab: IsaacPayTabs
  endDate: Date
  isMonthlyViewEnabled: boolean
  payoutId: string | null
  startDate: Date
}

/**
 * Custom hook that exports isaac-pay payout report page data
 */
export const useIsaacPayPayoutReportData = ({
  startDate,
  endDate,
  isMonthlyViewEnabled,
  payoutId,
  activeTab,
}: Props): IsaacPayPayoutReportDataValue => {
  const { query } = useQuery()
  const { school } = useSelectedSchool()
  const { filter } = useTuitionPayoutReportFilter()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const { pagination } = usePagination(1, 25)
  const { ordering } = useOrdering()

  const enabledPayoutListHook = !isOutsourcedProvider
  const {
    data: isaacPayPayoutReportList,
    isFetching: isFetchingIsaacPayPayoutReportList,
    isError: hasErrorOnFetchIsaacPayPayoutReportList,
    refetch: refetchIsaacPayPayoutReportList,
  } = useIsaacPayPayoutReports(school.id, {
    enabled: enabledPayoutListHook,
  })

  const nameFilter = query.get('name') ?? ''

  const enablePayoutDetailsHook = enabledPayoutListHook && !!payoutId && !isMonthlyViewEnabled

  const {
    data: isaacPayPayoutReportDetails,
    isFetching: isFetchingIsaacPayPayoutReportDetails,
    isError: hasErrorOnFetchIsaacPayPayoutReportDetails,
    refetch: refetchIsaacPayPayoutReportDetails,
  } = usePayoutDetailsQuery(
    'isaac-pay',
    {
      payoutId,
      filter: {
        name: nameFilter,
        ...filter,
      },
      pagination: {
        page: pagination.page,
        per_page: pagination.itemsPerPage,
        sort_by: ordering?.sortBy,
        sort_order: ordering?.sortOrder,
      },
    },
    { enabled: enablePayoutDetailsHook }
  )

  const enablePayoutMonthAggregationHook =
    (!isOutsourcedProvider && isMonthlyViewEnabled) ||
    (isOutsourcedProvider && activeTab === IsaacPayTabs.OTHER_PAYMENTS)
  const {
    data: isaacPayPayoutMonthAggregation,
    isFetching: isFetchingPayoutMonthAggregation,
    isError: hasErrorOnFetchIsaacPayPayoutMonthAggregation,
    refetch: refetchIsaacPayPayoutMonthAggregation,
  } = usePayoutMonthAggregation(
    {
      startDate: startDate.toLocaleDateString('en-CA'),
      endDate: endDate.toLocaleDateString('en-CA'),
      payoutDomain: PayoutDomain.IsaacPay,
      schoolID: school.id,
      filter: {
        name: nameFilter,
      },
      pagination: {
        page: pagination.page,
        per_page: pagination.itemsPerPage,
        sort_by: ordering?.sortBy,
        sort_order: ordering?.sortOrder,
      },
    },
    {
      enabled: enablePayoutMonthAggregationHook,
    }
  )

  function refetch() {
    if (enabledPayoutListHook) {
      refetchIsaacPayPayoutReportList()
    }

    if (enablePayoutDetailsHook) {
      refetchIsaacPayPayoutReportDetails()
    }

    if (enablePayoutMonthAggregationHook) {
      refetchIsaacPayPayoutMonthAggregation()
    }
  }

  const hookValue: IsaacPayPayoutReportDataValue = {
    payoutMonthAggregation: isaacPayPayoutMonthAggregation,
    detailedPayoutReport: isaacPayPayoutReportDetails,
    payoutReportList: isaacPayPayoutReportList,
    hasError:
      hasErrorOnFetchIsaacPayPayoutReportDetails ||
      hasErrorOnFetchIsaacPayPayoutReportList ||
      hasErrorOnFetchIsaacPayPayoutMonthAggregation,
    enabled: (enabledPayoutListHook && enablePayoutDetailsHook) || enablePayoutMonthAggregationHook,
    refetch,
    isFetching:
      isFetchingIsaacPayPayoutReportDetails ||
      isFetchingIsaacPayPayoutReportList ||
      isFetchingPayoutMonthAggregation,
  }

  return hookValue
}

import { PageStatusWrapper } from '@/shared/components/PageStatusWrapper'
import { NotFound } from '@/modules/app/pages/NotFound'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { IrregularStudentTable } from './components/IrregularStudentTable'
import {
  useIrregularStudentsQuery,
  useIrregularityBigNumbersQuery,
} from './hooks/useIrregularStudentsQuery'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { usePagination } from '@/shared/hooks/usePagination'
import * as Styled from './styles'
import { Box } from '@material-ui/core'
import { Pagination } from '@olaisaac/design-system'
import { IrregularityBigNumbers } from './components/IrregularityBigNumbers'
import { Heading } from '@gravity/heading'
import { useEffect, useState } from 'react'
import { Text } from '@gravity/text'
import { SearchBar } from './components/SearchBar'
import { IrregularStatusFilter } from './components/IrregularStatusFilter'
import { useIrregularStudentsFilter } from './hooks/useIrregularStudentsFilter'
import { useIrregularStudentsEvents } from './hooks/useIrregularStudentsEvents'
import { EmptyState } from './components/EmptyState'
import { useLayout } from '@/shared/hooks/useLayout'
import { Option } from '@gravity/select'
import { useSchoolEnrollmentCycles } from '../../hooks/useSchoolEnrollmentCycles'
import {
  DEFAULT_IRREGULAR_STUDENT_STATUS_FILTERS,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
} from './constants'

export const IrregularStudentEnrollments = () => {
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const { school } = useSelectedSchool()
  const { pagination, updatePaginationValue } = usePagination(DEFAULT_PAGE, DEFAULT_PER_PAGE)
  const { enrollmentCycleYears, activeEnrollmentCycleYear } = useSchoolEnrollmentCycles()
  const [studentName, setStudentName] = useState<string>('')

  const { irregularStudentsFilter, updateFilter } = useIrregularStudentsFilter(
    DEFAULT_IRREGULAR_STUDENT_STATUS_FILTERS,
    String(activeEnrollmentCycleYear)
  )

  const { data, isLoading, isError, isSuccess } = useIrregularStudentsQuery({
    referenceYear: irregularStudentsFilter.referenceYear,
    schoolId: school?.id ?? '',
    studentName: studentName,
    status: irregularStudentsFilter.irregularStatus,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  })

  const {
    data: dataEmptyState,
    isLoading: isLoadingEmptyState,
    isError: isErrorEmptyState,
  } = useIrregularStudentsQuery({
    referenceYear: irregularStudentsFilter.referenceYear,
    schoolId: school?.id ?? '',
    pagination: {
      page: 1,
      per_page: 1,
    },
  })

  const {
    data: dataBigNumbers,
    isLoading: isLoadingBigNumbers,
    isError: isErrorBigNumbers,
  } = useIrregularityBigNumbersQuery({
    referenceYear: irregularStudentsFilter.referenceYear,
    schoolId: school?.id ?? '',
  })

  const {
    sendPageViewEvent,
    sendClickSelectReferenceYearEvent,
    sendClickSearchBarEvent,
  } = useIrregularStudentsEvents()

  useEffect(sendPageViewEvent, [])

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Integrações' })

  if (!isIntegratedSchool) {
    return <NotFound />
  }

  const referenceYearOptions = enrollmentCycleYears?.reverse().map(
    (item): Option => ({
      value: item.toString(),
      label: item.toString(),
    })
  )

  const getStatus = () => {
    if (isError || isErrorBigNumbers || isErrorEmptyState) {
      return 'error'
    }

    if (isLoadingBigNumbers || isLoadingEmptyState) {
      return 'loading'
    }

    return 'success'
  }

  return (
    <PageStatusWrapper status={getStatus()}>
      <Styled.PageContainer>
        <Styled.HeaderContainer>
          <Styled.TitleContainer>
            <Heading variant="heading-h3-medium">Pendências de registro</Heading>
          </Styled.TitleContainer>
          <Styled.StyledSelect
            options={referenceYearOptions}
            size={3}
            variant="surface"
            defaultValue={irregularStudentsFilter.referenceYear || referenceYearOptions[0].value}
            onValueChange={value => {
              updateFilter({ ...irregularStudentsFilter, referenceYear: value })
            }}
            onOpenChange={open => open && sendClickSelectReferenceYearEvent()}
          />
        </Styled.HeaderContainer>
        {dataEmptyState?.data?.length ? (
          <>
            <Styled.SubtitleContainer>
              <Text variant="subtitle-regular">
                Pendências de registro impedem que os alunos sejam registrados e os valores sejam
                repassados para a escola.
              </Text>
              <br />
              <Text variant="subtitle-regular">As correções devem ser feitas no seu sistema.</Text>
            </Styled.SubtitleContainer>
            <Styled.BigNumbersContainer>
              <IrregularityBigNumbers
                data={dataBigNumbers?.data}
                isLoading={isLoadingBigNumbers}
                isError={isErrorBigNumbers}
              />
            </Styled.BigNumbersContainer>
            <Styled.SearchBarContainer>
              <SearchBar
                initialValue={studentName}
                setValue={setStudentName}
                placeholder="Buscar por aluno"
                onClick={sendClickSearchBarEvent}
              />
            </Styled.SearchBarContainer>
            <IrregularStatusFilter
              irregularStudentsFilter={irregularStudentsFilter}
              updateFilter={updateFilter}
            />
            <IrregularStudentTable data={isSuccess ? data?.data : []} isLoading={isLoading} />
            <Box my={2} px={2}>
              <Pagination
                currentPage={pagination.page}
                itensPerPage={pagination.itemsPerPage}
                itensPerPageOptions={[10, 15, 25, 50]}
                totalItens={isSuccess ? data?.pagination?.total ?? 0 : 0}
                onPageChange={newPage => {
                  updatePaginationValue('page', newPage)
                }}
                onItensPerChangeChange={newItensPerPage => {
                  updatePaginationValue('itemsPerPage', newItensPerPage)
                }}
              />
            </Box>
          </>
        ) : (
          <EmptyState referenceYear={irregularStudentsFilter.referenceYear} />
        )}
      </Styled.PageContainer>
    </PageStatusWrapper>
  )
}

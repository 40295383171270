import styled from 'styled-components'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.theme.gravity.spacing[10]};
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${props => props.theme.gravity.spacing[4]};
  width: 100%;
`

import { useApi } from '@/utils/hooks/useApi'
import { AddDiscountsReqBody } from '@/shared/interfaces'
import { useQuery } from '@tanstack/react-query'

export const useCanAddDiscountQuery = (props: AddDiscountsReqBody & { schoolId: uuid }) => {
  const { api } = useApi()

  const queryKey = ['school-contract-installmentDrawer-canAddDiscounts', props.receivable_id]

  const query = useQuery<{ is_contract_in_flexible_period: boolean }, XMLHttpRequest>(
    queryKey,
    () => {
      return api.receivables.canAddDiscounts(props)
    },
    {
      // ...SOFTCACHE_OPTIONS_BASE,
      retry: false,
      staleTime: 1000 * 60 * 5,
      enabled: Boolean(props.receivable_id),
      // ...options,
    }
  )

  return query
}

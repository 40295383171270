import { Wrapper } from '../../styles'
import { Text } from './styles'

export function EmptyDeliveries() {
  return (
    <Wrapper>
      <Text variant="body-1-medium">Não existem materiais para serem entregues</Text>
      <Text>
        Informe aos responsáveis que os materiais didáticos já podem ser pagos através do aplicativo
        meu isaac.
      </Text>
    </Wrapper>
  )
}

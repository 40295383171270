import { NetworkMode, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useApi } from '@/utils/hooks/useApi'
import { PreContract } from 'src/shared/interfaces'
import { SaveTuitionPayload } from '../types'

export type usePreContractCommands = {
  saveTuition: (payload: SaveTuitionPayload) => void
}

export type usePreContractQueries = {
  isFetchPreContractError: boolean
  isFetchPreContractLoading: boolean
  isSaveTuitionError: boolean
  isSaveTuitionLoading: boolean
  isSaveTuitionSuccess: boolean
  preContract: PreContract
}

export const usePreContract = (
  contractId: uuid
): usePreContractCommands & usePreContractQueries => {
  const { api } = useApi()
  const queryClient = useQueryClient()
  const queryKey = ['pre-contract', contractId]
  const networkMode: NetworkMode = 'offlineFirst'

  const {
    data: preContract,
    isLoading: isFetchPreContractLoading,
    isError: isFetchPreContractError,
  } = useQuery<PreContract>(queryKey, () => api.preContracts.get(contractId), {
    networkMode: networkMode,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    staleTime: 1000 * 60 * 5,
  })

  const {
    mutate: saveTuition,
    isLoading: isSaveTuitionLoading,
    isError: isSaveTuitionError,
    isSuccess: isSaveTuitionSuccess,
  } = useMutation(({ id, tuition }: SaveTuitionPayload) => api.preContracts.save(id, tuition), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    preContract,
    isFetchPreContractLoading,
    isFetchPreContractError,
    saveTuition,
    isSaveTuitionLoading,
    isSaveTuitionError,
    isSaveTuitionSuccess,
  }
}

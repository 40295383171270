import { Table } from '@gravity/table'

import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'
import Icon from '@mdi/react'

import { mdiPencil } from '@mdi/js'

export const TableAction = ({ onClick }: { onClick: () => void }) => {
  return (
    <Table.ActionCell
      actions={
        <Tooltip text="Editar dados" position="left">
          <IconButton
            variant="ghost"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              onClick()
            }}
          >
            <Icon path={mdiPencil} size={16} />
          </IconButton>
        </Tooltip>
      }
    />
  )
}

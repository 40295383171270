import { Container } from './styles'
import { CommunicationSection } from '@/modules/school-home/pages/SchoolHome/components/CommunicationSection'
import { QuickAccessSection } from '@/modules/school-home/pages/SchoolHome/components/QuickAccessSection'
import { HomeSearch } from './components/HomeSearch'
import { MainInfoSection } from './components/MainInfoSection'

import { useJWT } from '@/shared/hooks/useJWT'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useLayout } from '@/shared/hooks/useLayout'

import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'

export const SchoolHome = () => {
  const { hasPayoutReportAccess } = useJWT()
  const { school } = useSelectedSchool()

  const isIsaacPay = isIsaacPaySchool(school)
  const showMainInfoSection = hasPayoutReportAccess && !isIsaacPay

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Página inicial',
  })

  return (
    <Container>
      <HomeSearch />
      <QuickAccessSection />
      <CommunicationSection />
      {showMainInfoSection && <MainInfoSection />}
    </Container>
  )
}

import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { date2PTFormat, formatCentsToReal } from 'src/shared/utils'
import { ContractInstallments } from 'src/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawerDefaultContent'
import { useContract } from 'src/escolas/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'

export type ContractDetailsSummaryProps = {
  contractInstallments: ContractInstallments
}

const Discount = styled(Typography)`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  & span {
    font-size: 15px;
  }
  font-weight: normal;
`

const StyledTypography = styled(Typography)`
  font-weight: 400;
`
const BoldTypography = styled(Typography)`
  font-weight: bold;
`

export const ContractDetailsSummary = ({ contractInstallments }: ContractDetailsSummaryProps) => {
  const { annuity, enrollment, tuition, isaacPay } = contractInstallments
  const { contract } = useContract()

  const { school } = useSelectedSchool()
  const tuitionInstallments = isIsaacPaySchool(school) ? isaacPay : tuition

  return (
    <Box mt={2} mb={4}>
      {enrollment.installments_quantity > 0 && (
        <Box mb={3}>
          <Typography variant="subtitle1">Pré-matrícula</Typography>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1.5}>
            <Typography variant="body1" color="textSecondary">
              Total
            </Typography>
            <Typography variant="body1" color="textSecondary" data-testid="enrollment-total-amount">
              {formatCentsToReal(enrollment.totalAmount)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
            <Discount variant="body1" color="textSecondary">
              Descontos
            </Discount>
            <Discount
              variant="body1"
              data-testid="enrollment-discounts-total-amount"
              color="textSecondary"
            >
              {`- ${formatCentsToReal(enrollment.discounts)}`}
            </Discount>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <StyledTypography variant="body1">Parcelas</StyledTypography>
            <StyledTypography variant="body1" data-testid="enrollment-installments">{`${
              enrollment.installments_quantity
            }x de ${formatCentsToReal(
              enrollment.installment - enrollment.discounts
            )}`}</StyledTypography>
          </Box>
        </Box>
      )}
      <Box mb={3}>
        <Typography variant="subtitle1">Mensalidades</Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1.5}>
          <Typography variant="body1" color="textSecondary">
            Total
          </Typography>
          <Typography variant="body1" color="textSecondary" data-testid="tuition-total-amount">
            {formatCentsToReal(tuitionInstallments.totalAmount)}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
          <Discount variant="body1" color="textSecondary">
            Descontos
          </Discount>
          <Discount
            variant="body1"
            data-testid="tuition-discounts-total-amount"
            color="textSecondary"
          >
            {`- ${formatCentsToReal(tuitionInstallments.discounts)}`}
          </Discount>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <StyledTypography variant="body1">Parcelas</StyledTypography>
          <StyledTypography variant="body1" data-testid="tuition-installments">{`${
            tuitionInstallments.installments_quantity
          }x de ${
            tuitionInstallments.installments_quantity > 0
              ? formatCentsToReal(tuitionInstallments.installment - tuitionInstallments.discounts)
              : formatCentsToReal(0)
          }`}</StyledTypography>
        </Box>
        <Divider />
        <Box mt={3} display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body1" color="textSecondary">
            Anuidade
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {formatCentsToReal(annuity.totalAmount)}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
          <StyledTypography variant="body1" color="textSecondary">
            Total de descontos
          </StyledTypography>
          <StyledTypography variant="body1" color="textSecondary">
            {formatCentsToReal(annuity.totalDiscounts)}
          </StyledTypography>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" justifyContent="space-between" textAlign="center">
        <Box pt={3} pb={2}>
          <BoldTypography variant="body2">ID do contrato</BoldTypography>
          <StyledTypography variant="body2">{contract?.id}</StyledTypography>
        </Box>
        {contract?.created_at && (
          <BoldTypography variant="body2">{`Contrato criado em ${date2PTFormat(
            contract?.created_at
          )}`}</BoldTypography>
        )}
      </Box>
    </Box>
  )
}

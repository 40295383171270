import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { mdiInformation, mdiReload } from '@mdi/js'
import Icon from '@mdi/react'

import { Container } from './styles'

type SectionErrorStateProps = {
  onRetry: () => void
}

export const SectionErrorState = ({ onRetry }: SectionErrorStateProps) => {
  return (
    <Container data-testid="error-state">
      <div>
        <Icon path={mdiInformation} size="20px" />
        <Text variant="subtitle-medium">Erro ao carregar as informações desta seção</Text>
      </div>

      <Button className="action" iconStart={<Icon path={mdiReload} />} onClick={onRetry}>
        Recarregar seção
      </Button>
    </Container>
  )
}

import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useApi } from '@/utils/hooks/useApi'
import { calcFixedDate } from '@/shared/utils'

const useDueDayCalculator = (startMonth, dueDay, useWorkingDates: boolean) => {
  const { api } = useApi()
  const [date, setDate] = useState<Dayjs>()

  const calculateWorkingDate = (_date: Dayjs, nthWorkingDay: number) => {
    if (_date.isValid() && nthWorkingDay > 0) {
      api.date
        .getWorkingDateFromNthDay({ date: _date.toISOString(), nth_working_day: nthWorkingDay })
        .then(res => {
          setDate(dayjs(res?.data))
        })
    } else {
      setDate(null)
    }
  }

  const calculateFixedDate = (_date: Dayjs, nthWorkingDay: number) => {
    setDate(calcFixedDate(_date, nthWorkingDay))
  }

  const resetDate = () => {
    setDate(null)
  }

  useEffect(() => {
    if (!startMonth || !dueDay) {
      resetDate()
      return
    }
    if (useWorkingDates) {
      calculateWorkingDate(startMonth, dueDay)
    } else {
      calculateFixedDate(startMonth, dueDay)
    }
  }, [startMonth, dueDay])

  return date
}

export default useDueDayCalculator

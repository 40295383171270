import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'
import { UseGuardiansQueryProps, UseGuardiansQueryResponse } from '../../services/types'
import { guardianService } from '../../services'

export type useGuardiansQueries = {
  guardians: UseGuardiansQueryResponse
  isFetchGuardiansError: boolean
  isFetchGuardiansFetched: boolean
  isFetchGuardiansLoading: boolean
}

export const useGuardiansQuery = (
  props: UseGuardiansQueryProps,
  options?: UseQueryOptions<UseGuardiansQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()
  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardians-debt-status', props]

  const {
    data: guardians,
    isLoading: isFetchGuardiansLoading,
    isError: isFetchGuardiansError,
    isFetched: isFetchGuardiansFetched,
  } = useQuery<UseGuardiansQueryResponse, XMLHttpRequest>(
    queryKey,
    () => {
      return service.fetchGuardiansList({
        ...props.pagination,
        ...props.filter,
        schoolId: props.schoolId,
      })
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    }
  )

  return {
    isFetchGuardiansLoading,
    isFetchGuardiansError,
    guardians,
    isFetchGuardiansFetched,
  }
}

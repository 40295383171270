import { Skeleton } from '@gravity/skeleton'
import { Container } from './styles'

export const OverviewSectionPlaceholder = () => (
  <Container data-testid="loading">
    <div className="cards">
      {[1, 2, 3].map(item => (
        <div key={item} className="content">
          <Skeleton variant="neutral" radius="full" className="icon" width={56} height={58} />
          <div className="description">
            <Skeleton variant="neutral" radius={6} className="title" width={80} height={16} />
            <Skeleton variant="neutral" radius={6} className="details" width={160} height={16} />
          </div>
        </div>
      ))}
    </div>
  </Container>
)

import { FormValues, SelectedProduct } from '..'

export const useFormValues = (selectedProduct: SelectedProduct | null) => {
  const defaultProduct: FormValues = {
    name: '',
    enrollment_amount: 0,
    enrollment_installments_quantity: 0,
    monthly_amount: 0,
    installments_quantity: 0,
    product_category_id: null,
    product_program_id: null,
    educational_substage_id: null,
    educational_stage_id: undefined,
  }

  const defaultFormValues: FormValues = selectedProduct
    ? {
        name: selectedProduct.name,
        envelope_template_referral_id: selectedProduct.envelope_template_referral_id,
        enrollment_amount: selectedProduct.enrollment_amount,
        enrollment_installments_quantity: selectedProduct.enrollment_installments_quantity,
        monthly_amount: selectedProduct.monthly_amount,
        installments_quantity: selectedProduct.installments_quantity,
        educational_stage_id:
          selectedProduct.educational_substage?.educational_stage_id || undefined,
        educational_substage_id: selectedProduct.educational_substage_id || null,
        product_program_id: selectedProduct.product_program_id || null,
        product_category_id: selectedProduct.product_category_id || null,
      }
    : defaultProduct

  return { defaultFormValues }
}

import { Student } from '../pages/Student'

import type { RouteDefinition } from '@/shared/core/router'

export const routes: RouteDefinition[] = [
  {
    key: 'STUDENT',
    path: '/alunos/:studentId',
    component: Student,
    exact: true,
    // TODO: rota não acessada em produção
    canAccess: () => false,
  },
]

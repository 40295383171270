import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { CampaignStudentExcludesRequest } from '@/modules/enrollment/services/enrollmentService/types'
import { useBulkInstallmentAvailability } from '@/modules/enrollment/hooks/useBulkInstallmentAvailability'
import {
  useFetchCampaignStudents,
  fetchCampaignStudentsQueryKey,
} from '@/modules/enrollment/hooks/queries/useCampaignQueries'
import { queryClient } from '@/shared/contexts/ReactQuery'

export type Params = {
  campaignId: uuid
}

export const useBulkInstallmentBtnState = ({ campaignId }: Params) => {
  const isAddInstallmentFlagEnabled = useUnleashFlag(
    UnleashFlags.MAT_474_ENABLE_CAMPAIGN_ADD_INSTALLMENTS
  )

  const campaignStudentsQuery = useFetchCampaignStudents(
    {
      campaignId,
      excludes: [
        CampaignStudentExcludesRequest.enrollmentCanceled,
        CampaignStudentExcludesRequest.enrollmentWithTuition,
      ],
      pagination: {
        page: 1,
        per_page: 10,
      },
    },
    {
      staleTime: 0,
    }
  )
  const campaignStudents = campaignStudentsQuery.data?.data?.students ?? []
  const hasCampaignStudents = campaignStudents.length > 0
  const handleUnavailabilityExpires = () => {
    queryClient.removeQueries({ queryKey: [fetchCampaignStudentsQueryKey] })
    campaignStudentsQuery.refetch()
  }
  const { isAvailable } = useBulkInstallmentAvailability(campaignId, handleUnavailabilityExpires)

  const isAddInstallmentBtnDisabled = (() => {
    if (!isAddInstallmentFlagEnabled) return true
    if (!hasCampaignStudents) return true
    return isAvailable === false
  })()

  const isAddInstallmentBtnLoading = campaignStudentsQuery.isLoading || isAvailable === false

  const addInstallmentBtnTooltip = (() => {
    if (!isAddInstallmentFlagEnabled)
      return 'Em breve, você poderá adicionar mensalidades à sua campanha.'
    if (!isAvailable) return 'Estamos processando as mensalidades.'
    if (!hasCampaignStudents) return 'Todos alunos desta campanha já têm mensalidades adicionadas.'
    return ''
  })()

  return {
    isAddInstallmentBtnDisabled,
    isAddInstallmentBtnLoading,
    addInstallmentBtnTooltip,
  }
}

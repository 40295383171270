import styled from 'styled-components'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  padding: 0 4rem;

  #page-header {
    margin: 0 -4rem 2.5rem;
  }
  gap: ${props => props.theme.gravity.spacing[4]};
  margin-top: ${props => props.theme.gravity.spacing[10]};
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: ${props => props.theme.gravity.spacing[12]};
  gap: ${props => props.theme.gravity.spacing[4]};
  width: 100%;
`

import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import {
  UseGuardianFiltersOptionsQueryProps,
  UseGuardianFiltersOptionsQueryResponse,
} from '../../services/types'
import { guardianService } from '../../services'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { DEFAULT_QUERY_STALE_TIME, DEFAULT_QUERY_RETRY_COUNT } from '../constants'

export const useGuardianFiltersOptionsQuery = (
  props: UseGuardianFiltersOptionsQueryProps,
  options?: UseQueryOptions<UseGuardianFiltersOptionsQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()
  const queryKey = ['guardian-details-filters', props]
  const service = guardianService(apiClient.privateApiV2)

  const { data: filterOptions, ...rest } = useQuery<
    UseGuardianFiltersOptionsQueryResponse,
    XMLHttpRequest
  >(
    queryKey,
    () => {
      return service.fetchGuardianFiltersOptions({
        guardianId: props.guardianId,
        schoolId: props.schoolId,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(props.guardianId),
      retry: DEFAULT_QUERY_RETRY_COUNT,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...options,
    }
  )

  return {
    filterOptions,
    isFiltersOptionsLoading: rest.isLoading,
    isFiltersOptionsError: rest.isError,
    isFiltersOptionsFetched: rest.isFetched,
    refetchFiltersOptions: rest.refetch,
  }
}

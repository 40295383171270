import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { GetSchoolEnrollmentCyclesResponse } from '../../../services/types'
import { schoolsEnrollmentCycleService } from '@/modules/enrollment/services'

export const useGetSchoolEnrollmentCycles = (
  schoolId: string,
  options?: UseQueryOptions<GetSchoolEnrollmentCyclesResponse>
) => {
  const { apiClient } = useApiClient()

  const service = schoolsEnrollmentCycleService(apiClient.privateApi)

  return useQuery<GetSchoolEnrollmentCyclesResponse>(
    ['school-enrollment-cycle', schoolId],
    () => service.getSchoolEnrollmentCycles(schoolId),
    options
  )
}

import { useQuery } from '@tanstack/react-query'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventIdentifierName } from '@monorepo/account-reports/models/enums/EventIdentifierName.enum'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { accountReportsService } from '@monorepo/account-reports/services/account-reports'

export const useGetTeachingMaterialIncomesReport = () => {
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { apiClient } = useApiClient()
  const service = accountReportsService(apiClient.getClients().privateApi)

  return useQuery({
    queryKey: ['teaching-material-incomes', school?.id],
    queryFn: async () => {
      const { data } = await service.fetchTeachingMaterialIncomesReport({ id: school?.id ?? '' })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },

    onSuccess: () => {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.TEACHING_MATERIAL_INCOME_PANEL,
          name: EventDispatcherEvents.VIEW_TEACHING_MATERIAL_REPORT,
          pageName: 'Relatório de venda de material didático',
          identifierName: EventIdentifierName.OPEN_TEACHING_MATERIAL_REPORT_PAGE,
        })
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
  })
}

import { ChangeEvent, DragEvent } from 'react'
import styled from 'styled-components'
import { mdiFileSyncOutline } from '@mdi/js'
import { Icon } from '@mdi/react'
import { useToast } from '@gravity/toast'
import { Text } from '@gravity/text'

type FileUploadProps = {
  file: File | null
  setFile: (file: File) => void
}

const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px dashed;
  border-color: #d0d0d0;
  border-radius: 4px;
  width: 684px;
  padding: 32px 0px;
`

export const FileUpload = ({ file, setFile }: FileUploadProps) => {
  const { toast } = useToast()

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const files = Array.from(event.dataTransfer.files)

    if (files.length === 0) return

    if (event.dataTransfer.files.length > 1) {
      return toast({
        title: 'Selecione apenas um arquivo',
        type: 'alert',
      })
    }

    setFile(files[0])
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleInputFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files === null || files.length === 0) {
      return
    }

    setFile(files[0])
  }

  return (
    <>
      <DropZone onDrop={handleDrop} onDragOver={handleDragOver}>
        <Icon path={mdiFileSyncOutline} color="#A0A0A0" size={2} style={{ paddingBottom: '8px' }} />

        <Text variant="body-1-regular">
          <Text>Arraste aqui ou </Text>
          <label>
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".xlsx"
              onChange={handleInputFileChange}
            />
            <Text style={{ color: '#444fd4', cursor: 'pointer' }}>busque seus arquivos</Text>
          </label>
        </Text>

        <Text as="div" variant="caption-regular">
          Formatos de documentos permitidos: PDF, DOCX, XLSX
        </Text>
      </DropZone>
      <div>{file?.name}</div>
    </>
  )
}

import { useMemo, useState } from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { Add as IconAdd, Remove as IconRemove } from '@material-ui/icons'
import { ActionDrawerHeader, Drawer, Typography } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { PayoutTransfersSection } from './components/PayoutTransfersSection'
import { PayoutTransfersSectionGroup } from './components/PayoutTransfersSectionGroup'
import { mdiCalendar } from '@mdi/js'
import Icon from '@mdi/react'
import { useApi } from '@/utils/hooks/useApi'
import { useQuery } from '@/shared/hooks/useQuery'
import { useSnackbar } from '@/shared/hooks'

import { formatDate } from '@/shared/utils/dateFormatters'
import { downloadFile } from '@/shared/utils/downloadFile'
import { formatCurrencyAmount } from '@/modules/report/utils/formatCurrencyAmount'
import { buildCategories } from './utils/buildCategories'
import { groupCategoriesIntoInstallmentAndOtherValues } from './utils/groupCategoriesIntoInstallmentAndOtherValues'
import { orderCategories } from './utils/orderCategories'

import theme from '@/shared/theme'

import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'

import type { BankTransfer } from '@/modules/report/models/report'
import type { FinancialOperations } from '@/modules/report/models/FinancialOperations'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { colors } from '@gravity/tokens'
import { TransferDates, Value } from './styles'
import { Button } from '@gravity/button'

type TuitionPayoutReportTransfersDrawerProps = {
  bankTransfers?: BankTransfer[]
  deductions?: FinancialOperations
  income?: FinancialOperations
  isOpen: boolean
  isPayoutOpen: boolean
  onClose: () => void
  payoutDueDate: Date
  payoutId: string
  schoolSlug: string
  transactionsInfo: { charges: number; students: number }
}

const DIVIDER_COLOR = theme.primitiveTokens.colors.gray[10]
const ICONS_COLOR = colors['colors-text-main-2']

/**
 * Component for tuition payout report details drawer
 *
 * @param props
 * @param props.schoolSlug School slug
 * @param props.payoutId Tuition payout ID
 * @param props.isPayoutOpen Indicates if payout is open
 * @param props.payoutDueDate Payout reference date
 * @param props.income Payout income
 * @param props.deductions Payout deductions
 * @param props.bankTransfers Payout bank transfers
 * @param props.transactionsInfo Payout secondary infos
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback function called on request close
 */
export const TuitionPayoutReportTransfersDrawer = ({
  schoolSlug,
  payoutId,
  isPayoutOpen,
  payoutDueDate,
  income = { items: {}, total: 0 },
  deductions = { items: {}, total: 0 },
  bankTransfers = [],
  transactionsInfo,
  isOpen,
  onClose,
}: TuitionPayoutReportTransfersDrawerProps) => {
  const { api } = useApi()
  const { query } = useQuery()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { setIsOpen } = useSnackbar()

  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  const handleDownloadReport = async (payoutId: string) => {
    const filename = `resumo-${schoolSlug}.pdf`

    try {
      setIsLoadingDownload(true)

      await downloadFile(filename, 'pdf', () =>
        api.report.fetchPayoutReportFile({
          id: payoutId,
          version: 2,
          extension: 'pdf',
        })
      )
    } catch {
      setIsOpen(true, {
        variation: 'error',
        description: 'Falha ao baixar arquivo. Tente novamente mais tarde.',
      })
    } finally {
      setIsLoadingDownload(false)

      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.PAYOUT_REPORT,
          name: EventDispatcherEvents.TUITION_PAYOUT_DOWNLOAD_SUMMARY,
          action: 'file_download',
          customProperties: {
            $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          },
        })
    }
  }

  const openedTransferSection = query.get('transfer_section')

  const payoutReferenceMonth = formatDate(payoutDueDate, 'MMMM')

  const [incomeInstallmentValues, incomeOtherValues] = useMemo(() => {
    const categories = buildCategories(income)

    const sortedCategories = orderCategories(categories)

    return groupCategoriesIntoInstallmentAndOtherValues(sortedCategories)
  }, [income])

  const [deductionsInstallmentValues, deductionsOtherValues] = useMemo(() => {
    const categories = buildCategories(deductions)

    const sortedCategories = orderCategories(categories)

    return groupCategoriesIntoInstallmentAndOtherValues(sortedCategories)
  }, [deductions])

  const formattedBankTransfers = bankTransfers.map(({ date, amount }) => {
    const value = amount < 0 ? 0 : amount

    return {
      date: formatDate(date, 'DD/MM/YYYY'),
      amount: formatCurrencyAmount(value),
    }
  })

  return (
    <Drawer open={isOpen}>
      <ActionDrawerHeader
        title="Detalhes do repasse"
        subtitle="Veja todos os valores que compõem o seu repasse"
        onClose={onClose}
      />

      <Box display="flex" flex="1" flexDirection="column" overflow="auto">
        <PayoutTransfersSection.Root
          icon={IconAdd}
          iconColor={ICONS_COLOR}
          label="Recebimentos"
          totalLabel="Valor total dos recebimentos"
          description="Soma das mensalidades e outros valores positivos no mês."
          totalAmount={income?.total ?? 0}
          startOpened={openedTransferSection === 'income'}
          isEmpty={incomeInstallmentValues.length === 0 && incomeOtherValues.length === 0}
          emptySectionMessage={`Em ${payoutReferenceMonth} seu repasse não possui nenhum valor de recebimento.`}
        >
          <PayoutTransfersSectionGroup
            categoryTitle="Valores referentes a parcelas de mensalidade"
            categories={incomeInstallmentValues}
            description={`${transactionsInfo.students} alunos | ${transactionsInfo.charges} cobranças`}
          />

          <PayoutTransfersSectionGroup
            categoryTitle="Outros valores"
            categoryTooltip="Valores que impactam seu repasse, mas não estão vinculados à contratos de mensalidades."
            categories={incomeOtherValues}
          />
        </PayoutTransfersSection.Root>

        <PayoutTransfersSection.Root
          icon={IconRemove}
          iconColor={ICONS_COLOR}
          label="Descontos"
          totalLabel="Valor total dos descontos"
          description="Valores referentes a taxa isaac, ajustes feitos ao longo do mês e serviços adicionais."
          totalAmount={deductions?.total ?? 0}
          startOpened={openedTransferSection === 'deductions'}
          isEmpty={deductionsInstallmentValues.length === 0 && deductionsOtherValues.length === 0}
          emptySectionMessage={`Em ${payoutReferenceMonth} seu repasse não possui nenhum valor de desconto.`}
        >
          <PayoutTransfersSectionGroup
            categoryTitle="Valores referentes a parcelas de mensalidade"
            categories={deductionsInstallmentValues}
          />

          <PayoutTransfersSectionGroup
            categoryTitle="Outros valores"
            categoryTooltip="Valores que impactam seu repasse, mas não estão vinculados à contratos de mensalidades."
            categories={deductionsOtherValues}
          />
        </PayoutTransfersSection.Root>

        {formattedBankTransfers.length > 0 && (
          <Box p="2rem 1.5rem">
            <Box display="flex" alignItems="center">
              <Icon path={mdiCalendar} size={1} />
              <Typography variation="subtitleDesktopLarge">Transferências programadas</Typography>
            </Box>

            <TransferDates>
              {formattedBankTransfers.map(({ date, amount }) => (
                <Box key={date} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variation="bodyLarge" color="secondary">
                    {date}
                  </Typography>

                  <Value>{amount}</Value>
                </Box>
              ))}
            </TransferDates>
          </Box>
        )}
      </Box>

      <Box p="2rem 1rem" borderTop={`1px solid ${DIVIDER_COLOR}`}>
        <Tooltip
          title={
            isPayoutOpen
              ? 'Disponível apenas para repasses fechados'
              : 'Baixar o arquivo .pdf do período'
          }
        >
          <span>
            <Button
              onClick={() => handleDownloadReport(payoutId)}
              loading={isLoadingDownload}
              disabled={isPayoutOpen}
              fullWidth
            >
              Baixar detalhamento
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Drawer>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  padding: ${({ theme }) => theme.gravity.spacing[6]};
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.gravity.colors['colors-background-neutral-1']};
`

export const CloseDrawer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const HeaderContainer = styled.div`
  padding: ${({ theme }) => theme.gravity.spacing[6]} 0;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
`

export const StickyBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.gravity.spacing[6]};
  padding-bottom: ${({ theme }) => theme.gravity.spacing[6]};

  align-items: center;
  position: sticky;
  bottom: 0;

  background-color: ${({ theme }) => theme.gravity.colors['colors-background-neutral-1']};
`

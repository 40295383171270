import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { UnleashFlags, useSnackbar, useUnleashFlag } from '@/shared/hooks'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { accountReportsService } from '../../services/account-reports'
import { DownloadPaymentStatusPeriod } from '../../services/account-reports/types'

import { downloadFile } from '@/shared/utils'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { ReportTypes } from '../../models/enums/ReportTypes.enum'

export const useDownloadPaymentReport = () => {
  const { apiClient } = useApiClient()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { school } = useSelectedSchool()
  const { setMessage, setIsOpen } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const isPaymentStatusReportByBFFEnabled = useUnleashFlag(
    UnleashFlags.PE_41_ENABLE_PAYMENT_STATUS_REPORT_BY_BFF
  )
  const fetcher = isPaymentStatusReportByBFFEnabled
    ? apiClient.getClients().bffApi
    : apiClient.privateApi

  const serviceAccountReports = accountReportsService(fetcher)

  const sendEvent = (isDownloadSuccessful: boolean) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.DOWNLOAD_FILE,
        scope: EventDispatcherEventScopes.REPORTS,
        action: 'file_download',
        customProperties: {
          $report: ReportTypes.PAYMENT,
          $is_download_successful: isDownloadSuccessful,
        },
      })
  }

  const downloadPaymentReport = async (period: DownloadPaymentStatusPeriod) => {
    setIsLoading(true)

    let isDownloadSuccessful = true

    try {
      const report = async () => {
        const downloadPaymentStatusReport = isPaymentStatusReportByBFFEnabled
          ? serviceAccountReports.downloadPaymentStatusReportByBff
          : serviceAccountReports.downloadPaymentStatusReportByApi

        const response = await downloadPaymentStatusReport({
          period,
          schoolId: school?.id ?? '',
          schoolName: school?.name,
          schoolSlug: school?.slug,
        })
        return response
      }
      await downloadFile('relatório-de-cobranças', 'xlsx', report)
    } catch (error) {
      isDownloadSuccessful = false
      setMessage(
        'Erro ao baixar relatório. Por favor tente novamente mais tarde ou entre em contato com o nosso suporte.'
      )
      setIsOpen(true, { variation: 'error' })
    } finally {
      setIsLoading(false)
      sendEvent(isDownloadSuccessful)
    }
  }

  return {
    downloadPaymentReport,
    isLoading,
  }
}

import type { RouteDefinition } from '@/shared/core/router'
import { UploadPage } from './pages/UploadPage'
import { useJWT } from '@/shared/hooks'
import { ValidatePage } from './pages/ValidatePage'

export const routes: RouteDefinition[] = [
  {
    key: 'ONBOARDING',
    path: '/migrar-dados',
    component: UploadPage,
    exact: true,
    canAccess: () => {
      const { isBackofficeUser } = useJWT()

      return isBackofficeUser
    },
  },
  {
    key: 'ONBOARDING_VALIDATE',
    path: '/migrar-dados/:batchID/validar',
    component: ValidatePage,
    exact: true,
    canAccess: () => {
      const { isBackofficeUser } = useJWT()

      return isBackofficeUser
    },
  },
]

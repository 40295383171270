import styled from 'styled-components'
import { spacing } from '@gravity/tokens'

export const SelectYearWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.primitiveTokens.spacing[2]};

  button {
    width: 88px;
    min-width: 88px;
    height: 40px;
    border: 2px solid ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[30]};
    border-radius 8px;
  }
`

export const SelectLabelWrapper = styled.div`
  span {
    color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
    font-weight: 500;
  }
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  gap: ${spacing[8]};
`

export const ActionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.primitiveTokens.spacing[9]};
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: ${({ theme }) => theme.primitiveTokens.spacing[6]};
`

export const ActionButtonsWrapper = styled.div`
  display: flex;
  order: 2;
  align-items: center;
  gap: ${spacing[2]};
`

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const SecondaryButtons = styled.div`
  display: flex;
  flex-direction: row;
`
export const FilterAndSecondary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

/**
 * Converts a file size in bytes to a human-readable string format (e.g., "2.5 MB")
 * @param bytes - The file size in bytes
 * @returns A formatted string representing the file size with appropriate unit
 */

export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const BYTES_PER_UNIT = 1024
  const SIZE_UNITS = ['Bytes', 'KB', 'MB', 'GB']

  const unitIndex = Math.floor(Math.log(bytes) / Math.log(BYTES_PER_UNIT))

  const sizeInUnit = bytes / Math.pow(BYTES_PER_UNIT, unitIndex)

  const formattedSize = parseFloat(sizeInUnit.toFixed(2))

  return `${formattedSize} ${SIZE_UNITS[unitIndex]}`
}

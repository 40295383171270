import { hotjar } from 'react-hotjar'
import envConfig from '@/config'

export enum HotjarEvents {
  ACCESS_ENROLLMENT_FINANCIAL_VIEW = 'access-enrollment-financial-view',
  ACCESS_ENROLLMENT_PERFORMANCE = 'access-enrollment-performance',
  ADD_CONTRACT = 'add-contract',
  ADD_CONTRACT_CHECKOUT = 'add-contract-checkout',
  ADD_INSTALLMENT_DISCOUNT = 'add-installment-discount',
  ADD_PRODUCT = 'add-product',
  AGGLUTINATE_INSTALLMENTS = 'agglutinate-installments',
  CAMPAIGN_ABANDON_ALERT = 'campaign-abandon-alert',
  CAMPAIGN_ABANDON_ALERT_CONFIRMATION_BUTTON_CLICKED = 'campaign-abandon-alert-confirmation-button-clicked',
  CAMPAIGN_CHANGE_PRODUCT_ALERT = 'campaign-change-product-alert',
  CAMPAIGN_CREATED = 'matriculas-campaign-created',
  CAMPAIGN_DEFINE_PAYMENT_PLAN_LANDED = 'campaign-define-payment-plan-landed',
  CAMPAIGN_ERROR_WHILE_PROCEEDING = 'campaign_error-while-proceeding',
  CAMPAIGN_NEW_CAMPAIGN_CLICKED = 'new-campaign-clicked',
  CAMPAIGN_NO_PRODUCT_SELECTED_ALERT = 'campaign-no-product-selected-alert',
  CAMPAIGN_NO_STUDENT_SELECTED_ALERT = 'campaign-no-student-selected-alert',
  CAMPAIGN_PROCESSING_LANDED = 'campaign-processing-landed',
  CAMPAIGN_REMOVE_STUDENT_ALERT = 'campaign-remove-student-alert',
  CAMPAIGN_REVIEW_AND_SAVE_LANDED = 'campaign-review-and-save-landed',
  CAMPAIGN_SELECT_NEXT_PRODUCT_LANDED = 'campaign-select-next-product-landed',
  CAMPAIGN_SELECT_STUDENTS_LANDED = 'campaign-select-students-landed',
  CAMPAIGN_TERMS_AND_CONDITIONS_OPENED = 'campaign-terms-and-conditions-opened',
  CANCEL_CONTRACT = 'cancel-contract',
  CHANGE_DATE = 'change-date',
  CHANGE_ENROLLMENT_DATE = 'change-enrollment-date',
  CHANGE_PAYOUT_DATE = 'change-payout-date',
  CHANGE_TABLE_VIEW = 'change-table-view',
  CHECKOUT = 'checkout',
  CONTRACT_DETAILS = 'contract-details',
  EDIT_CONTRACT_DISCOUNTS = 'edit-contract-discounts',
  EDIT_CONTRACT_DUE_DAY = 'edit-contract-due-day',
  EDIT_CONTRACT_PAGE = 'edit-contract-page',
  EDIT_ENROLLMENT_DUEDATE = 'edit-enrollment-duedate',
  EDIT_GUARDIAN = 'edit-guardian',
  EDIT_MANUAL_LIQUIDATION = 'edit-manual-liquidation',
  EFFECT_CONTRACT_BUTTON_CLICKED = 'effect-contract-button-clicked',
  EFFECT_CONTRACT_SUCCESS_MESSAGE = 'effect-contract-success-message',
  EFFECT_CONTRACT_WARNING_MESSAGE = 'effect-contract-warning-message',
  GENERATE_STATEMENT = 'generate-statement',
  GENERATE_STATEMENT_CHECKOUT = 'generate-statement-checkout',
  MANUAL_LIQUIDATION = 'manual-liquidation',
  OPEN_TABLE_FILTER = 'open-table-filter',
  ORDER_TABLE = 'order-table',
  PRINT_SLIP = 'print-slip',
  RENEGOTIATE_INSTALLMENT = 'renegotiate-installment',
  SCORE_SEARCH = 'matriculas-score-search',
  SEARCH_BY_STUDENT = 'search-by-student',
  SEE_DETAILS = 'see-details',
  SEE_ENROLLMENT_DETAILS = 'see-enrollment-details',
  SEE_PAYOUT_DETAILS = 'see-payout-details',
  SEE_STUDENT_PAYOUT_DETAILS = 'see-student-payout-details',
  SEE_SUMMARY = 'see-summary',
}

type hotjarPages =
  | `/contratos/${string}/checkout`
  | '/contratos/details/checkout'
  | `/contratos/${string}/campaign`

export enum HotjarPageNames {
  CONTRACTS_CHECKOUT,
  CONTRACTS_DETAILS_CHECKOUT,
  CONTRACTS_CAMPAIGN,
}

export const hotjarPageFactory = (pageName: HotjarPageNames, referenceYear = ''): hotjarPages => {
  const hotjarPages: { [key in HotjarPageNames]: hotjarPages } = {
    [HotjarPageNames.CONTRACTS_CHECKOUT]: `/contratos/${referenceYear}/checkout`,
    [HotjarPageNames.CONTRACTS_DETAILS_CHECKOUT]: `/contratos/details/checkout`,
    [HotjarPageNames.CONTRACTS_CAMPAIGN]: `/contratos/${referenceYear}/campaign`,
  }

  return hotjarPages[pageName]
}

const { HOTJAR_ID, HOTJAR_SNIPPET_VERSION } = envConfig
const isHotjarEnabled = HOTJAR_ID && HOTJAR_SNIPPET_VERSION

export const useHotjar = () => {
  const sendHotjarEvent = (type: HotjarEvents) => isHotjarEnabled && hotjar.event(type)

  const setHotjarPage = (type: hotjarPages) => isHotjarEnabled && hotjar.stateChange(type)

  return { sendHotjarEvent, setHotjarPage }
}

import { useState, useEffect } from 'react'
import BoxFooter from '../../BoxFooter/BoxFooter'
import type { PaidDateFieldProps } from '../types'
import * as S from '../styles'
import { DatePicker } from '@olaisaac/design-system'
import { useSendManualLiquidationEvent } from '../../../hooks/manualLiquidation/useSendManualLiquidationEvents'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import dayjs, { Dayjs } from 'dayjs'

import { makeIsDayAfterDate, makeIsDayBeforeDate } from '@/shared/utils'
import { UnleashFlags, useUnleashFlag as unleashFlag } from 'src/shared/hooks/useUnleashFlag'
import useLiquidationInfo from '../../../hooks/manualLiquidation/useLiquidationInfo'
import { getIdFromUrn } from '../../../helper'

const PaidDateField = ({
  onSave,
  onCancel,
  value,
  maxDate,
  minDate,
  contractCreationDate,
  isEdition,
  installment,
}: PaidDateFieldProps) => {
  const receivableId = getIdFromUrn(installment?.urn)
  const [selectedValue, setSelectedValue] = useState(value)
  const [errorMessage, setErrorMessage] = useState('')

  const sendManualLiquidationEvent = useSendManualLiquidationEvent()

  const isValidatePaidDateBefore7Days = unleashFlag(
    UnleashFlags.PAYMENTS_PEX_273_ENABLE_VALIDATE_PAID_DATE_MIN_7_DAYS_BEFORE
  )

  const { isLoading } = useLiquidationInfo({
    installment,
    paidDate: selectedValue,
    isSimulateRequest: true,
  })

  const getErrorMessage = (date: Dayjs) => {
    if (makeIsDayBeforeDate(dayjs(minDate).utc())(date)) {
      if (isValidatePaidDateBefore7Days) {
        return isEdition
          ? 'Você deve selecionar uma data de até 7 dias anteriores à última data de recebimento.'
          : 'Você deve selecionar uma data de até 7 dias anteriores ao dia de hoje.'
      }
      return `Data não pode ser menor que ${minDate.format('DD/MM/YYYY')}.`
    }
    if (makeIsDayAfterDate(dayjs(maxDate).utc())(date)) {
      return isEdition
        ? 'Data não pode ser depois da última data de recebimento'
        : 'Data não pode ser futura.'
    }
    return ''
  }

  useEffect(() => {
    setErrorMessage(getErrorMessage(selectedValue))

    const isSelectedDateBeforeContractCreation = makeIsDayBeforeDate(
      dayjs(contractCreationDate).utc()
    )(selectedValue)

    if (!isValidatePaidDateBefore7Days || isSelectedDateBeforeContractCreation) return

    if (makeIsDayBeforeDate(dayjs(minDate).utc())(selectedValue)) {
      sendManualLiquidationEvent(
        EventDispatcherEvents.WARNING_VIEWED,
        EventDispatcherEntities.MANUAL_LIQUIDATION_PAST_DATE_WARNING,
        null,
        {
          contract_id: installment.contracts[0].id,
          name: 'Alerta data retroativa',
          selected_date: selectedValue.toISOString(),
          receivable_id: receivableId,
        }
      )
    }
  }, [selectedValue])

  return (
    <S.StyledBox>
      <S.StyledContent>
        <DatePicker
          style={{ maxWidth: '255px' }}
          value={selectedValue}
          minDate={contractCreationDate}
          disableFuture
          label=""
          onChange={(date: Dayjs) => setSelectedValue(date || null)}
          data-testid="input-paid-date"
          helperText={errorMessage}
          error={!!errorMessage}
        />
      </S.StyledContent>
      <BoxFooter
        isValid={!isLoading && errorMessage === ''}
        onCancel={onCancel}
        onSave={() => onSave(selectedValue)}
      />
    </S.StyledBox>
  )
}

export default PaidDateField

import { AxiosInstance } from 'axios'
import type {
  DownloadPaymentStatusRequest,
  GetEmbedReportRequestDTO,
  GetEmbedReportResponseDTO,
} from './types'
import type {
  APIResponse,
  PresignedURL,
  RequestPresignedURL,
  TicketsSummaryList,
} from '@/shared/interfaces'

export const accountReportsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for download payment status report
     *
     * @param data.schoolId School id
     * @param data.period Period of the report
     *
     * @returns A blob with the report to be downloaded
     */
    downloadPaymentStatusReportByApi: async ({
      schoolId,
      period,
    }: DownloadPaymentStatusRequest): Promise<Blob> => {
      const { data } = await fetcher.get<Blob>('school/report/payment', {
        params: {
          school_id: schoolId,
          period,
        },
        responseType: 'blob',
      })

      return data
    },
    /**
     * Function responsible for download payment status report through bff
     *
     * @param data.schoolId School id
     * @param data.schoolName School name
     * @param data.schoolSlug School slug
     * @param data.period Period of the report
     *
     * @returns A blob with the report to be downloaded
     */
    downloadPaymentStatusReportByBff: async ({
      schoolId,
      period,
      schoolName,
      schoolSlug,
    }: DownloadPaymentStatusRequest): Promise<Blob> => {
      const { data } = await fetcher.get<Blob>(`report/payment`, {
        params: {
          schoolId,
          schoolName,
          schoolSlug,
          period,
        },
        responseType: 'blob',
      })

      return data
    },
    /**
     * Function responsible for fetching the enrollment campaign report URL
     *
     * @param schoolId School ID
     *
     * @returns Embed report URL
     */
    fetchEnrollmentCampaignReport: async ({ id }: GetEmbedReportRequestDTO) => {
      const { data } = await fetcher.get<APIResponse<GetEmbedReportResponseDTO>>(
        `/report/enrollment-campaign/${id}`
      )

      return data
    },
    /**
     * Function responsible for fetch all available payouts summary
     *
     * @param schoolId School ID
     *
     * @returns List of available payouts summary
     */
    fetchFidcReport: async ({ id }: GetEmbedReportRequestDTO) => {
      const { data } = await fetcher.get<APIResponse<GetEmbedReportResponseDTO>>(
        `/report/contracts-confirmation/${id}`
      )

      return data
    },
    /**
     * Function responsible for fetching the family regularization report URL
     *
     * @param schoolId School ID
     *
     * @returns Embed report URL
     */
    fetchFamilyRegularizationReport: async ({ id }: GetEmbedReportRequestDTO) => {
      const { data } = await fetcher.get<APIResponse<GetEmbedReportResponseDTO>>(
        `/report/family-regularization/${id}`
      )

      return data
    },
    /**
     * Function responsible for fetching isaac pay incomes report URL
     *
     * @param id School ID
     *
     * @returns Embed report URL
     */
    fetchIsaacPayIncomesReport: async ({ id }: GetEmbedReportRequestDTO) => {
      const { data } = await fetcher.get<APIResponse<GetEmbedReportResponseDTO>>(
        `/report/isaac-pay-incomes/${id}`
      )

      return data
    },
    /**
     * Function responsible for fetching teaching material incomes report URL
     *
     * @param id School ID
     *
     * @returns Embed report URL
     */
    fetchTeachingMaterialIncomesReport: async ({ id }: GetEmbedReportRequestDTO) => {
      const { data } = await fetcher.get<APIResponse<GetEmbedReportResponseDTO>>(
        `/report/isaac-pay-teaching-material/${id}`
      )

      return data
    },
    fetchTicketsSummaryList: async (schoolID: uuid) => {
      return (
        await fetcher.get<APIResponse<TicketsSummaryList>>(
          `/tickets-summary/${schoolID}?school_id=${schoolID}`
        )
      )?.data
    },
    downloadTicketsSummary: async (schoolID: uuid, reference: string) => {
      return await fetcher.get<Blob>(
        `/tickets-summary/files/${schoolID}.${reference}.pdf?school_id=${schoolID}`,
        {
          responseType: 'blob',
        }
      )
    },
    getPresignedUrl: async (body: RequestPresignedURL) =>
      (await fetcher.post<APIResponse<PresignedURL>>(`/school/signed-url`, body))?.data.data,
    downloadFile: (fileName: string) => {
      return fetcher.get<Blob>(`${fileName}`, {
        responseType: 'blob',
        withCredentials: false,
      })
    },
  }
}

import { useState } from 'react'

type UseCancelContractModalInitialValues = {
  contractId?: string | null
  installmentsIdsToPreCancel?: uuid[]
  isOpen?: boolean
}

export type UseCancelContractModalReturn = ReturnType<typeof useCancelContractModal>

export const useCancelContractModal = (initialValues?: UseCancelContractModalInitialValues) => {
  const [isOpen, setIsOpen] = useState(initialValues?.isOpen ?? false)
  const [contractId, setContractId] = useState(initialValues?.contractId ?? null)
  const [installmentsIdsToPreCancel, setInstallmentsIdsToPreCancel] = useState(
    initialValues?.installmentsIdsToPreCancel ?? []
  )

  const close = () => setIsOpen(false)

  const open = (contractId?: string) => {
    if (contractId) {
      setContractId(contractId)
    }

    setIsOpen(true)
  }

  return {
    isOpen,
    close,
    open,
    contractId,
    setContractId,
    installmentsIdsToPreCancel,
    setInstallmentsIdsToPreCancel,
  }
}

import { useHistory } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiMenu } from '@mdi/js'
import { Text } from '@gravity/text'
import { IconButton } from '@gravity/icon-button'
import { colors } from '@gravity/tokens'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useApi } from '@/utils/hooks/useApi'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { client } from '@/shared/integrations/sorting-hat'

import { ContextDropdown } from '@/shared/components/ContextDropdown'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import { Container, Content } from './styles'

import env from '@/config'

type UserMenuProps = {
  subtitle: string
  title: string
  variation?: 'primary' | 'secondary'
}

/**
 * TODO: Move to a environment var
 */
const FAQ_URL = 'https://centraldeajuda.olaisaac.io'

/**
 * TODO: Move to a environment var or constant
 */
const TERMS_FILE_URL =
  'https://storage.googleapis.com/plataforma-isaac-bucket/documents/termos_condicoes_isaac.pdf'

const IS_BACKOFFICE_REALM = env.SORTING_HAT.REALM === 'backoffice'

/**
 * User options dropdown component for header
 *
 * @param props.title Component title
 * @param props.subtitle Component subtitle
 */
export const UserMenu = ({ title, subtitle }: UserMenuProps) => {
  /**
   * TODO: Use apiClient
   */
  const { api } = useApi()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()

  const sendEvent = (option: 'faq' | 'terms' | 'logout') => {
    const events: Record<'faq' | 'terms' | 'logout', EventDispatcherEvents> = {
      faq: EventDispatcherEvents.FAQ_ACCESS,
      terms: EventDispatcherEvents.TERMS_CONDITIONS_PDF_ACCESS,
      logout: EventDispatcherEvents.LOGOUT_CLICK,
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.HEADER_MENU,
        name: events[option],
        action: 'click',
      })
    }
  }

  const handleContextDropdownAction = (action: 'terms' | 'faq') => {
    sendEvent(action)

    const ACTION_URLS = {
      terms: TERMS_FILE_URL,
      faq: FAQ_URL,
    }

    window.open(ACTION_URLS[action], '_blank')
  }

  const handleLogoutClick = async () => {
    sendEvent('logout')

    try {
      await api.auth.logout()
      client.logout()
    } catch (err) {
      client.logout()
    }
  }

  const lowerCasedTitle = title.toLowerCase()

  const menuOptions = [
    {
      label: 'Central de Ajuda',
      name: 'faq',
      onClick: () => handleContextDropdownAction('faq'),
    },
    {
      label: 'Termos de uso',
      name: 'terms',
      onClick: () => handleContextDropdownAction('terms'),
    },
    { label: 'Sair', name: 'logout', onClick: handleLogoutClick },
  ]

  if (!IS_BACKOFFICE_REALM) {
    menuOptions.unshift({
      label: 'Gestão de usuários',
      name: 'access-management',
      onClick: () => {
        push(`/${schoolSlug}/acessos`)

        if (isInitialized) {
          eventDispatcherClient.sendEvent({
            scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
            name: EventDispatcherEvents.BUTTON_CLICKED,
            action: 'click',
            customProperties: {
              $button_name: 'gestao_de_acessos',
            },
          })
        }
      },
    })
  }

  return (
    <Container>
      <Content>
        <Text variant="body-2-medium" as="p" style={{ textTransform: 'capitalize' }}>
          {lowerCasedTitle}
        </Text>

        <Text variant="caption-regular" as="p" style={{ color: colors['colors-text-main-3'] }}>
          {subtitle}
        </Text>
      </Content>

      <div>
        <ContextDropdown
          button={
            <IconButton className="header-menu">
              <Icon path={mdiMenu} />
            </IconButton>
          }
          options={menuOptions}
        />
      </div>
    </Container>
  )
}

import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { guardianService } from '@/modules/guardians/services'
import { GuardianNegotiationQueryResponse } from '@/modules/guardians/services/types'

export const useGuardianNegotiationQuery = (
  props: { id?: uuid },
  options?: UseQueryOptions<GuardianNegotiationQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()

  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardian-negotiation', props.id]

  const {
    data: guardianNegotiation,
    refetch: refetchGuardianNegotiation,
    isLoading,
    isRefetching,
    isError,
    isSuccess,
  } = useQuery<GuardianNegotiationQueryResponse, XMLHttpRequest>(
    queryKey,
    () => service.fetchGuardianNegotiation(props?.id),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      retry: false,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: false,
      ...options,
    }
  )

  return {
    guardianNegotiation,
    refetchGuardianNegotiation,
    isLoading,
    isRefetching,
    isError,
    isSuccess,
  }
}

import { useApi } from '@/utils/hooks/useApi'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  EnrollmentCycleRequest,
  EnrollmentCycleResponse,
} from '@/modules/enrollment/services/enrollmentService/types'

import { ENROLLMENT_CYCLE_QUERY_KEY } from '../../../constants/enrollment'

export const useEnrollmentCycle = (
  props: EnrollmentCycleRequest,
  options?: UseQueryOptions<EnrollmentCycleResponse, XMLHttpRequest>
) => {
  const { api } = useApi()
  const getEnrollments = api.enrollment.fetchEnrollmentCycle

  return useQuery<EnrollmentCycleResponse, XMLHttpRequest>(
    [ENROLLMENT_CYCLE_QUERY_KEY, props],
    () => {
      return getEnrollments(props)
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}

import { useApiClient } from '@/shared/hooks/useApiClient'
import { useMutation } from '@tanstack/react-query'
import { CreateCreditSimulationRequest } from '@/shared/services/credit/types'
import { creditService } from '@/shared/services/credit'

export const createCreditSimulationQueryKey = 'create-credit-simulation'

export const useCreateCreditSimulation = () => {
  const { apiClient } = useApiClient()
  const service = creditService(apiClient.getClients().bffApi)

  return useMutation(
    [createCreditSimulationQueryKey],
    async (body: CreateCreditSimulationRequest) => service.createCreditSimulation(body),
    { retry: 2, useErrorBoundary: true }
  )
}

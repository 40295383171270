import { NotificationError } from '../components/NotificationError'
import { OverviewSection } from '../components/OverviewSection'

import { useQuery } from '@/shared/hooks/useQuery'
import { useLoadAllNotifications, useNotificationsStatus } from '../hooks'
import { useLayout } from '@/shared/hooks/useLayout'

import {
  NOTIFICATION_INITIAL_PAGE,
  NOTIFICATION_MIN_COUNT,
  NOTIFICATION_STATUS,
} from '@monorepo/notification/constants'
import { NotificationsSection } from '../components/NotificationsSection'
import { Container } from './styles'

export const NotificationsBoard = () => {
  const { query } = useQuery()

  const category = query.get('category') || ''
  const status = query.get('status') || ''
  const page = Number(query.get('page')) || NOTIFICATION_INITIAL_PAGE
  const count = Number(query.get('count')) || NOTIFICATION_MIN_COUNT

  const { data: notificationsData, isLoading, isError, refetch } = useLoadAllNotifications({
    count,
    page,
    category,
  })
  const {
    notifications,
    notificationsCount,
    unreadNotifications,
    unreadNotificationsCount,
  } = notificationsData

  const { data, handleNotificationRead } = useNotificationsStatus({
    notifications,
    unreadNotifications,
  })

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Quadro de avisos' })

  const selectedNotifications =
    status === NOTIFICATION_STATUS.UNREAD ? data.unreadNotifications : data.notifications
  const selectedNotificationsCount =
    status === NOTIFICATION_STATUS.UNREAD ? unreadNotificationsCount : notificationsCount

  return (
    <Container>
      {isError ? (
        // TODO: remove material-ui components
        <div className="error">
          <NotificationError onRetry={refetch} />
        </div>
      ) : (
        <>
          <OverviewSection
            isLoading={isLoading}
            notifications={selectedNotifications ?? []}
            category={category}
          />

          <NotificationsSection
            isLoading={isLoading}
            itemsPerPage={count}
            notifications={selectedNotifications ?? []}
            page={page}
            totalItems={selectedNotificationsCount ?? 0}
            onNotificationRead={handleNotificationRead}
          />
        </>
      )}
    </Container>
  )
}

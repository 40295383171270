import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[6]};
  scroll-margin-top: 160px;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-height: ${props => props.theme.gravity.spacing[10]};
  }
`

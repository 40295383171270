import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  > div {
    display: flex;
    gap: ${props => props.theme.gravity.spacing[2]};
  }

  .action {
    margin-left: auto;
  }
`

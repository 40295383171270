import { useRouteMatch } from 'react-router-dom'
import { Text } from '@gravity/text'

import { ItemGuardianSearch } from '../ItemGuardianSearch'
import { ItemStudentSearch } from '../ItemStudentSearch'

import type {
  GuardianItem,
  SearchCategories,
  StudentItem,
} from '@/modules/school-home/services/school-home-search/types'
import type { StorageItemType } from '@/modules/school-home/types/StorageItem'
import { EventIdentifierName } from '@/modules/school-home/models/EventIdentifierName.enum'

import { Container, HeaderTitle } from './styles'

type Result = {
  guardians: GuardianItem[]
  students: StudentItem[]
}

type ResultSectionProps<T extends SearchCategories> = {
  handleAddItemInStorage: (
    item: StorageItemType,
    nameEvent?: string,
    identifierName?: string
  ) => void
  inputSearch: string
  isLoading: boolean
  results: Result[T] | undefined
  total: number
  type: T
}

const FILTER_NAME_LABEL: Record<SearchCategories, string> = {
  guardians: 'responsáveis',
  students: 'alunos',
}

export const ResultSection = <T extends SearchCategories>({
  inputSearch,
  isLoading,
  type,
  results = [],
  total,
  handleAddItemInStorage,
}: ResultSectionProps<T>) => {
  const { url } = useRouteMatch()

  const handleClickInItem = (data: StorageItemType) => {
    handleAddItemInStorage(data, 'resultado_da_busca', EventIdentifierName.CLICK_ON_SEARCH_RESULT)
  }

  return (
    <Container data-testid="result-search-section">
      {!isLoading && !!inputSearch && (
        <>
          <HeaderTitle data-testid="header-title">
            <Text variant="caption-regular" className="caption-search">
              Resultados de {FILTER_NAME_LABEL[type]}
            </Text>

            {total > 0 && (
              <Text variant="caption-regular" className="caption-search">
                {total} resultados, continue digitando para resultados mais precisos
              </Text>
            )}
          </HeaderTitle>

          {(results.length === 0 || !results) && (
            <Text variant="body-1-regular">Não há resultados que correspondem à sua busca</Text>
          )}

          {!!results && (
            <div>
              {type === 'guardians' &&
                (results as GuardianItem[]).map(guardian => (
                  <ItemGuardianSearch
                    id={guardian.id}
                    onClick={handleClickInItem}
                    type="search"
                    key={guardian.id}
                    name={guardian.name}
                    students={guardian.students}
                    url={url}
                    inputSearch={inputSearch}
                  />
                ))}

              {type === 'students' &&
                (results as StudentItem[]).map(student => (
                  <ItemStudentSearch
                    id={student.id}
                    onClick={handleClickInItem}
                    type="search"
                    key={student.id}
                    mainProduct={student.main_product ?? undefined}
                    name={student.name}
                    guardianName={student.guardian_name ?? undefined}
                    url={url}
                    inputSearch={inputSearch}
                  />
                ))}
            </div>
          )}
        </>
      )}
    </Container>
  )
}

import { Heading } from '@gravity/heading'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { Text } from '@gravity/text'

type LayoutTemplateProps = {
  children?: ReactNode
  description?: ReactNode
  footer?: ReactNode
  title?: ReactNode
}

const LayoutTemplateFooter = styled.footer`
  display: flex;
  padding: 28px 0px;
  justify-content: end;
  gap: 16px;
`

const LayoutTemplateContent = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 42px;
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-width: 100vw;
  padding-top: 124px;
`

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 896px;
  height: 100%;

  @media screen and (min-width: 1439px) {
    width: 1136px;
  }
`

export const LayoutTemplate = ({ children, description, footer, title }: LayoutTemplateProps) => {
  return (
    <PageWrapper>
      <PageContent>
        <Heading variant="heading-h3-medium" style={{ marginBottom: '8px' }}>
          {title}
        </Heading>
        <Text variant="body-1-regular">{description}</Text>
        <LayoutTemplateContent>{children}</LayoutTemplateContent>
        <LayoutTemplateFooter>{footer}</LayoutTemplateFooter>
      </PageContent>
    </PageWrapper>
  )
}

import { useQuery, useQueryClient, useMutation, useQueries } from '@tanstack/react-query'
import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { useApi } from '@/utils/hooks/useApi'
import { Guardian, GuardianStudentsResponse, GuardianStudentsType } from 'src/shared/interfaces'
import { EditGuardianFormType } from '../components/contract/EditRegistrationDrawer/useHelperEditRegistration'

export type updateGuardianStudentsPayload = {
  newGuardianStudents: EditGuardianFormType
  schoolID: string
}

export type useGuardianStudentsCommands = {
  updateGuardianStudents: (
    payload: updateGuardianStudentsPayload
  ) => Promise<GuardianStudentsResponse>
}

export type useGuardianStudentsQueries = {
  cleanGuardianStudentsCache: () => void
  guardianStudents: GuardianStudentsType
  isStudentsFetched: boolean
  isUpdateGuardianStudentsError: boolean
  isUpdateGuardianStudentsLoading: boolean
  isUpdateGuardianStudentsSuccess: boolean
  refetchGuardianStudents: () => void
}

export const useGuardianStudents = (
  guardianId?: string,
  studentsIds?: string[]
): useGuardianStudentsCommands & useGuardianStudentsQueries => {
  const { api } = useApi()
  const queryClient = useQueryClient()
  const queryGuardianKey = ['guardian', guardianId]

  const { data: guardian, refetch } = useQuery<Guardian>(
    queryGuardianKey,
    () => api.guardians.get(guardianId),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(guardianId),
      staleTime: 10 * 60 * 1000,
    }
  )

  const studentsQueries = useQueries({
    queries: studentsIds?.map(id => ({
      queryKey: ['student', id],
      queryFn: () => api.students.get(id),
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(id),
      staleTime: 10 * 60 * 1000,
    })),
  })

  const isStudentsFetched = studentsQueries?.every(res => res?.isFetched)

  const students = isStudentsFetched ? studentsQueries?.map(res => res.data) : []

  const guardianStudents = guardian && {
    guardian_id: guardian.id,
    guardian: guardian,
    students: students?.map(student => ({
      student_id: student.id,
      student_data: student,
    })),
  }

  const refetchGuardianStudents = () => {
    refetch()
    studentsQueries.forEach(res => res.refetch())
  }

  const {
    mutateAsync: updateGuardianStudents,
    isLoading: isUpdateGuardianStudentsLoading,
    isError: isUpdateGuardianStudentsError,
    isSuccess: isUpdateGuardianStudentsSuccess,
  } = useMutation(
    ({ newGuardianStudents, schoolID }: updateGuardianStudentsPayload) => {
      const guardianStudentsToUpdate: GuardianStudentsType = {
        guardian_id: newGuardianStudents.id,
        guardian: {
          tax_id: newGuardianStudents.tax_id,
          name: newGuardianStudents.name,
          email: newGuardianStudents.email,
          phone_number: newGuardianStudents.phone_number,
          address: newGuardianStudents.address,
        },
        students: newGuardianStudents?.students.map(student => ({
          student_id: student.id,
          student_data: {
            name: student.name,
          },
        })),
      }

      return api.guardianStudents.update(guardianStudentsToUpdate, schoolID)
    },
    {
      onSuccess: async updatedGuardian => {
        await queryClient.invalidateQueries(queryGuardianKey)
        return queryClient.setQueryData(queryGuardianKey, updatedGuardian)
      },
    }
  )

  const cleanGuardianStudentsCache = () => queryClient.invalidateQueries(queryGuardianKey)

  return {
    cleanGuardianStudentsCache,
    guardianStudents,
    isUpdateGuardianStudentsError,
    updateGuardianStudents,
    isUpdateGuardianStudentsLoading,
    isUpdateGuardianStudentsSuccess,
    isStudentsFetched,
    refetchGuardianStudents,
  }
}

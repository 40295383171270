import {
  GetAggregatedProvidersReportResponseDTO,
  PayoutDomain,
} from '@/escolas/services/report/types'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApi } from '@/utils/hooks/useApi'

type ExternalPaymentQueryProps = {
  endDate: string
  options?: UseQueryOptions<GetAggregatedProvidersReportResponseDTO>
  schoolId: string
  startDate: string
}

export const useIsaacPayAggregatedProvidersSummary = ({
  schoolId,
  endDate,
  startDate,
  options,
}: ExternalPaymentQueryProps) => {
  const { api } = useApi()

  return useQuery<GetAggregatedProvidersReportResponseDTO>(
    ['aggregated-providers-summary', schoolId, startDate, endDate, PayoutDomain.IsaacPay],
    async () => {
      const { data } = await api.report.fetchAggregatedProvidersReport({
        schoolId,
        startDate,
        endDate,
        payoutDomain: PayoutDomain.IsaacPay,
      })

      return data
    },
    options
  )
}

import { useIsCampaignEnabled } from '@/shared/hooks/useIsCampaignEnabled'
import { useSelectYear } from './useSelectYear'

export const useEnrollmentYear = () => {
  const { handleChangeSelectedYear, years, selectedYear: selectedCycle } = useSelectYear()

  const isCampaignEnabled = useIsCampaignEnabled()
  const selectedYear = selectedCycle
  const isCampaignButtonEnabled = isCampaignEnabled && selectedYear >= 2025

  return {
    selectedYear,
    selectedCycle,
    years,
    isCampaignButtonEnabled,
    handleChangeSelectedYear,
  }
}

import qs from 'qs'
import type { AxiosInstance } from 'axios'

import type { SearchRequest, SearchResponse } from './types'

export const schoolHomeSearchService = (fetcher: AxiosInstance) => {
  return {
    search: async ({ schoolId, search, type, limit }: SearchRequest): Promise<SearchResponse> => {
      const { data } = await fetcher.get<SearchResponse>('school-home/search', {
        params: {
          school_id: schoolId,
          search,
          type,
          limit,
        },
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'brackets', skipNulls: true }),
      })

      return data
    },
  }
}

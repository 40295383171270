import { Skeleton } from '@gravity/skeleton'

import { ItemLoading } from './styles'

export const ItemsLoading = () => {
  return (
    <>
      <ItemLoading data-testid="items-loading">
        <Skeleton variant="neutral" width={80} height={21} />
        <Skeleton variant="neutral" width={128} height={24} />
      </ItemLoading>

      <ItemLoading data-testid="items-loading">
        <Skeleton variant="neutral" width={80} height={21} />
        <Skeleton variant="neutral" width={128} height={24} />
      </ItemLoading>

      <ItemLoading data-testid="items-loading">
        <Skeleton variant="neutral" width={80} height={21} />
        <Skeleton variant="neutral" width={128} height={24} />
      </ItemLoading>
    </>
  )
}

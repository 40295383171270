import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const useIsPageEnabled = () => {
  const isScoreFFEnabled = useUnleashFlag(UnleashFlags.MAT_616_ENABLE_ISAAC_SCORE)

  const history = useHistory()

  const { schoolSlug } = useSelectedSchool()

  const redirect = () => {
    history.replace(`/${schoolSlug}/matriculas`)
  }

  useEffect(() => {
    if (!isScoreFFEnabled) {
      redirect()
    }
  }, [isScoreFFEnabled])
}

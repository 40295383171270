import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { onboardingAPI } from '../services/onboardingApi'
import { useApiClient } from '@/shared/hooks'
import { OnboardingBatch } from '../services/types/onboardingBatch'

type UseFetchOnboardingBatchesParams = {
  schoolId: uuid
}

type UseFetchOnboardingBatchesReturn = OnboardingBatch[]

export const useFetchOnboardingBatches = (
  { schoolId }: UseFetchOnboardingBatchesParams,
  options?: UseQueryOptions<UseFetchOnboardingBatchesReturn>
) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)
  return useQuery<UseFetchOnboardingBatchesReturn>(
    ['onboarding-batches', schoolId],
    async () => {
      const response = await api.fetchOnboardingBatches({ schoolId })

      return response.data
    },
    options
  )
}

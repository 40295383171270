import { useApi } from '@/utils/hooks/useApi'
import { useMutation } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'
import type { AddTuitionBodyRequest } from '@/modules/enrollment/services/enrollmentService/types'

type MutationProps = {
  body: AddTuitionBodyRequest
  params: {
    campaignID: uuid
  }
}
export const useAddTuitionToCampaignQuery = () => {
  const { api } = useApi()

  return useMutation({
    mutationFn: async ({ params, body }: MutationProps) => {
      return await api.enrollment.addTuitionToCampaign(params.campaignID, body)
    },
    onError: error => {
      Sentry.captureException(error)
    },
  })
}

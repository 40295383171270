import { validateCpf } from '@/shared/utils'
import dayjs from 'dayjs'
import { z } from 'zod'

export const EditStudentSchema = z
  .object({
    name: z.string().trim().min(1, { message: 'Informe o nome do aluno' }),
    birthDate: z
      .string()
      .refine(
        dateString => {
          return dateString !== ''
        },
        {
          message: 'Informe a data de nascimento',
        }
      )
      .refine(
        dateString => {
          return (
            !dayjs(dateString, 'DD/MM/YYYY').isSame(dayjs(), 'day') &&
            !dayjs(dateString, 'DD/MM/YYYY').isAfter(dayjs(), 'day') &&
            !dayjs(dateString, 'DD/MM/YYYY').isBefore(dayjs('1900-01-01'), 'year')
          )
        },
        {
          message: 'Informe uma data válida',
        }
      )
      .refine(
        dateString => {
          const date = dayjs(dateString, 'DD/MM/YYYY', true)
          return date.isValid()
        },
        {
          message: 'Informe uma data válida',
        }
      ),
    taxID: z.string().optional(),
    noTaxID: z.boolean(),
  })
  .refine(
    schema => {
      return schema.noTaxID || schema.taxID.length === 11
    },
    { message: 'Informe um CPF válido', path: ['taxID'] }
  )
  .refine(
    schema => {
      return schema.noTaxID || validateCpf(schema.taxID)
    },
    { message: 'Informe um CPF válido', path: ['taxID'] }
  )

export type EditStudentForm = z.infer<typeof EditStudentSchema>

import { useQuery } from '@tanstack/react-query'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { accountReportsService } from '@monorepo/account-reports/services/account-reports'

export const useGetEnrollmentReportCampaignReport = () => {
  const { school } = useSelectedSchool()
  const { apiClient } = useApiClient()

  const service = accountReportsService(apiClient.getClients().privateApi)

  return useQuery({
    queryKey: ['enrollment-campaign', school?.id],
    queryFn: async () => {
      const { data } = await service.fetchEnrollmentCampaignReport({ id: school?.id ?? '' })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
  })
}

import { OPTIONS_GROUP } from '@monorepo/access-management/constants/OPTIONS_GROUP'
import { REQUIRED_ROLE_FOR_GROUP_ASSIGN } from '@monorepo/access-management/constants/REQUIRED_ROLE_FOR_GROUP_ASSIGN'

import type { UserGroupNames } from '@/shared/integrations/sorting-hat/types'
import type { Roles } from '@/shared/contexts/Auth'

interface IProps {
  userRoles: Set<Roles>
}

export const getAvailableOptions = ({ userRoles }: IProps) => {
  return OPTIONS_GROUP.reduce((acc, option: { label: string; value: UserGroupNames }) => {
    const currentRole = REQUIRED_ROLE_FOR_GROUP_ASSIGN[option.value]

    if (userRoles.has(currentRole)) return [...acc, option]

    return acc
  }, [] as { label: string; value: UserGroupNames }[])
}

import { useState, createContext } from 'react'
import { useApi } from '@/utils/hooks/useApi'
import { Pos } from 'src/shared/interfaces'

type PosContext = {
  getList: (schoolId: uuid) => void
  isError: boolean
  isLoading: boolean
  listPos: Pos[]
}

export const PosContext = createContext<PosContext>(null)

export const PosProvider = props => {
  const { api } = useApi()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listPos, setListPos] = useState<Array<Pos>>([])
  const [isError, setIsError] = useState<boolean>(false)

  const getList = (schoolId: uuid) => {
    setIsLoading(true)
    api.checkout
      .listPos(schoolId)
      .then(data => {
        setListPos(data)
        setIsError(false)
      })
      .catch(() => setIsError(true))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <PosContext.Provider
      value={{
        getList,
        isLoading,
        listPos,
        isError,
      }}
    >
      {props.children}
    </PosContext.Provider>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'

import { studentsService } from '@monorepo/students/services/students'

import type {
  FetchStudentProfileRequest,
  FetchStudentProfileResponse,
} from '@monorepo/students/services/students/types'

export const useStudentProfile = (
  { studentId }: FetchStudentProfileRequest,
  options?: UseQueryOptions<FetchStudentProfileResponse>
) => {
  const { apiClient } = useApiClient()

  const service = studentsService(apiClient.getClients().bffApi)

  return useQuery<FetchStudentProfileResponse>(
    ['students', studentId],
    async () => {
      const data = await service.fetchStudentProfile({
        studentId,
      })

      return data
    },
    options
  )
}

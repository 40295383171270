import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { useQuery } from '@/shared/hooks/useQuery'
import { filterForm, filterSchema } from '../form/filtersSchema'
import { Filters } from '../components/NotificationsFilters'

import { NOTIFICATION_INITIAL_PAGE, NOTIFICATION_MIN_COUNT } from '@monorepo/notification/constants'
import { categoryOptions, statusOptions } from '../constants/form'

import type { TableFilters } from '../types'

export const useNotificationFilters = () => {
  const [isFilterDialogOpen, toggleIsFilterDialogOpen] = useState(false)
  const { query, setOnQueryParam } = useQuery()

  const category = query.get('category') || ''
  const status = query.get('status') || ''

  const form = useForm<filterForm>({
    mode: 'all',
    defaultValues: {},
    resolver: zodResolver(filterSchema),
  })

  const { control, setValue, getValues } = form

  const categoryForm = getValues()?.category ?? []
  const statusForm = getValues()?.status ?? []

  const filtersGroup = [
    {
      id: 0,
      title: 'Categoria dos avisos',
      component: <Filters name="category" control={control} />,
    },
    {
      id: 1,
      title: 'Status dos avisos',
      component: <Filters name="status" control={control} />,
    },
  ]

  const handleSetDefaultValues = () => {
    setValue(
      'status',
      statusOptions.map(item => ({
        ...item,
        selected: item.value === status,
      }))
    )

    setValue(
      'category',
      categoryOptions.map(item => ({
        ...item,
        selected: item.value === category,
      }))
    )
  }

  useEffect(() => {
    handleSetDefaultValues()
  }, [status, category])

  const handleOpenFilterDialog = () => {
    handleSetDefaultValues()
    toggleIsFilterDialogOpen(true)
  }

  const handleCloseFilterDialog = () => {
    toggleIsFilterDialogOpen(false)
  }

  const handleRemoveFilter = ({ value }: TableFilters) => {
    const categoryValuesForm = categoryForm.map(item => ({
      ...item,
      selected: item.value === value,
    }))

    const isItemSelectedCategory = categoryValuesForm.some(item => item.selected)

    if (isItemSelectedCategory) {
      setOnQueryParam('', 'category')
      setValue('category', categoryValuesForm)
    }

    const statusValuesForm = statusForm.map(item => ({
      ...item,
      selected: item.value === value,
    }))

    const isItemSelectedStatus = statusValuesForm.some(item => item.selected)

    if (isItemSelectedStatus) {
      setOnQueryParam('', 'status')
      setValue('status', statusValuesForm)
    }
  }

  const onSubmitFilters: SubmitHandler<filterForm> = ({ category, status }) => {
    const selectedCategory = category?.find(cat => cat.selected)
    const selectedStatus = status?.find(st => st.selected)

    const resetParam = !selectedCategory && !selectedStatus

    setOnQueryParam(resetParam ? '' : `${NOTIFICATION_INITIAL_PAGE}`, 'page')
    setOnQueryParam(resetParam ? '' : `${NOTIFICATION_MIN_COUNT}`, 'count')

    setOnQueryParam(selectedCategory ? `${selectedCategory.value}` : '', 'category')
    setOnQueryParam(selectedStatus?.value ? selectedStatus?.value : '', 'status')

    handleCloseFilterDialog()
  }

  const selectedFilters = useMemo(() => {
    const selectedCategory = categoryOptions
      .map(item => ({
        text: item.text,
        value: item.value === category ? item.value : null,
      }))
      .filter(item => item.value)

    const selectedOptions = statusOptions
      .map(item => ({
        text: item.text,
        value: item.value === status ? item.value : null,
      }))
      .filter(item => item.value)

    return selectedCategory.concat(selectedOptions) as TableFilters[]
  }, [status, category])

  return {
    isFilterDialogOpen,
    filtersGroup,
    selectedFilters,
    form,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleRemoveFilter,
    onSubmitFilters,
  }
}

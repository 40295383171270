import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'
import { schoolsEnrollmentCycleService } from '@/modules/enrollment/services'

import {
  GetStudentEnrollmentCycleResponse,
  StudentEnrollmentCycleRequest,
} from '../../services/types'

export const studentEnrollmentCycleKey = 'student-enrollment-cycle'

export const useGetStudentEnrollmentCycle = (
  params: StudentEnrollmentCycleRequest,
  options?: UseQueryOptions<GetStudentEnrollmentCycleResponse>
) => {
  const { apiClient } = useApiClient()

  const service = schoolsEnrollmentCycleService(apiClient.privateApi)

  return useQuery<GetStudentEnrollmentCycleResponse>(
    [studentEnrollmentCycleKey, params],
    () => service.fetchStudentEnrollmentCycle(params),
    options
  )
}

import { useApiClient } from '@/shared/hooks'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { onboardingAPI } from '../services/onboardingApi'

export type UseUploadOnboardingFileMutationParams = {
  file: File
  schoolId: uuid
}

export type UseUploadOnboardingFileMutationResponse = any

export const useUploadOnboardingFileMutation = (
  options?: UseMutationOptions<
    UseUploadOnboardingFileMutationResponse,
    unknown,
    UseUploadOnboardingFileMutationParams,
    unknown
  >
) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useMutation(async ({ schoolId, file }: UseUploadOnboardingFileMutationParams) => {
    const response = await api.uploadOnboardingFile({
      schoolId,
      file,
    })

    return response.data
  }, options)
}

import { FC, useState } from 'react'
import copy from 'copy-to-clipboard'
import { Button, DialogTitle, DialogContent, DialogActions } from '@olaisaac/design-system'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import { useContract } from 'src/escolas/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { Receivable } from 'src/shared/interfaces'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export type CopyInvoiceLinkSuccessDialogProps = {
  disabled?: boolean
  openReceivable: Receivable
  selectedInstallmentHasError: boolean
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
}

const CopyInvoiceLinkSuccessDialog: FC<CopyInvoiceLinkSuccessDialogProps> = ({
  openReceivable,
  disabled,
  selectedInstallmentHasError,
  setShowInvoicesNotGeneratedDialog,
}) => {
  const { api } = useApi()
  const [isOpen, setIsOpen] = useState(false)
  const [link, setLink] = useState<string>('')
  const { schoolSlug } = useSelectedSchool()
  const { contract } = useContract()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendCopyLinkEvent = () => {
    if (!isInitialized) {
      return () => null
    }

    const completeMetadata = {
      school_slug: schoolSlug,
      name: 'Copiar link de cobrança',
      invoice_id: openReceivable.invoice_id,
      installment_id: openReceivable.installment_id,
      contract_id: contract.id,
    }

    eventDispatcherClient.sendEvent({
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      scope: EventDispatcherEventScopes.INVOICE,
      customProperties: completeMetadata,
      entity: EventDispatcherEntities.INVOICE_LINK_COPIED,
    })
  }

  const handleCopyLink = async () => {
    if (selectedInstallmentHasError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else if (openReceivable) {
      const { checkout_url } = await api.invoices.get(openReceivable.invoice_id)

      copy(checkout_url)
      setLink(checkout_url)
      setIsOpen(true)
      sendCopyLinkEvent()
    }
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Button variation="primary" fullWidth onClick={handleCopyLink} disabled={disabled}>
        Copiar link de cobrança
      </Button>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Typography color="textPrimary" variant="subtitle1">
            Link copiado com sucesso!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            O link do seu boleto foi copiado para a área de transferência como:
          </Typography>
          <br />
          <a target="_blank" rel="noreferrer" href={link}>
            {link}
          </a>
        </DialogContent>
        <DialogActions>
          <Button variation="primary" onClick={handleClose}>
            Ok, entendi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CopyInvoiceLinkSuccessDialog

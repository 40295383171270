import dayjs from 'dayjs'

import { Table } from '@gravity/table'
import {
  GuardianHistoryOriginalReceivable,
  GuardianHistoryReceivableStatus,
  GuardianHistoryResultantReceivable,
} from '@/modules/guardians/services/types'
import { formatCentsToReal, formatDate } from '@/shared/utils'

import { NegotiationTableContainer } from './styles'
import { InstallmentType } from '@/shared/interfaces'

const STATUS_TEXT: Record<GuardianHistoryReceivableStatus, string> = {
  AGGLUTINATED: 'Aglutinada',
  AWAITING: 'Aguardando',
  CANCELED: 'Cancelada',
  OPEN: 'A vencer',
  OVERDUE: 'Vencida',
  PAID: 'Paga',
  RENEGOTIATED: 'Renegociada',
}

const STATUS_COLOR: Record<
  GuardianHistoryReceivableStatus,
  'success' | 'error' | 'accent' | 'warning'
> = {
  AGGLUTINATED: 'warning',
  AWAITING: 'warning',
  CANCELED: 'warning',
  OPEN: 'accent',
  OVERDUE: 'error',
  PAID: 'success',
  RENEGOTIATED: 'warning',
}

type NegotiationCardProps = {
  shortId?: string
  table?: (GuardianHistoryOriginalReceivable | GuardianHistoryResultantReceivable)[]
  type: 'resultant' | 'original'
}

export const NegotiationTable = ({ shortId, table, type }: NegotiationCardProps) => {
  const negotiationId = shortId ? `Negociação #${shortId}` : undefined

  const formatType = (type: InstallmentType) => {
    if (type === InstallmentType.NEGOTIATION) return 'Negociação'
    if (type === InstallmentType.ENROLLMENT) return 'Pré-matrícula'
    return 'Mensalidade'
  }

  const formatOrderReference = (orderReference: string, type: string) => {
    let formatted = ''
    if (type === InstallmentType.NEGOTIATION && orderReference?.includes('1/')) {
      formatted += 'Entrada '
    }
    formatted += orderReference?.replace(/(\d+)\/(\d+)/, '$1 de $2')

    return formatted
  }

  const formatCompetence = (competence: string | string[]) => {
    if (Array.isArray(competence))
      return competence.map(item => dayjs(item, 'YYYY-MM').format('MMM. YYYY')).join(', ')
    return dayjs(competence, 'YYYY-MM').format('MMM. YYYY')
  }

  return (
    <NegotiationTableContainer>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell name="due_date" minWidth={156}>
            Vencimento
          </Table.HeaderCell>
          <Table.HeaderCell name="type" minWidth={156}>
            Fatura
          </Table.HeaderCell>
          <Table.HeaderCell name="competence" minWidth={156}>
            Competência
          </Table.HeaderCell>
          <Table.HeaderCell name="student_name" minWidth={156}>
            Aluno
          </Table.HeaderCell>
          <Table.HeaderCell name="product_name" minWidth={156}>
            Produto
          </Table.HeaderCell>
          {type === 'resultant' && (
            <>
              <Table.HeaderCell name="amount" minWidth={156} align="end">
                Valor
              </Table.HeaderCell>
              <Table.HeaderCell name="status" minWidth={156}>
                Situação
              </Table.HeaderCell>
            </>
          )}
        </Table.Head>
        <Table.Body>
          {table?.map(row => (
            <Table.Row key={row?.id + row?.due_date}>
              <Table.TextCell>{formatDate(row?.due_date, 'DD/MM/YYYY')}</Table.TextCell>
              <Table.TextCell subtext={formatOrderReference(row?.order_reference, row?.type)}>
                {formatType(row?.type)}
              </Table.TextCell>
              <Table.TextCell>{formatCompetence(row?.accrual_date)}</Table.TextCell>
              <Table.TextCell>{row?.student_name}</Table.TextCell>
              <Table.TextCell subtext={negotiationId}>{row?.product_name}</Table.TextCell>
              {type === 'resultant' && (
                <>
                  <Table.TextCell align="end">
                    {formatCentsToReal(row?.amount, true)}
                  </Table.TextCell>
                  <Table.BadgeCell badgeColor={STATUS_COLOR[row?.status]} badgeVariant="soft">
                    {STATUS_TEXT[row?.status]}
                  </Table.BadgeCell>
                </>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </NegotiationTableContainer>
  )
}

import { APIResponse } from '@/shared/interfaces'
import { AxiosInstance } from 'axios'
import {
  CreateProductDeliveryRequest,
  CreateProductDeliveryResponse,
  GetProductDeliveriesRequest,
  GetProductDeliveriesResponse,
} from './types'

export const productDeliveriesService = (fetcher: AxiosInstance) => {
  return {
    fetchProductDeliveries: async ({
      nameFilter,
      page,
      perPage,
      schoolId,
    }: GetProductDeliveriesRequest): Promise<APIResponse<GetProductDeliveriesResponse, string>> => {
      const response = await fetcher.get<APIResponse<GetProductDeliveriesResponse, string>>(
        `/product-delivery/teaching-material`,
        {
          params: {
            search_name: nameFilter,
            school_id: schoolId,
            page: page,
            per_page: perPage,
          },
        }
      )

      return response.data
    },
    createProductDelivery: async (
      product_delivery: CreateProductDeliveryRequest
    ): Promise<CreateProductDeliveryResponse> => {
      const { data: response } = await fetcher.post<{ data: CreateProductDeliveryResponse }>(
        'product-delivery',
        product_delivery
      )

      return response.data
    },
  }
}

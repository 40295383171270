import { mdiCheck } from '@mdi/js'
import Icon from '@mdi/react'
import styled from 'styled-components'
import { Heading } from '@gravity/heading'
import { colors } from '@gravity/tokens'

const StyledRoot = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

export const NoInvalidData = () => {
  return (
    <StyledRoot>
      <Icon path={mdiCheck} size={3} color={colors['colors-interaction-primary-1']} />
      <Heading variant="heading-h4-medium">Tudo certo por aqui: sem correções necessárias!</Heading>
    </StyledRoot>
  )
}

import { IrregularityHeaderType, IrregularityTableProps } from './types'
import { Table } from '@gravity/table'

export const IrregularityTable = ({
  columnHeaders,
  rows,
  ordering,
  onChangeOrdering,
}: IrregularityTableProps) => {
  return (
    <Table.Root style={{ borderRadius: '0px' }}>
      <Table.Head>
        {columnHeaders.map(({ key, label, sortable }: IrregularityHeaderType) => (
          <Table.HeaderCell
            key={key}
            name={key}
            sortable={sortable}
            sortOrder={
              ordering.sortBy === key && ordering.sortOrder ? ordering.sortOrder : undefined
            }
            onSort={(sortBy, sortOrder) => {
              onChangeOrdering(sortBy, sortOrder)
            }}
          >
            {label}
          </Table.HeaderCell>
        ))}
      </Table.Head>

      <Table.Body>
        {rows.map(row => (
          <Table.Row key={row.key} highlighOnHover={false}>
            <Table.CustomCell width={344}>{row.columns[0]}</Table.CustomCell>
            <Table.CustomCell width={344}>{row.columns[1]}</Table.CustomCell>
            <Table.CustomCell width={344}>{row.columns[2]}</Table.CustomCell>
            <Table.CustomCell width={344}>{row.columns[3]}</Table.CustomCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  )
}

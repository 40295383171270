import { Button } from '@gravity/button'
import { ReactElement, useEffect } from 'react'
import { Text } from '@gravity/text'
import { Tabs } from '@gravity/tabs'
import {
  mdiAccountSchoolOutline,
  mdiBookOpenBlankVariant,
  mdiAccountCircleOutline,
  mdiCurrencyUsd,
  mdiRefresh,
} from '@mdi/js'
import { Icon } from '@mdi/react'
import { useQuery } from '@/shared/hooks/useQuery'
import { usePagination } from '@/shared/hooks/usePagination'
import { LayoutTemplate } from '@/modules/onboarding/LayoutTemplate'
import { StudentTable } from '@/modules/onboarding/pages/components/StudentTable'
import { ContractTableByProduct } from '@/modules/onboarding/pages/components/ContractTableByProduct'
import { GuardianTable } from '../components/GuardianTable'

const TAB_QUERY_KEY = 'tab'

enum TabKey {
  CHARGES = 'pagamentos',
  GUARDIANS = 'responsaveis',
  PRODUCTS = 'academicos',
  STUDENTS = 'alunos',
}

const tabs: { icon: ReactElement; key: TabKey; table?: JSX.Element; title: string }[] = [
  {
    key: TabKey.PRODUCTS,
    icon: <Icon path={mdiAccountSchoolOutline} />,
    title: 'Dados acadêmicos',
    table: <ContractTableByProduct />,
  },
  {
    key: TabKey.STUDENTS,
    icon: <Icon path={mdiBookOpenBlankVariant} />,
    title: 'Dados de alunos',
    table: <StudentTable />,
  },
  {
    key: TabKey.GUARDIANS,
    icon: <Icon path={mdiAccountCircleOutline} />,
    title: 'Dados de responsáveis',
    table: <GuardianTable />,
  },
  {
    key: TabKey.CHARGES,
    icon: <Icon path={mdiCurrencyUsd} />,
    title: 'Dados de pagamentos',
  },
]

const PageContent = () => {
  const { query, setOnQueryParam } = useQuery()
  const { pagination, updatePaginationValue } = usePagination()

  const handleActiveTab = (value: TabKey) => {
    setOnQueryParam(value, TAB_QUERY_KEY)
    updatePaginationValue('page', 1)
  }

  // placeholder. will be removed
  const nextPage = () => {
    updatePaginationValue('page', Math.min(pagination.page + 1, 10))
  }

  const previousPage = () => {
    updatePaginationValue('page', Math.max(pagination.page - 1, 1))
  }

  useEffect(() => {
    const tabQuery = query.get(TAB_QUERY_KEY) as TabKey
    const currentTab = tabs.map(i => i.key).includes(tabQuery) ? tabQuery : TabKey.PRODUCTS

    setOnQueryParam(currentTab, TAB_QUERY_KEY)
  }, [])

  return (
    <Tabs.Root
      value={query.get(TAB_QUERY_KEY) ?? ''}
      onValueChange={value => handleActiveTab(value as TabKey)}
    >
      <Tabs.List background="gray">
        {tabs.map(tab => (
          <Tabs.Trigger key={tab.key} value={tab.key} iconStart={tab.icon}>
            <Text variant="button-2" className="text-inherit">
              {tab.title}
            </Text>
          </Tabs.Trigger>
        ))}
      </Tabs.List>

      {tabs.map(tab => (
        <Tabs.Content key={tab.key} value={tab.key} style={{ marginTop: 32 }}>
          {tab.table ?? (
            <div>
              {tab.title}
              <button onClick={nextPage}>+page</button>
              <button onClick={previousPage}>-page</button>
            </div>
          )}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  )
}

export const ValidatePage = () => {
  return (
    <LayoutTemplate
      title="Corrigir dados da escola"
      description="Nesta etapa, você deve informar ou corrigir todos os dados obrigatórios para a migração dos contratos. Não se preocupe com os dados que não aparecem aqui, isso significa que eles estão corretos e prontos para serem migrados."
      footer={
        <>
          <Button variant="ghost" iconStart={<Icon path={mdiRefresh} />} disabled>
            Substituir planilha
          </Button>
          <Button>Continuar</Button>
        </>
      }
    >
      <PageContent />
    </LayoutTemplate>
  )
}

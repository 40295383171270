import { useQuery } from '@tanstack/react-query'
import { omit } from 'ramda'
import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { useApi } from '@/utils/hooks/useApi'
import { Discount, Installment } from 'src/shared/interfaces'
import { parseUpdatedInstallments } from '../components/contract/ContractEditDrawer/utils'

export type useEditDiscountsInfoParams = {
  contractId: uuid
  discounts: Discount[]
  get_current_amount?: boolean
  installment_id: uuid
}

const validateParams = (params: useEditDiscountsInfoParams): boolean => {
  return !!params?.contractId && !!params?.installment_id
}

export const useEditDiscountsInfo = (
  params: useEditDiscountsInfoParams = {
    contractId: null,
    discounts: [],
    get_current_amount: null,
    installment_id: null,
  },
  enabled = true
) => {
  const { api } = useApi()
  const queryKey = ['edit-discounts-info', params]

  const methods = useQuery<{
    is_contract_in_flexible_period: boolean
    new_installments: Installment[]
    original_installments: Installment[]
  }>(
    queryKey,
    () => api.contracts.bulkEditDiscountsInfo(params.contractId, omit(['contractId'], params)),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: enabled && validateParams(params),
    }
  )
  const { data } = methods
  return { ...methods, installmentsToEditDiscounts: data ? parseUpdatedInstallments(data) : [] }
}

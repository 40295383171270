import { GetOnboardingBatchRequest } from '@/modules/onboarding/services/types/commonTypes'
import {
  FetchOnboardingBatchesParams,
  FetchOnboardingBatchesResponse,
  OnboardingBatch,
} from '@/modules/onboarding/services/types/onboardingBatch'
import { GetContractsResponse } from '@/modules/onboarding/services/types/onboardingContract'
import { UploadOnboardingFileParams } from '@/modules/onboarding/services/types/onboardingFile'
import { GetStudentsResponse } from '@/modules/onboarding/services/types/student'
import { AxiosInstance } from 'axios'
import { Product, UpdateProductParams } from '../types/onboardingProduct'
import { GetGuardiansResponse } from '../types/onboardingGuardian'

const makeDefaultParams = (params: GetOnboardingBatchRequest): URLSearchParams => {
  const paramsQuery = new URLSearchParams()
  paramsQuery.append('sort_order', 'ASC')
  if (params.pagination.page) {
    paramsQuery.append('page', params.pagination.page.toString())
  }
  if (params.pagination.per_page) {
    paramsQuery.append('per_page', params.pagination.per_page.toString())
  }
  return paramsQuery
}

export const onboardingAPI = (fetcher: AxiosInstance) => ({
  fetchOnboardingBatches: async ({ schoolId }: FetchOnboardingBatchesParams) => {
    const response = await fetcher.get<FetchOnboardingBatchesResponse>(
      `schools/${schoolId}/onboarding_batches`
    )

    return response.data
  },
  uploadOnboardingFile: async ({ file, schoolId }: UploadOnboardingFileParams) => {
    const formData = new FormData()
    formData.append('file', file)

    const response = await fetcher.post<OnboardingBatch>(
      `schools/${schoolId}/onboarding_batches`,
      formData
    )

    return response
  },
  getStudents: async (params: GetOnboardingBatchRequest): Promise<GetStudentsResponse> => {
    return (
      await fetcher.get<GetStudentsResponse>(`/onboarding_batches/${params.batchID}/students`, {
        params: makeDefaultParams(params),
      })
    ).data
  },
  getGuardians: async (params: GetOnboardingBatchRequest): Promise<GetGuardiansResponse> => {
    return (
      await fetcher.get<GetGuardiansResponse>(`/onboarding_batches/${params.batchID}/guardians`, {
        params: makeDefaultParams(params),
      })
    ).data
  },
  getContractsByProduct: async (
    params: GetOnboardingBatchRequest
  ): Promise<GetContractsResponse> => {
    return (
      await fetcher.get<GetContractsResponse>(
        `/onboarding_batches/${params.batchID}/contracts/products`,
        {
          params: makeDefaultParams(params),
        }
      )
    ).data
  },
  updateProduct: async (params: UpdateProductParams) => {
    const response = await fetcher.patch<Product>(
      `/onboarding_batches/${params.batchID}/products/${params.product.id}`,
      { ...params.product }
    )

    return response
  },
})

import { useParams } from 'react-router-dom'
import { Button } from '@gravity/button'
import { mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'

import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useStudentProfile } from '../../hooks/queries/students'
import { useSectionObserver } from '@monorepo/students/hooks/useSectionObserver'

import { ProfileSection } from './components/ProfileSection'
import { SectionContainer } from './components/SectionContainer'
import { SidebarMenu, SidebarMenuSection } from './components/SidebarMenu'

import { Container, SubContainer } from './styles'

export const Student = () => {
  useLayout({ enableSideMenu: true, enableHeader: false, headerTitle: 'Aluno' })

  const { school } = useSelectedSchool()
  const { studentId } = useParams<{ studentId: string }>()

  const { data, isFetching, isError, refetch } = useStudentProfile(
    { studentId },
    { enabled: !!studentId }
  )
  const taxId = data?.data.tax_id ?? null
  const birthDate = data?.data.birth_date ?? null

  const sections: SidebarMenuSection[] = [
    {
      id: 'dados',
      label: 'Dados cadastrais',
      subLabel: !taxId || !birthDate ? 'Incompletos' : undefined,
    },
    { id: 'responsaveis', label: 'Responsáveis' },
    { id: 'financeiro', label: 'Financeiro', subLabel: 'Em atraso' },
    { id: 'contratos', label: 'Contratos', subLabel: '3 ativos' },
  ]

  const { activeSectionId, registerSection, handleActiveSection } = useSectionObserver({
    sections: sections.map(section => section.id),
  })

  return (
    <Container>
      <SidebarMenu
        sections={sections}
        activeSectionId={activeSectionId}
        onSectionActiveCallback={handleActiveSection}
      />

      <SubContainer>
        <ProfileSection
          id="dados"
          ref={el => registerSection('dados', el)}
          schoolName={school?.name ?? ''}
          isLoading={isFetching}
          hasError={isError}
          taxId={taxId}
          birthDate={birthDate}
          onRequestEditProfile={() => console.log('TODO')}
          onRetry={refetch}
        />

        <SectionContainer
          id="responsaveis"
          title="Responsáveis"
          ref={el => registerSection('responsaveis', el)}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices viverra dolor, et
          sagittis arcu finibus vel. Phasellus efficitur et libero a rutrum. Integer auctor nibh a
          lacus tristique, id ultricies sapien bibendum. Vivamus a turpis nec eros imperdiet luctus
          sit amet ut arcu. Fusce sodales molestie risus, non sollicitudin eros. Phasellus malesuada
          urna quam, a vehicula velit consectetur nec. Sed et scelerisque est. Duis ornare, justo
          sit amet mattis ullamcorper, ipsum urna consectetur nisl, eget finibus lectus est vitae
          massa. Nullam porttitor tempor felis, sit amet feugiat tortor sollicitudin eget. Cras ac
          volutpat mi. In non porta sapien, in hendrerit leo. Nulla erat eros, porta et mattis sit
          amet, eleifend sed ex. Vivamus lacinia, turpis sed lobortis dapibus, quam urna egestas
          metus, at faucibus nisl dolor vel sapien. Maecenas tempus in eros ut malesuada. Donec
          convallis, orci eu pretium commodo, felis lectus vestibulum tortor, ut laoreet purus
          tellus vel orci. Ut suscipit, sem in interdum rutrum, lacus elit pretium ligula, nec
          fermentum ipsum eros ut quam. Sed eget magna condimentum, ultrices velit eget, rhoncus
          est. Nam quis urna in mauris mattis pretium. Praesent lacinia dui eu lectus euismod
          lacinia sit amet vel diam. Donec faucibus elit tincidunt elit elementum suscipit vel sit
          amet nibh. Quisque vitae libero vitae nunc mattis elementum. Nulla quis eleifend ipsum.
          Nulla posuere libero ac arcu porta, sit amet mattis augue suscipit. Mauris erat lectus,
          laoreet at tempus vel, ornare eget magna. Vestibulum vestibulum tempus venenatis. Nam
          maximus semper justo ac ullamcorper. Quisque mauris augue, mattis quis est nec, pretium
          efficitur libero. Duis posuere sem vel laoreet elementum. Mauris imperdiet dolor id ornare
          commodo. Pellentesque sollicitudin imperdiet tellus eget mattis. Nam ut odio in quam
          venenatis mollis. Cras vitae urna a felis efficitur pharetra. Maecenas hendrerit molestie
          tortor, quis ornare lectus condimentum ac. Aliquam tempus lorem bibendum, pulvinar orci
          id, tincidunt augue. Fusce felis est, pharetra et justo eget, dictum sollicitudin ante.
          Aenean aliquam vel ante sed accumsan. Ut imperdiet nunc a lacus luctus cursus.
          Pellentesque pretium, enim quis hendrerit dignissim, nibh diam aliquam tortor, non luctus
          eros ex sit amet dui. Morbi vitae tincidunt lacus. Ut ac felis dolor. Sed mattis sed metus
          nec eleifend. Interdum et malesuada fames ac ante ipsum primis in faucibus. In id mauris
          id massa mattis commodo. Ut vel orci ultrices, rhoncus nunc sed, scelerisque magna. Nullam
          eu egestas quam. Praesent blandit facilisis orci vitae lobortis. Vivamus vel faucibus
          magna. Etiam eget pretium sem. Nunc sit amet metus ac tellus egestas sodales. Pellentesque
          eu quam dapibus magna vestibulum iaculis. Pellentesque posuere pharetra felis, vitae
          placerat nisl fringilla in. Etiam in sagittis lectus. Nam bibendum dui orci, at rutrum
          odio interdum et. Praesent facilisis venenatis metus nec rhoncus. Ut eget egestas lacus.
          Nunc lobortis suscipit magna in varius. Pellentesque commodo tellus nec arcu dapibus
          fermentum. Ut vel hendrerit mi, fringilla porttitor leo.
        </SectionContainer>

        <SectionContainer
          id="financeiro"
          title="Financeiro"
          ref={el => registerSection('financeiro', el)}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices viverra dolor, et
          sagittis arcu finibus vel. Phasellus efficitur et libero a rutrum. Integer auctor nibh a
          lacus tristique, id ultricies sapien bibendum. Vivamus a turpis nec eros imperdiet luctus
          sit amet ut arcu. Fusce sodales molestie risus, non sollicitudin eros. Phasellus malesuada
          urna quam, a vehicula velit consectetur nec. Sed et scelerisque est. Duis ornare, justo
          sit amet mattis ullamcorper, ipsum urna consectetur nisl, eget finibus lectus est vitae
          massa. Nullam porttitor tempor felis, sit amet feugiat tortor sollicitudin eget. Cras ac
          volutpat mi. In non porta sapien, in hendrerit leo. Nulla erat eros, porta et mattis sit
          amet, eleifend sed ex. Vivamus lacinia, turpis sed lobortis dapibus, quam urna egestas
          metus, at faucibus nisl dolor vel sapien. Maecenas tempus in eros ut malesuada. Donec
          convallis, orci eu pretium commodo, felis lectus vestibulum tortor, ut laoreet purus
          tellus vel orci. Ut suscipit, sem in interdum rutrum, lacus elit pretium ligula, nec
          fermentum ipsum eros ut quam. Sed eget magna condimentum, ultrices velit eget, rhoncus
          est. Nam quis urna in mauris mattis pretium. Praesent lacinia dui eu lectus euismod
          lacinia sit amet vel diam. Donec faucibus elit tincidunt elit elementum suscipit vel sit
          amet nibh. Quisque vitae libero vitae nunc mattis elementum. Nulla quis eleifend ipsum.
          Nulla posuere libero ac arcu porta, sit amet mattis augue suscipit. Mauris erat lectus,
          laoreet at tempus vel, ornare eget magna. Vestibulum vestibulum tempus venenatis. Nam
          maximus semper justo ac ullamcorper. Quisque mauris augue, mattis quis est nec, pretium
          efficitur libero. Duis posuere sem vel laoreet elementum. Mauris imperdiet dolor id ornare
          commodo. Pellentesque sollicitudin imperdiet tellus eget mattis. Nam ut odio in quam
          venenatis mollis. Cras vitae urna a felis efficitur pharetra. Maecenas hendrerit molestie
          tortor, quis ornare lectus condimentum ac. Aliquam tempus lorem bibendum, pulvinar orci
          id, tincidunt augue. Fusce felis est, pharetra et justo eget, dictum sollicitudin ante.
          Aenean aliquam vel ante sed accumsan. Ut imperdiet nunc a lacus luctus cursus.
          Pellentesque pretium, enim quis hendrerit dignissim, nibh diam aliquam tortor, non luctus
          eros ex sit amet dui. Morbi vitae tincidunt lacus. Ut ac felis dolor. Sed mattis sed metus
          nec eleifend. Interdum et malesuada fames ac ante ipsum primis in faucibus. In id mauris
          id massa mattis commodo. Ut vel orci ultrices, rhoncus nunc sed, scelerisque magna. Nullam
          eu egestas quam. Praesent blandit facilisis orci vitae lobortis. Vivamus vel faucibus
          magna. Etiam eget pretium sem. Nunc sit amet metus ac tellus egestas sodales. Pellentesque
          eu quam dapibus magna vestibulum iaculis. Pellentesque posuere pharetra felis, vitae
          placerat nisl fringilla in. Etiam in sagittis lectus. Nam bibendum dui orci, at rutrum
          odio interdum et. Praesent facilisis venenatis metus nec rhoncus. Ut eget egestas lacus.
          Nunc lobortis suscipit magna in varius. Pellentesque commodo tellus nec arcu dapibus
          fermentum. Ut vel hendrerit mi, fringilla porttitor leo.
        </SectionContainer>

        <SectionContainer
          id="contratos"
          title="Contratos"
          ref={el => registerSection('contratos', el)}
          action={
            <Button variant="outline" iconStart={<Icon path={mdiPlus} />}>
              Novo contrato
            </Button>
          }
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices viverra dolor, et
          sagittis arcu finibus vel. Phasellus efficitur et libero a rutrum. Integer auctor nibh a
          lacus tristique, id ultricies sapien bibendum. Vivamus a turpis nec eros imperdiet luctus
          sit amet ut arcu. Fusce sodales molestie risus, non sollicitudin eros. Phasellus malesuada
          urna quam, a vehicula velit consectetur nec. Sed et scelerisque est. Duis ornare, justo
          sit amet mattis ullamcorper, ipsum urna consectetur nisl, eget finibus lectus est vitae
          massa. Nullam porttitor tempor felis, sit amet feugiat tortor sollicitudin eget. Cras ac
          volutpat mi. In non porta sapien, in hendrerit leo. Nulla erat eros, porta et mattis sit
          amet, eleifend sed ex. Vivamus lacinia, turpis sed lobortis dapibus, quam urna egestas
          metus, at faucibus nisl dolor vel sapien. Maecenas tempus in eros ut malesuada. Donec
          convallis, orci eu pretium commodo, felis lectus vestibulum tortor, ut laoreet purus
          tellus vel orci. Ut suscipit, sem in interdum rutrum, lacus elit pretium ligula, nec
          fermentum ipsum eros ut quam. Sed eget magna condimentum, ultrices velit eget, rhoncus
          est. Nam quis urna in mauris mattis pretium. Praesent lacinia dui eu lectus euismod
          lacinia sit amet vel diam. Donec faucibus elit tincidunt elit elementum suscipit vel sit
          amet nibh. Quisque vitae libero vitae nunc mattis elementum. Nulla quis eleifend ipsum.
          Nulla posuere libero ac arcu porta, sit amet mattis augue suscipit. Mauris erat lectus,
          laoreet at tempus vel, ornare eget magna. Vestibulum vestibulum tempus venenatis. Nam
          maximus semper justo ac ullamcorper. Quisque mauris augue, mattis quis est nec, pretium
          efficitur libero. Duis posuere sem vel laoreet elementum. Mauris imperdiet dolor id ornare
          commodo. Pellentesque sollicitudin imperdiet tellus eget mattis. Nam ut odio in quam
          venenatis mollis. Cras vitae urna a felis efficitur pharetra. Maecenas hendrerit molestie
          tortor, quis ornare lectus condimentum ac. Aliquam tempus lorem bibendum, pulvinar orci
          id, tincidunt augue. Fusce felis est, pharetra et justo eget, dictum sollicitudin ante.
          Aenean aliquam vel ante sed accumsan. Ut imperdiet nunc a lacus luctus cursus.
          Pellentesque pretium, enim quis hendrerit dignissim, nibh diam aliquam tortor, non luctus
          eros ex sit amet dui. Morbi vitae tincidunt lacus. Ut ac felis dolor. Sed mattis sed metus
          nec eleifend. Interdum et malesuada fames ac ante ipsum primis in faucibus. In id mauris
          id massa mattis commodo. Ut vel orci ultrices, rhoncus nunc sed, scelerisque magna. Nullam
          eu egestas quam. Praesent blandit facilisis orci vitae lobortis. Vivamus vel faucibus
          magna. Etiam eget pretium sem. Nunc sit amet metus ac tellus egestas sodales. Pellentesque
          eu quam dapibus magna vestibulum iaculis. Pellentesque posuere pharetra felis, vitae
          placerat nisl fringilla in. Etiam in sagittis lectus. Nam bibendum dui orci, at rutrum
          odio interdum et. Praesent facilisis venenatis metus nec rhoncus. Ut eget egestas lacus.
          Nunc lobortis suscipit magna in varius. Pellentesque commodo tellus nec arcu dapibus
          fermentum. Ut vel hendrerit mi, fringilla porttitor leo.
        </SectionContainer>
      </SubContainer>
    </Container>
  )
}

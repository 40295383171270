import { AxiosInstance } from 'axios'

import type { FetchStudentProfileRequest, FetchStudentProfileResponse } from './types'

export const studentsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetch a student profile data
     */
    fetchStudentProfile: async ({
      studentId,
    }: FetchStudentProfileRequest): Promise<FetchStudentProfileResponse> => {
      const { data } = await fetcher.get<FetchStudentProfileResponse>(
        `/students/${studentId}/profile`
      )

      return data
    },
  }
}

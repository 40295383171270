import { useQuery } from '@tanstack/react-query'
import { GetStudentsResponse } from '../services/types/student'
import { useApiClient } from '@/shared/hooks'
import { onboardingAPI } from '../services/onboardingApi'
import { GetOnboardingBatchRequest } from '../services/types/commonTypes'

export const useStudentList = (params: GetOnboardingBatchRequest) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useQuery<GetStudentsResponse>(['getStudentList', params], () => api.getStudents(params))
}

import { useApiClient } from '@/shared/hooks/useApiClient'
import { schoolGroupsService } from '@/shared/services/schoolGroups'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export const useHasGroupManagerAccess = (options?: UseQueryOptions<number>) => {
  const { apiClient } = useApiClient()

  const service = schoolGroupsService(apiClient.getClients().bffApi)

  return useQuery<number>(['school-group', 'access'], () => service.fetchIsGroupManager(), {
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  })
}

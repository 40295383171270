import config from '@/config'

import type {
  NotificationRequestDTO,
  NotificationResponseDTO,
  NotificationData,
  NotificationResponse,
} from './types'

export const notificationsService = () => {
  return {
    fetchNotifications: async ({
      pagination,
      markAsRead = false,
      ...remainingParams
    }: NotificationRequestDTO): Promise<NotificationResponseDTO> => {
      const page = pagination?.page ?? 0
      const count = pagination?.count ?? 1

      const query = new URLSearchParams({
        page: page.toString(),
        maxResults: count.toString(),
        published: 'true',
        expired: 'false',
        markAsRead: markAsRead.toString(),
        traceableLinks: 'true',
        ...remainingParams,
      })

      const response = await fetch(`${config.BEAMER.HOST}/posts?${query}`, {
        method: 'GET',
        headers: {
          'Beamer-Api-Key': config?.BEAMER.KEY ?? '',
        },
      })

      const data = (await response.json()) as NotificationData[]

      const notifications: NotificationResponse[] = data.map(notification => ({
        id: notification.id,
        date: notification.date,
        filter: notification.filter,
        ...notification.translations[0],
      }))

      return notifications
    },

    fetchNotificationsCount: async ({
      filter = '',
      userId = '',
      category = '',
    }: NotificationRequestDTO): Promise<number> => {
      const query = new URLSearchParams({
        userId,
        filter,
        category,
        published: 'true',
        expired: 'false',
      })

      const response = await fetch(`${config.BEAMER.HOST}/posts/count?${query}`, {
        method: 'GET',
        headers: {
          'Beamer-Api-Key': config.BEAMER.KEY ?? '',
        },
      })

      const { count } = await response.json()

      return count
    },

    fetchUnreadNotifications: async ({
      pagination,
      markAsRead = false,
      ...remainingParams
    }: NotificationRequestDTO): Promise<NotificationResponseDTO> => {
      const page = pagination?.page ?? 0
      const count = pagination?.count ?? 1

      const query = new URLSearchParams({
        page: page.toString(),
        maxResults: count.toString(),
        markAsRead: markAsRead.toString(),
        traceableLinks: 'true',
        ...remainingParams,
      })

      const response = await fetch(`${config.BEAMER.HOST}/unread?${query}`, {
        method: 'GET',
        headers: {
          'Beamer-Api-Key': config?.BEAMER.KEY ?? '',
        },
      })

      const data = (await response.json()) as NotificationData[]

      const notifications: NotificationResponse[] = data.map(notification => ({
        id: notification.id,
        date: notification.date,
        filter: notification.filter,
        ...notification.translations[0],
      }))

      return notifications
    },

    fetchUnreadNotificationsCount: async ({
      filter = '',
      userId = '',
      category = '',
    }: NotificationRequestDTO): Promise<number> => {
      const query = new URLSearchParams({
        userId,
        filter,
        category,
        published: 'true',
        expired: 'false',
      })

      const response = await fetch(`${config.BEAMER.HOST}/unread/count?${query}`, {
        method: 'GET',
        headers: {
          'Beamer-Api-Key': config?.BEAMER.KEY ?? '',
        },
      })

      const { count } = await response.json()

      return count
    },
  }
}

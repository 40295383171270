import { memo, MouseEventHandler, useCallback, useMemo, useRef } from 'react'
import { Icon } from '@mdi/react'
import { mdiClose } from '@mdi/js'

import { useToast } from '@gravity/toast'
import { Button } from '@gravity/button'
import { Separator } from '@gravity/separator'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { IconButton } from '@gravity/icon-button'

import { Drawer, DrawerProps } from '@olaisaac/design-system'

import type { Props as ProductFormProps } from './components/ProductForm'
import ProductForm from './components/ProductForm'
import {
  CloseDrawer,
  Container,
  HeaderContainer,
  StickyBottomContainer,
  SubContainer,
} from './styles'

type Props = Pick<DrawerProps, 'open'> &
  ProductFormProps & {
    onClose: MouseEventHandler<HTMLButtonElement>
  }

const TITLE = {
  create: 'Criar produto',
  edit: 'Editar produto',
}

const SUBTITLE = {
  create: 'Produtos são os serviços que a sua escola oferece.',
  edit: 'As mudanças feitas só serão aplicadas a novos contratos.',
}

const TOAST: Record<'create' | 'edit', { description?: string; title: string }> = {
  create: {
    title: 'Produto salvo com sucesso!',
  },
  edit: {
    title: 'Produto editado com sucesso!',
    description: 'As alterações não afetarão contratos previamente atrelados ao produto.',
  },
}

export const CreateOrEditProductDrawer = memo(({ open, onClose, ...props }: Props) => {
  const form = useRef<HTMLFormElement>(null)
  const { toast } = useToast()

  const isEditing = useMemo(() => Boolean(props?.product), [props.product])

  const [title, subtitle] = useMemo(() => {
    const key = isEditing ? 'edit' : 'create'
    return [TITLE[key], SUBTITLE[key]]
  }, [isEditing])

  const handleSubmit = useCallback(() => {
    if (form.current) {
      form.current.requestSubmit()
    }
  }, [form.current])

  const handleSuccess = useCallback(() => {
    onClose(null as any)

    const method = isEditing ? 'edit' : 'create'

    toast({
      title: TOAST[method].title,
      description: TOAST[method].description,
      type: 'success',
    })
  }, [onClose, toast, isEditing])

  const handleError = useCallback(() => {
    toast({
      title: 'Não foi possível salvar o produto.',
      description: 'Por favor, tente novamente ou entre em contato com seu gerente no isaac.',
      type: 'error',
    })
  }, [toast])

  return (
    <Drawer open={open}>
      <Container>
        <CloseDrawer>
          <IconButton name="close" size={1} variant="ghost" onClick={onClose} aria-label="close">
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </CloseDrawer>
        <HeaderContainer>
          <Heading variant="heading-h4-medium">{title}</Heading>
          <Text variant="subtitle-regular">{subtitle}</Text>
        </HeaderContainer>

        <Separator color="neutral-2" />

        <SubContainer>
          {open && (
            <ProductForm
              {...props}
              onApiError={handleError}
              showEnvelopField={Boolean(props.product)}
              ref={form}
              onSuccess={handleSuccess}
            />
          )}
        </SubContainer>

        <StickyBottomContainer>
          <Separator color="neutral-2" />
          <Button fullWidth onClick={handleSubmit}>
            Salvar produto
          </Button>
        </StickyBottomContainer>
      </Container>
    </Drawer>
  )
})

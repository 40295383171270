import styled from 'styled-components'

import theme from '@/shared/theme'

const BORDER_COLOR = theme.primitiveTokens.colors.gray[5]

export const Container = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  height: 96px;
  border-bottom: 1px solid ${BORDER_COLOR};
  z-index: 2;

  .select-container {
    width: 20rem;
    margin-left: ${props => props.theme.gravity.spacing[6]};
  }

  .controllers-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: ${props => props.theme.gravity.spacing[4]};
  }
`

import { z } from 'zod'

const MAX_FILE_SIZE = 50 * 1024 * 1024

export const fileSchema = z.object({
  file: z
    .instanceof(File)
    .refine(file => file.size <= MAX_FILE_SIZE, {
      message: 'Tamanho máximo por aqui: 50MB',
    })
    .refine(
      file => {
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png']
        return allowedTypes.includes(file.type)
      },
      {
        message: 'Tipo de arquivo não suportado',
      }
    ),
})
